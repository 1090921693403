@charset 'utf-8';
.switch-container{
  display: inline-block;
  padding: 2px 0;
  border-radius: 2em;
  background: $bg-white;
  box-shadow: 0 0 0 1px $color-stroke-grey-2;
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.switch{
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0 2px;
  position: relative;
  z-index: 2;
  button{
    display: block;
    @include font-en-overline-2();
    // width: 4em;
    padding: 4px 6px;
    border-radius: 2em;
    color: $color-txt-grey-2;
    @include transition(color .25s .25s);
    touch-action: none;
    &.is-active,
    &:hover,
    &:focus-visible{
      color: $color-white;
      // background: $color-black;
    }
  }
  .highlight-span{
    width: 2em;
    height: 100%;
    position: absolute;
    z-index: -2;
    inset: 0px;
    background: $color-black;
    border-radius: 2em;
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
  &:hover{
    button{
      &.is-active{
        color: $color-txt-grey-2;
        &:hover,
        &:focus-visible{
          color: $color-white;
        }
      }
    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .switch{
  }
}
@media (max-width: 1280px) {
  .switch{
  }
}
@media (max-width: 1024px) {
  .switch{
  }
}
@media (max-width: 1023px) {
  .switch{
  }
}
@media (max-width: 768px) {
  .switch{
  }
}
@media (max-width: 414px) {
  .switch{
  }
}
@media (max-width: 360px) {
  .switch{
  }
}