@charset 'utf-8';
.swiper-button-next,
.swiper-button-prev{
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  margin: 0;
  cursor: pointer;
  // background-color: transparentize(black, .75);
  mix-blend-mode: exclusion;
  z-index: 3;
  &:after{
    content: '';
    display: block;
    width: 38px;
    height: 46px;
    padding: 5px 0;
    background: url(/assets/img/icon/slide-next.svg) no-repeat center center;
    opacity: 0;
    right: 24px;
    position: absolute;
    @include transition(transform .3s, opacity .3s);
  }
  &:hover,
  &:focus-visible {
    &:after {
      opacity: 1;
      @include translate(4px,0);
    }
  }
}
.swiper-button-prev{
  right: auto;
  left: 0;
  &:after{
    background: url(/assets/img/icon/slide-prev.svg) no-repeat center center;
    right: auto;
    left: 24px;
  }
  &:hover,
  &:focus-visible {
    &:after {
      @include translate(-4px,0);
    }
  }
}
.swiper-pagination{
  position: relative;
  margin-top: 10px;
  @include font-en-caption-2();
  font-weight: 300;
  text-align: left;
  z-index: 3;
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .swiper{
  }
}
@media (max-width: 1280px) {
  .swiper{
  }
}
@media (max-width: 1024px) {
  .swiper{
  }
}
@media (max-width: 1023px) {
  .swiper{
  }
}
@media (max-width: 768px) {
  .swiper{
  }
}
@media (max-width: 414px) {
  .swiper{
  }
}
@media (max-width: 360px) {
  .swiper{
  }
}