@charset 'utf-8';
.editor-content{
	font-size: 16px;
	color: $color-black;
	word-break: break-all;
	word-break: break-word;
	h2,
	h3,
	h4,
	h5,
	h6{
		font-weight: 500;
		margin: .5rem 0;
		line-height: 1.75;
		color:$color-black;
	}
	h2{font-size: 2em;}
	h3{font-size: 1.75em;}
	h4{font-size: 1.5em;}
	h5{font-size: 1.25em;}
	h6{font-size: 1.125em;}
	p{margin-bottom: 1.5rem; }
	p,li{
		font-size: 1em;
		line-height: 1.75;
	}
	a{
		display: inline;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		letter-spacing: inherit;
		color:$color-black;
		&:hover{text-decoration: underline;}
	}
	.marker{background: $color-black;} /* 標記背景色 */
	big{font-size: 1.25em;}
	small{font-size: .85em;}
	b,
	strong{font-weight: bold;}
	q{font-family: Georgia,Cambria,"Times New Roman",Times,serif; } /* 單行引用文字 */
	cite{font-style: italic;} /* 引用來源 */
	kbd{font-family: "Consolas", monospace;} /* keyboard input */
	samp{ /* output範例 */
		display: block;
		border:1px solid #eeeeee;
		padding:10px;
	}
	blockquote{ /* 引用段落文字 */
		display: block;
		font-family: Georgia,Cambria,"Times New Roman",Times,serif;
		font-weight: 400;
		font-style: italic;
		line-height: 1.48;
		letter-spacing: -.012rem;
		margin: 1rem 0;
		padding:0 1rem;
	}
	img,
	iframe{
		height: auto;
		max-width: 100%;
		max-height: none;
	}
	.vid-container{
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		position: relative;
		overflow: hidden;
	}
	iframe{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;left: 0;
	}
	ul{
		margin:.5rem 0;
		list-style-position: inside;
		li{
			list-style-type: disc;
			li{
				list-style-type: circle;
				margin-left: 2rem;
				li{
					list-style-type: block;
					margin-left: 2rem;
				}
			}
		}
	}
	ol{
		margin:10px 0;
		list-style-position: inside;
		li{
			list-style-type: decimal;
			li{
				list-style-type: upper-alpha;
				margin-left: 2rem;
				li{
					list-style-type: lower-alpha;
					margin-left: 2rem;
					li{
						list-style-type: lower-latin;
						margin-left: 2rem;
					}
				}
			}
		}
	}
}
@media (max-width: 768px){
	.editor-content{font-size: 14px;}
}