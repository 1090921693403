@mixin font-en-h1() {
  font-size: 15.625em;
  font-weight: 600;
  letter-spacing: -.05em;
  line-height: 1;
}
@mixin font-en-h2() {
  font-size: 9.375em;
  font-weight: 500;
  letter-spacing: -.04em;
  line-height: 1.1333;
  text-transform: uppercase;
}
@mixin font-en-h3() {
  font-size: 8.75em;
  font-weight: 600;
  letter-spacing: -.02857em;
  line-height: 1;
}
@mixin font-en-h4() {
  font-size: 3.875em;
  font-weight: 600;
  letter-spacing: -.0323em;
  line-height: 1.1613;
}
@mixin font-en-h5() {
  font-size: 2.625em;
  font-weight: 600;
  letter-spacing: -.0238em;
  line-height: 1.2;
}
@mixin font-en-h6() {
  font-size: 2.375em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.3158;
}
@mixin font-en-subtitle-1() {
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 1.4286;
  letter-spacing: .0286em;
}
@mixin font-en-subtitle-2() {
  font-size: 2em;
  font-weight: 500;
  line-height: 1.3125;
  letter-spacing: 0;
}
@mixin font-en-subtitle-3() {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 1.53333;
  letter-spacing: 0;
}
@mixin font-en-subtitle-4() {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.1666;
  letter-spacing: 0;
  text-transform: uppercase;
}
@mixin font-en-subtitle-5() {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .05em;
  text-transform: uppercase;
}
@mixin font-en-body-1() {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.7777;
  letter-spacing: -0.0111em;
}
@mixin font-en-body-2() {
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-body-3() {
  font-size: .9375em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.0133em;
}
@mixin font-en-btn-1() {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -.0066em;
}
@mixin font-en-btn-2() {
  font-size: 1.375em;
  font-weight: 600;
  line-height: 1;
  letter-spacing: .0455em;
  text-transform: uppercase;
}
@mixin font-en-btn-3() {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-btn-4() {
  font-size: .9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
}


@mixin font-en-btn-m-1() {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-btn-m-2() {
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-btn-m-3() {
  font-size: .9375em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-smal() {
  font-size: .9375em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: .008em;
  b,
  strong{
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.0133em;
  }
}

@mixin font-en-caption-1() {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -.0125em;
}
@mixin font-en-caption-2() {
  font-size: .875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .0143em;
}

@mixin font-en-overline-1() {
  font-size: .8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}
@mixin font-en-overline-2() {
  font-size: .75em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .025em;
}


// EN-MOBILE
@mixin font-en-m-h1() {
  @include font-en-h1() ;
  font-size: 5.625em;
  letter-spacing: -.01666em;
}
@mixin font-en-m-h2() {
  font-size: 4.375em;
  font-weight: 600;
  letter-spacing: -.0171em;
  line-height: 1;
}
@mixin font-en-m-h3() {
  font-size: 3.625em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.0345;
}
@mixin font-en-m-h4() {
  font-size: 2em;
  font-weight: 500;
  letter-spacing: -.0039em;
  line-height: 1.125;
}
@mixin font-en-m-h5() {
  font-size: 1.875em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.0666;
}
@mixin font-en-m-h6() {
  @include font-en-h6() ;
  font-size: 1.6875em;
  letter-spacing: 0;
  line-height: 1.3704;
}
@mixin font-en-m-body-1() {
  font-size: 1.125em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6666;
}
@mixin font-en-m-body-2() {
  font-size: 1.0625em;
  font-weight: 600;
  letter-spacing: .02353em;
  line-height: 1;
}
@mixin font-en-m-body-3() {
  font-size: 1em;
  font-weight: 500;
  letter-spacing: -.0125em;
  line-height: 1.75;
}
@mixin font-en-m-body-4() {
  font-size: 0.75em;
  letter-spacing: .05714em;
  line-height: 1.5714;
}

@mixin font-en-m-subtitle-1() {
  font-size: 1.625em;
  font-weight: 600;
  line-height: 1.3077;
  letter-spacing: 0;
}
@mixin font-en-m-subtitle-2() {
  @include font-en-subtitle-2() ;
  font-size: 1.5625em;
  font-weight: 500;
  line-height: 1.28;
}
@mixin font-en-m-subtitle-3() {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
}
@mixin font-en-m-subtitle-4() {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.2;
}
@mixin font-en-m-caption-1() {
  font-size: .875em;
  font-weight: 400;
  line-height: 1.4286;
  letter-spacing: 0;
}

@mixin font-en-m-overline-1() {
  @include font-en-overline-1();
  letter-spacing: 0;
}
@mixin font-en-m-overline-2() {
  @include font-en-overline-2();
  letter-spacing: 0;
}


// ZH

@mixin font-zh-h1() {
  font-size: 2.625em;
  font-weight: 500;
  letter-spacing: .0357em;
  line-height: 1.4048;
}
@mixin font-zh-h2() {
  font-size: 2.375em;
  font-weight: 500;
  letter-spacing: .0263em;
  line-height: 1.7368;
}
@mixin font-zh-h3() {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: .05em;
  line-height: 1.8333;
}
@mixin font-zh-subtitle-1() {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: .04em;
}
@mixin font-zh-subtitle-2() {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.3888;
  letter-spacing: 0.0444em;
}
@mixin font-zh-body-1() {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.7777;
  letter-spacing: 0.0444em;
}
@mixin font-zh-body-2() {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}
@mixin font-zh-body-3() {
  font-size: .9375em;
  font-weight: 400;
  line-height: 1.8667;
  letter-spacing: 0.013em;
}
@mixin font-zh-caption-1() {
  font-size: .9375em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .04em;
}
@mixin font-zh-caption-2() {
  font-size: .875em;
  font-weight: 400;
  line-height: 1.5714;
  letter-spacing: .0143em;
}
@mixin font-zh-overline-1() {
  font-size: .875em;
  font-weight: 400;
  line-height: 1;
  letter-spacing: .0571em;
}

@mixin font-zh-btn-1() {
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  letter-spacing: .0313em;
}


@mixin font-zh-m-h2() {
  font-size: 1.625em;
  font-weight: 500;
  letter-spacing: .01538em;
  line-height: 1.6154;
}
@mixin font-zh-m-subtitle-1() {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.7777;
  letter-spacing: 0.0444em;
}
@mixin font-zh-m-subtitle-2() {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.025em;
}
@mixin font-zh-m-subtitle-3() {
  font-size: .9375em;
  font-weight: 400;
  line-height: 1.6667;
  letter-spacing: 0.0533em;
}

@mixin font-zh-m-caption-1() {
  font-size: .875em;
  font-weight: 400;
  line-height: 1.7143;
  letter-spacing: .05714em;
}
@mixin font-zh-m-body-2() {
  font-size: .9375em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.02667em;
  text-align: justify;
}