@charset 'utf-8';
body{
  padding-top: var(--top_padding);
  // @include transition(padding .25s ease);
}
/* -------------HEADER------------ */
#header-position{
  position: fixed;
  top: 0;left: 0;right: 0;
  z-index: 9;
  pointer-events: none;
  >*{pointer-events: all;}
}
#header {
  padding: 35px 0;
  background: $bg-black;
  color: $color-white;
  transition: background .1s, color .1s, padding .4s ease-out, transform .4s linear;
  // will-change: transform;
  // backface-visibility: hidden;
  h1{
    @include font-en-subtitle-4();
    span{
      display: block;
      opacity: 0;
      @include animation(fadeIn, 1s, 0s, ease, 1, both);
    }
    svg{
      opacity: 0;
      @include animation(fadeIn, 1s, 0s, ease, 1, both);
    }
  }
  .menu-container{
    margin-left: auto;
  }
  nav > a,
  #trigger-search span{
    transition: opacity .2s;
    &:hover,
    &:focus-visible{
      opacity: 0.5;
    }
  }
  nav{
    > a{
      display: block;
      @include font-en-btn-3();
      margin-left: 35px;
    }
  }
  &.theme-white{
    padding: 16px 0;
    background: $bg-white;
    color: $color-black;
    .container{
      align-items: center;
    }
    #header-logo{
      h1{
        display: none !important;
        opacity: 0;
      }
      svg{
        display: block !important;
        opacity: 0;
        @include animation(fadeInUp, .25s, 0s, ease, 1, both);
        path{
          fill: $color-black;
        }
      }
    }
    #trigger-search{
      svg{
        path{
          fill: $color-black;
        }
      }
    }
  }
  &.is-hidden{
    @include translate(0, -100%);
  }
}
#header-logo{
  img,
  svg{
    width: 38px;
    height: 38px;
    @include transition(all .25s);
  }
}
#trigger-search{
  margin-left: 40px;
  span{
    display: block;
    width: 44px;
    height: 44px;
    margin: -12px;
    position: relative;
  }
  img,
  svg{
    width: 20px;
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    margin: auto;
  }
}
#hamburger {
  display: none;
}
body:not(#page-index){
  // #header-position{
  //   position: sticky;
  //   top: 0;
  // }
  #header{
    @extend .theme-white;
    padding: 25px 0;
    &.is-shrink{
      padding: 16px 0;
      #header-logo{
        svg{
          width: 38px;
          height: 38px;
        }
      }
      #header-menu{margin-top: 0;}
    }
  }
  #header-logo{
    svg{
      width: 80px;
      height: 80px;
    }
  }
  #header-menu{
    margin-top: -30px;
    @include transition(margin-top .25s);
  }
}
@media (max-width: 1280px) {
  #header{
    padding: 25px 0;
    h1{
      @include font-en-m-subtitle-4();
    }
    nav{
      > a{
        @include font-en-btn-m-2();
        margin-left: 30px;
      }
    }
    &.theme-white{
      padding: 14px 0;
    }
  }
  #trigger-search{
    margin-left: 35px;
    span{
      margin: -14px;
    }
    img,
    svg{
      width: 16px;
    }
  }
  body:not(#page-index){
    #header{
      padding: 20px 0;
    }
    #header-logo{
      svg{
        width: 60px;
        height: 60px;
      }
    }
  #header-menu{
    margin-top: -20px;
  }
  }
}
@media (max-width: 1023px) {
  %ham-span {
    content: '';
    display: block;
    width: 26px;
    height: 2px;
    background: $color-white;
    position: absolute;
    top: 0;left: 0;right: 0;
    margin: 22px auto 0;
    transition: background .3s ease,top .3s ease .3s,transform .3s ease;
  }
  #hamburger {
    display: block;
    width: 44px;
    height: 44px;
    margin: -10px;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    span {
      @extend %ham-span;
    }
    &:before {
      @extend %ham-span;
      top: -9px;
    }
    &:after {
      @extend %ham-span;
      top: 9px;
    }
    &.is-active {
      &:before,
      &:after{
        opacity: 1;
        top: 0;
        transition: top .3s ease,transform .3s ease .3s;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
      span {
        background: transparent !important;
      }
    }
  }
  #header{
    nav{
      flex-direction: column;
      >a{
        @include font-en-m-h5();
        font-weight: 400;
        margin-left: 0;
        margin-bottom: 24px;
      }
    }
    &.theme-white{
      #header-logo{
        img,
        svg{
          width: 38px;
          height: 38px;
        }
      }
      %ham-span{background: $color-black; }
      #header-menu{
        &:before{
          background: $bg-white;
        }
      }
    }
    &.is-active{
      // transform: translate(0,0) !important;
      transform: none !important;
      #header-logo{
        h1{display: none;}
        svg{
          display: block !important;
          opacity: 0;
          @include animation(fadeInUp, .25s, 0s, ease, 1, both);
          path{
            fill: $color-white;
          }
        }
      }
      &.theme-white{
        #header-logo{
          svg{
            path{
              fill: $color-black;
            }
          }
        }
      }
      #header-menu{
        display: flex !important;
        &:before{
          @include scale(1,1);
        }
        nav{
          > a{
            // opacity: 1;
            // @include translate(0,0);
            // transition: transform .4s ease-out, opacity .6s;
            @include animation(fadeInUp, .8s, 0s, ease, 1, both);
            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                @include animation-delay($i * .12s);
              }
            }
          }
        }
        > p{
          @include animation(fadeInUp, .8s, .8s, ease, 1, both);
        }
        > small{
          @include animation(fadeInUp, .8s, 1s, ease, 1, both);
        }
      }
    }
    &.animate-out{
      #header-menu{
        &:before{
          @include scale(1, 0);
          transform-origin: center bottom;
          transition: transform .8s .4s ease;
        }
        nav{
          > a{
            @include animation(fadeOutDown, .25s, 0s, ease, 1, both);
            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                @include animation-delay(.5 - $i * .1s);
              }
            }
          }
        }
        > p{
          @include animation(fadeOutDown, .25s, .1s, ease, 1, both);
        }
        > small{
          @include animation(fadeOutDown, .25s, 0s, ease, 1, both);
        }
      }
    }
  }
  #header-logo{
    img,
    svg{
      width: 48px;
      height: 48px;
    }
  }
  #header-menu{
    flex-direction: column;
    // height: 100vh;
    height: 100%;
    padding-top: 180px;
    padding-inline: 40px;
    position: fixed;
    top: 0;left: 0;right: 0;
    z-index: -1;
    &:before{
      content:'';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;left: 0;
      background: $bg-black;
      z-index: -1;
      @include scale(1, 0);
      transform-origin: center top;
      transition: transform 1s .4s ease;
    }
    nav{
      > a{
        opacity: 0;
      }
    }
    > p,
    > small{
      opacity: 0;
    }
    > p{
      margin-top: 60px;
      margin-bottom: 10px;
      @include font-en-m-subtitle-4();
      font-weight: 400;
    }
    > small{
      display: block;
      margin-top: auto;
      margin-bottom: 40px;
    }
  }
  #cookie-banner{
    &:not(.is-hidden){
      ~#header{
        #header-menu{
          // height: calc(100vh - 37px);
          height: calc(100% - 37px);
          top: 37px;
        }
      }
    }
  }
  body:not(#page-index){
    #hamburger{margin-top: -20px;}
    #header-menu{
      margin-top: 0;
    }
    &.is-shrink{
      #hamburger{margin-top: -10px;}
    }
  }
}
@media (max-width: 414px) {
  #header{
    padding: 17px 0;
    &.theme-white{
      padding: 11px 0;
    }
  }
  body:not(#page-index){
    #header{
      &.is-shrink{
        padding:11px 0;
      }
    }
  }
  #header-logo{
    img,
    svg{
      width: 38px;
      height: 38px;
    }
  }
  #header-menu{
    padding-top: 120px;
    padding-inline: 30px;
    > small{
      margin-bottom: 30px;
    }
  }
}
@media (max-width:1023px) and (max-height: 730px){
  #header{
    nav{
      > a{
        margin-bottom: 16px;
      }
    }
  }
  #header-menu{
    padding-top: 100px;
    > p{margin-top: 20px;}
    > small{
      margin-bottom: 20px;
    }
  }
}