@charset 'utf-8';
.sec-main-title{
  &.title-about{
    h2{margin-bottom: 0;}
  }
}
%about{
  padding: 80px 0 160px;
  h2.font-en-h3{margin-bottom: 80px;}
}
.sec-intro-about{
  @extend %about;
  h2{margin-bottom: 60px;}
  p{
    margin-bottom: 1.3333em;
    &:last-child{margin-bottom: 0;}
    span{
      display: block;
      margin-bottom: 1.25em;
      &:last-child{margin-bottom: 0;}
    }
  }
  .title{
    p{max-width: 15em; }
  }
}
.sec-client-about{
  @extend %about;
  .logo-container{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-row-gap: 140px;
    justify-content: space-between;
    margin-top: 60px;
  }
  .logo-container{
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10%;
  }
  .logo{
    max-width: none;
    width: 100%;
  }
  .logo{
    max-width: 240px;
    display: block;
  }
  img{
    width: 100%;
    margin: 0 auto;
  }
}
.sec-team{
  @extend %about;
  .accordion-item{
    &.is-active{
      .content{
        display: grid;
      }
    }
  }
  .title{
    padding: 22px 0 21px;
  }
  .name{
    padding-left: 24px;
  }
  .content{
    padding: 24px 0 60px;
    figure{
      margin-left: 24px;
      img{
        max-width: 240px;
      }
    }
  }
  .txt-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 48px;
    padding-right: 24px;
  }
}
.sec-awards{
  @extend %about;
  li{
    > div,
    > a{
      padding: 22px 0;
      border-bottom: 2px solid $color-stroke-grey-1;
      @include transition(border .25s);
    }
  }
  .year{padding-left: 24px;}
  a{
    position: relative;
    .description{padding-right: 50px; }
    &:after{
      content:'';
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      background: url(/assets/img/icon/arrow-right-black.svg) center no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;bottom: 0;
      right: 24px;
      margin: auto 0;
      opacity: 0.3;
      @include translate(-15px, 0);
      @include transition(transform .3s cubic-bezier(.17,.67,.73,1.32), opacity .25s);
    }
    &:hover,
    &:focus-visible{
      border-color: $color-black;
      &:after{
        opacity: 1;
        @include translate(0, 0);
      }
      p{
        color: $color-black;
      }
    }
  }
  button{
    display: block;
    padding: 12px 24px;
    margin: 15px auto 0;
    @include transition(color .25s);
    position: relative;
    i{
      width: 1rem;
      height: 1rem;
      // margin-left: 0.5rem;
      // margin-top: -0.25rem;
      // position: relative;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      &:before,
      &:after{
        content:'';
        display: block;
        width: .75rem;
        height: 1px;
        background: $color-txt-grey-1;
        position: absolute;
        top: 0;right: 0;bottom: 0;left: 0;
        margin:auto;
        @include transition(transform .25s, background .25s);
      }
      &:after{@include rotate(90); }
    }
    &:hover,
    &:focus-visible{
      color: $color-black;
      i{
        &:before,
        &:after{
          background: $color-black;
        }
      }
    }
    &.is-hidden{
      display: none;
      visibility: none;
    }
  }
}
#list-awards{
  position: relative;
  &.has-more{
    &:after{
      content:'';
      display: block;
      width: 100%;
      height: 152px;
      position: absolute;
      bottom: 0;left: 0;
      @include linear-gradient(180deg, transparentize(white, 1), transparentize(white, 0));
      z-index: 2;
      user-select: none;
    }
    // > li{
    //   &:nth-child(n+8){
    //     display: none;
    //   }
    // }
  }
  // @for $i from 1 through 20 {
  //   &.is-shown-#{$i} {
  //     > li{
  //       &:nth-child(n){
  //         display: block;
  //       }
  //       &:nth-child(n+#{$i * 7 + 1}){
  //         display: none;
  //       }
  //     }
  //   }
  // }
}
/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-team{
    .title{
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      grid-column-gap: 0;
    }
    .name{
      flex-shrink: 0;
      width: 21.75rem;
      padding-left: 0;
      margin-inline: 24px;
    }
    .position{
      width: 100%;
    }
    .accordion-item{
      &.is-active{
        .content{
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          grid-column-gap: 0;
        }
      }
    }
    figure{
      flex-shrink: 0;
      width: 23.25rem;
    }
    .txt-container{
      width: 100%;
    }
  }
  .sec-awards{
    li{
      > div,
      > a{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        grid-column-gap: 0;
      }
    }
    .year{
      flex-shrink: 0;
      width: 5.25rem;
      padding-left: 0;
      margin-inline: 24px;
    }
    .name{
      flex-shrink: 0;
      width: 23.25rem;
      margin-right: 24px;
    }
    .description{
      width: 100%;
      padding-right: 1.5rem;
    }
  }
}
@media (max-width: 1440px) {
  .sec-intro-about{
    .title{
      p{max-width: 12em; }
    }
    .font-en-h5{
      font-size: 2.4306vw;
      line-height: 1.2;
      letter-spacing: -.0571em;
    }
    .font-zh-h1{
      font-size: 2.4306vw;
      line-height: 1.4;
      letter-spacing: .04286em;
    }
  }
  .sec-client-about{
    img{
      max-width: 200px;
    }
  }
}
@media (max-width: 1280px) {
  %about{
    padding: 60px 0 120px;
    h2.font-en-h3{
      font-size: 6em;
      line-height: 1;
      letter-spacing: -.04167em;
      margin-bottom: 80px;
    }
  }
  .sec-client-about{
    .logo-container{
      grid-template-columns: repeat(5, auto);
      margin-top: 0;
      grid-row-gap: 80px;
    }
    .logo{
      max-width: 138px;
    }
  }
  .sec-team{
    .title{
      padding: 15px;
      display: block;
      p{
        @include font-en-m-body-3();
      }
    }
    .name{
      padding-left: 0;
      margin-bottom: 3px;
    }
    .content{
      padding-bottom: 30px;
    }
    .txt-container{
      display: block;
      padding-right: 24px;
      p{
        width: 100%;
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
          padding-right: 0;
        }
      }
    }
  }
  .sec-awards{
    li{
      > div,
      > a{
        padding: 20px 15px;
        display: block;
      }
      .year{
        @include font-en-btn-m-2();
        padding-left: 0;
        margin-bottom: 5px;
      }
      .name{
        @include font-zh-m-subtitle-2();
        margin-bottom: 3px;
      }
      .description{
        @include font-zh-m-subtitle-3();
      }
    }
    a{
      &:after{
        width: 1rem;
        height: 1rem;
        right: 15px;
        top: auto;
        bottom: 23px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .sec-main-title{
    &.title-about{
      padding-top: 20px;
    }
  }
  .sec-intro-about{
    .g-col-5,
    .g-col-7{
      grid-column: auto/span 12;
      grid-column-start:1;
    }
    .title{
      p{max-width: 11em; }
    }
    .font-en-h5{
      font-size: 6.0547vw;
      line-height: 1.1613;
      letter-spacing: -.03226em;
      margin-bottom: 32px !important;
    }
    .font-zh-h1{
      font-size: 3.125vw;
      line-height: 1.4063;
      letter-spacing: .04688em;
    }
    h2{margin-bottom: 80px;}
  }
  .sec-team{
    .accordion-item{
      &.is-active{
        .content{
          display: block;
        }
      }
    }
    .content{
      padding: 20px 15px 30px;
      figure{
        width: 185px;
        margin-left: 0;
        margin-bottom: 30px;
      }
    }
    .txt-container{
      padding-right: 0;
      p{margin-bottom: 20px;}
    }
  }
}
@media (max-width: 1023px) {
  .sec-main-title{
    &.title-about{
      padding-top: 60px;
    }
  }
  .sec-about{
  }
}
@media (max-width: 768px) {
  .sec-main-title{
    &.title-about{
      padding-top: 80px;
    }
  }
  .sec-intro-about{
    .font-en-h5{
      font-size: 8.0729vw;
      letter-spacing: 0;
    }
    .font-zh-h1{
      font-size: 4.1666vw;
    }
  }
  .sec-client-about{
    .logo-container{
      grid-template-columns: repeat(3, auto);
    }
  }
  .sec-awards{
    li{
      > div,
      > a{
        border-bottom-width: 1px;
      }
    }
    a{
      &:after{
        @include translate(0, 0);
      }
      &:hover,
      &:focus-visible{
        border-color: $color-black;
        p{color: $color-black; }
      }
    }
  }
}
@media (max-width: 414px) {
  %about{
    padding-bottom: 80px;
    h2.font-en-h3{
      font-size: 16.9082vw;
      line-height: 1;
      letter-spacing: -.0171em;
      margin-bottom: 60px;
    }
  }
  .sec-intro-about{
    h2{margin-bottom: 60px;}
    .font-zh-h1{
      font-size: 6.2802vw;
    }
    .font-zh-body-1{
      @include font-zh-m-subtitle-2();
    }
    .font-en-body-1{
      @include font-en-m-body-3();
    }
  }
  .sec-client-about{
    .logo-container{
      grid-row-gap: 60px;
    }
  }
}
@media (max-width: 360px) {
  .sec-about{
  }
}