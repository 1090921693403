@charset 'utf-8';
/* -------------CTA SECTION------------ */
.sec-cta {
  padding-bottom: 90px;
  hr {
    border-top: 2px solid $color-black;
    margin-bottom: 60px;
  }
  h2{max-width: 10em;}
  .btn {
    margin: 20px 0 0;
    i {
      width: 1.5625rem;
      height: 1.5625rem;

    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-cta {
    padding-bottom: 80px;
    hr {
      margin-bottom: 40px;
    }
    h2 {
      font-size: 9.027vw;
      line-height: 1.15384em;
    }
    .btn{
      font-size: 1.75em;
    }
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .sec-cta {
    padding-bottom: 60px;
    hr {
      margin-bottom: 60px;
    }
    h2 {
      font-size: 14.0625vw;
      line-height: 1;
      letter-spacing: -.037em;
    }
    .btn{
      @include font-en-m-subtitle-2();
    }
  }
}
@media (max-width: 414px) {
  .sec-cta {
    hr {
      margin-bottom: 50px;
    }
    h2 {
      font-size: 15.1vw;
    }
    .btn{
      font-size: 1.5625em;
      line-height: 1.28;
      letter-spacing: 0;
    }
  }
}
@media (max-width: 360px){
  .sec-cta{
    .btn{
      font-size: 1.375em;
    }
  }
}