@charset 'utf-8';
.sec-news-list{
  padding-top: 30px;
  @include transition(all .6s ease);
  position: relative;
  z-index: 2;
  h2{
    max-width: 15em;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 80px;
  }
  .grid-highlight{
    .news-item{
      .content{
        h3{
          @include font-zh-subtitle-1();
          line-height: 1.9;
          text-transform: none;
        }
      }
    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .sec-news-list{
    .grid-highlight{
      .g-col-4{
        grid-column: auto/span 5;
      }
      .g-col-8{
        grid-column: auto/span 7;
        grid-column-start: 6;
      }
    }
    h2{
      font-size: 1.75em;
      line-height: 1.3571;
      letter-spacing: 0;
    }
  }
}
@media (max-width: 1024px) {
  .sec-news-list{
    h2{
      font-size: 1.375em;
      line-height: 1.4545;
    }
  }
}
@media (max-width: 1023px) {
  .sec-news-list{
    .grid-highlight{
      .g-col-4{
        grid-column: auto/span 6;
      }
      .g-col-8{
        grid-column: auto/span 6;
        grid-column-start: 1;
      }
    }
    h2{
      font-size: 1.5625em;
      margin-top: 0;
      max-width: none;
      nobr{
        &:last-child{
          display: block;
        }
      }
    }
  }
}
@media (max-width: 768px) {

}
@media (max-width: 414px) {
  .sec-news-list{
    padding-top: 80px;
    h2{
      @include font-en-m-subtitle-2();
      margin-bottom: 80px;
    }
  }
}
@media (max-width: 360px) {
  .sec-news-list{
    h2{
      font-size: 6.48vw;
      line-height: 1.3182;
      // letter-spacing: 0;
    }
  }
}