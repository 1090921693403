@charset 'utf-8';
.sec-main-title{
  &.title-privacy{
    padding:50px 0 200px;
    h2{margin-bottom: 0;}
  }
}
.sec-privacy{
  padding-bottom: 100px;
  h3{
    font-weight: 500;
  }
  p{
    @include font-zh-body-2();
  }
  .flex-nowrap{
    padding: 30px 0 60px;
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-main-title{
    &.title-privacy{
      h2{
        font-size: 7.6389vw;
      }
    }
  }
}
@media (max-width: 1280px) {
  .sec-privacy{
  }
}
@media (max-width: 1024px) {
  .sec-privacy{
    .flex-nowrap{
      flex-direction: column;
    }
    .col-30,
    .col-70{
      width: 100%;
    }
    h3{
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 1023px) {
  .sec-privacy{
  }
}
@media (max-width: 768px) {
  .sec-main-title{
    &.title-privacy{
      padding:40px 0 140px;
      h2{
        font-size: 16.9082vw;
        text-align: left;
      }
    }
  }

}
@media (max-width: 414px) {
  .sec-main-title{
    &.title-privacy{
      padding:40px 0 140px;
    }
  }
  .sec-privacy{
    padding-bottom: 80px;
    p{
      @include font-zh-m-body-2();
    }
  }
}
@media (max-width: 360px) {
  .sec-privacy{
  }
}