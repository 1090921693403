@charset 'utf-8';
/* -------------SMOOTH SCROLL----- */
  html{
    height: -webkit-fill-available;
    overflow: hidden;
    // scroll-behavior: smooth;
    &.is-loaded{
      overflow: unset;
    }
    &.is-closed{ // for scroll position reset before refresh
      header,
      main,
      footer,
      .modal{opacity: 0;}
    }
    &.is-noscroll{
      overflow: hidden;
    }

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      main{ // for ios mobile overscroll/elastic scroll area
        clip-path: inset(0);
      }
      &:before{ // for ios mobile overscroll/elastic scroll area
        content:'';
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;left: 0;
        background: white;
      }
    }
  }
/* -------------SELECTION--------- */
  ::selection      {background: $color-black; color: $color-white; }
  ::-moz-selection {background: $color-black; color: $color-white; }
/* -------------MAIN-------------- */