@charset 'utf-8';
.sec-main-title{
  position: relative;
  overflow: hidden;
  z-index: 3;
  @include transition(height linear .6s);
  h2{margin-bottom: 20px;}
  video{
    width: 80%;
    max-width: 1000px;
    margin: 10px auto 100px;
  }
}
/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-main-title{
    // h2{font-size: 250px; }
    video{max-width: 1002px; }
  }
}
@media (min-width: 2000px) {
  .sec-main-title{
    video{
      // max-width: 80em;
    }
  }
}
@media (max-width: 1440px) {
  .sec-main-title{
    h2{
      font-size: 14.8438vw;
    }
  }
}
@media (max-width: 1280px) {
  .sec-main-title{
    video{
      width: 61.875%;
      // max-width: 792px;
    }
  }
}
@media (max-width: 1024px) {
  .sec-main-title{
    video{
      width: 73.8281%;
      // max-width: 756px;
    }
  }
}
@media (max-width: 1023px) {
}
@media (max-width: 768px) {
  .sec-main-title{
    h2{
      font-size: 23.4375vw;
    }
    video{
      width: 110%;
      margin: 60px -5% 140px;
    }
  }
}
@media (max-width: 414px) {
}
@media (max-width: 360px) {
  .sec-main-title{
    video{
      margin-bottom: 120px;
    }
  }
}