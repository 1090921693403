@charset 'utf-8';
.modal{
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;left: 0;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 10;
  &.is-hidden{
    pointer-events: none;
    .filter{
      opacity: 0;
    }
    .bg-white{
      @include translate(100%, 0);
    }
  	.modal-title,
  	.btn-close{
  		opacity: 0;
  		@include animation-name(none);
	  }
  	.modal-content{
  		opacity: 0;
  		@include animation-name(none);
  	}
  }
  .filter{
    background: transparentize($color-black, .3);
    position: fixed;
    inset: 0;
    @include transition(opacity .4s ease);
  }
  .bg-white{
    min-height: 100%;
    margin-left: 120px;
    @include transition(transform .6s ease);
    position: relative;
    z-index: 2;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
  }
  .modal-title{
  	padding-block: 66px 10px;
  	@include animation(fadeIn, .6s, .6s, ease, 1, both);
    pointer-events: none;
    > div{
      display: inline-block;
      pointer-events: all;
    }
  }
  .modal-content{
  	@include animation(fadeInUp, .6s, .75s, ease, 1, both);
  }
  .btn-container{
    position: sticky;
    top: 60px;
    mix-blend-mode: exclusion;
    z-index: 3;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      filter: invert(1);
    }
  }
  .btn-close{
    position: absolute;
    // top: 66px;
    right: 60px;
  	// margin-top: -20px;
  }
}
_::-webkit-full-page-media, _:future, :root .modal .btn-container { /* Safari (7.1+) */
  filter: invert(1);
}
@media (min-width: 1681px) {
	.modal{
		.bg-white{margin-left: 140px;}
	}
}
@media (max-width: 1440px) {
	.modal{
		.bg-white{margin-left: 100px;}
	}
}
@media (max-width: 1280px) {
	.modal{
		.bg-white{margin-left: 80px;}
		.modal-title{
			padding-block: 46px;
		}
    .btn-container{top: 40px; }
	  .btn-close{
	  	right: 40px;
	  }
	}
}
@media (max-width: 1024px) {
	.modal{
  	.modal-title{
  		.font-en-subtitle-5{
  			@include font-en-caption-2();
  			font-weight: bold;
  		}
  	}
	}
}
@media (max-width: 1023px) {
	.modal{
		.btn-close{right: 25px; }
	}
}
@media (max-width: 768px) {
	.modal{
		.bg-white{margin-left: 60px;}
		.modal-title{
			padding-block: 36px calc(36px - 17px);
		}
	  .btn-container{top: 30px; }
	}
}
@media (max-width: 414px) {
	.modal{
		.bg-white{margin-left: 40px;}
    .btn-close{right: 17px; }
	}
}
@media (max-width: 360px) {
	.modal{
		.bg-white{margin-left: 20px;}
	}
}