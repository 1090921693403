@charset 'utf-8';
.sec-featured{
  padding-top: 30px;
  // margin-bottom: -50%;
  @include transition(all .6s ease);
  position: relative;
  z-index: 2;
  h2{
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 80px;
  }
}
.sec-list{
  padding-top: 30px;
  .work-item{
    margin-bottom: 80px;
    &:nth-child(n){
      grid-column: auto/span 6;
    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .sec-featured{
    h2{
      font-size: 1.75em;
      line-height: 1.3571;
      letter-spacing: 0;
    }
  }
  .sec-list{
    .work-item{
      margin-bottom: 60px;
    }
  }
}
@media (max-width: 1024px) {
  .sec-featured{
    h2{
      font-size: 1.375em;
      line-height: 1.4545;
    }
  }
}
@media (max-width: 1023px) {
  .sec-featured{
    h2{margin-top: 0;}
  }
  .sec-list{
    padding-top:80px;
  }
}
@media (max-width: 768px) {

}
@media (max-width: 414px) {
  .sec-featured{
    padding-top: 80px;
    h2{
      @include font-en-m-subtitle-2();
      margin-bottom: 80px;
    }
  }
}
@media (max-width: 360px) {
  .sec-featured{
    h2{
      font-size: 6.88vw;
      line-height: 1.3182;
      letter-spacing: 0;
    }
  }
}