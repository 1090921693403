// COLOR
  $color-txt-grey-1: #808080;
  $color-txt-grey-2: #B9B9B9;
  $color-stroke-grey-1: #D0D0D0;
  $color-stroke-grey-2: #EEEEEE;

  $color-white: #ffffff;
  $color-dark: #444444;
  $color-black: #000000;

  // $color-error: #c75555;
  $color-disabled: $color-txt-grey-1;

// BACKGROUND
  $bg-white: #ffffff;
  $bg-light: #f9f9f9;
  $bg-dark:  #222222;
  $bg-black: #000000;

// FONT
$font-content: "Messina Sans", -apple-system, "Pingfang TC", BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-title: $font-content;

$font-zh: "Pingfang TC", -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// FONT-SIZE