@charset 'utf-8';
.sec-main-title{
  &.title-work,
  &.title-news{
    padding-top: 10px;
  }
}
#page-work-article{
  padding-top: 0 !important;
  #header-position{
    position: fixed !important;
    #header{
      background: transparent;
      &.is-shrink{
        background: white;
      }
    }
    &.is-white{
      &:not(.is-open){
        #header:not(.is-shrink){
          color: white;
          #header-logo,
          #trigger-search{
            svg path{
              fill: white;
            }
          }
          #hamburger{
            &:before,
            &:after,
            span{
              background: white;
            }
          }
        }
      }
      #header.animate-out{
        color: white;
        #header-logo,
        #trigger-search{
          svg path{
            fill: white;
          }
        }
        #hamburger{
          &:before,
          &:after,
          span{
            background: white;
          }
        }
      }
    }
    &.is-open{
      background: white;
    }
    // &.is-different:not(.is-open){
    //   mix-blend-mode: exclusion;
    //   #header{
    //     &:not(.is-active){
    //       filter: invert(100%);
    //     }
    //   }
    // }
  }
  .sec-more{
    padding-bottom: 60px;
    h2{margin-bottom: 30px;}
  }
}
.sec-work{
  .bg-container{
    background: $color-white;
    color: $color-black;
    position: relative;
    z-index: 2;
    h2,
    h3,
    p,
    a{
      color: inherit;
    }
  }
  .module-hero{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    img,
    video,
    .vid-container{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;left: 0;
      object-fit: cover;
    }
    iframe{
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .module-intro{
    padding: 100px 0 160px;
    background: $bg-white;
    position: relative;
    z-index: 2;
    .switch-container{
      margin-top: 0;
      margin-bottom: 60px;
    }
    .content{
      h2{
        @include font-en-body-3();
        line-height: 1;
        letter-spacing: 0.08em;
        margin-bottom: 16px;
        font-weight: 600;
      }
      h3{
        @include font-en-subtitle-1();
        font-weight: 400;
        margin-bottom: 80px;
      }
      p{
        @include font-zh-subtitle-1();
        font-weight: 400;
        color: #404040;
      }
      &.lang-zh,
      &.lang-jp{
        p{
          text-align: justify;
        }
      }
      &.lang-en{
        p{
          letter-spacing: 0.01em;
          line-height: 1.75;
        }
      }
    }
    .info{
      display: flex;
      justify-content: flex-end;
      ul{
        width: 178px;
        margin-left: 24px;
      }
      li{margin-bottom: 20px;}
      h4{
        font-weight: bold;
        margin-bottom: 6px;
      }
      p{
        color: $color-txt-grey-1;
      }
      .list-related{
        p,
        a{
          @include font-zh-caption-2();
          color: $color-txt-grey-1;
          margin-bottom: 6px;
        }
        p{
          a{
            margin-bottom: 0;
          }
        }
        div > br{display: none;}
      }
      a{
        display: block;
        color: $color-txt-grey-1;
        text-decoration: underline;
        margin-bottom: 6px;
        &:hover,
        &:focus-visible{
          color: $color-black;
        }
      }
    }
  }
  .module-txt{
    padding: 160px 0;
    h2{
      margin-bottom: 14px;
    }
    p{
      font-weight: 400;
      text-align: justify;
    }
    .link-underline{
      margin-top: 30px;
      font-weight: 400;
      &:hover,
      &:focus-visible{
        color: $color-txt-grey-1 !important;
      }
    }
    // &.lang-zh,
    // &.lang-jp{
    //   h2,
    //   p,
    //   .link-underline{
    //     font-family: $font-zh;
    //   }
    // }
    &.lang-en{
      h2{
        font-weight: 600;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 20px;
      }
      p{
        letter-spacing: .01em;
        line-height: 1.75;
        text-align: left;
      }
      .link-underline{
        font-weight: 500;
      }
    }
    + .module-img,
    + .module-img-left,
    + .module-img-right,
    + .module-img-col2,
    + .module-carousel,
    + .module-compare{
      padding-top: 0;
    }
  }
  .module-quote{
    @extend .module-txt;
    .name{
      margin-top: 20px;
      font-weight: 400;
      text-align: left;
    }
    &.lang-en{
      p{
        line-height: 1.75;
      }
      .name{
        font-weight: 500;
        letter-spacing: .0111em;
      }
    }
  }
  .module-img,
  .module-img-left,
  .module-img-right,
  .module-img-col2,
  .module-img-col3,
  .module-img-col12,
  .module-carousel,
  .module-compare,
  .module-full-carousel{
    padding: 30px 0;
    +.module-txt,
    +.module-quote{
      margin-top: -30px;
    }
    + .module-img-full,
    + .module-full-carousel,
    + .module-compare{
      padding-top: 30px;
    }
  }
  .module-compare{
    padding-top: 0;
  }
  .module-img-full,
  .module-full-carousel,
  .module-compare{
    + .module-img,
    + .module-img-left,
    + .module-img-right,
    + .module-img-col2,
    + .module-img-col3,
    + .module-img-col12,
    + .module-carousel,
    + .module-compare{
      padding-top: 60px;
    }
  }
  // .module-img-col2{
  //   padding-top: 30px;
  // }
  .module-carousel{
    background: inherit;
    position: relative;
    overflow: hidden;
    .swiper-carousel{
      position: relative;
    }
    .swiper-slide{
      background: inherit;
      padding: 0 calc((100vw - 120px + 24px) / 12 / 2);
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next{
      margin: 0 calc((100vw - 120px + 24px) / 12 / 4);
    }
  }
  .module-full-carousel{
    background: inherit;
    position: relative;
    overflow: hidden;
    .full-swiper-carousel{
      position: relative;
      overflow: hidden;
    }
    .swiper-slide{
      display: block;
      position: relative;
      &:before{
        content:'';
        display: block;
        position: absolute;
        inset: 0;
        background: transparentize($color-black, .5);
        opacity: 0;
        @include transition(opacity .4s ease);
      }
      img{object-fit: cover; }
    }
    .swiper-controls{
      margin-top: 10px;
    }
    .swiper-pagination{
      top: 0;
      margin-top: 0;
    }
    .swiper-button-prev,
    .swiper-button-next{
      display: block;
      width: auto;
      height: auto;
      position: relative;
      mix-blend-mode: unset;
      padding-left: 15px;
      img{width: 16px; }
    }
  }
  .module-compare{
    .compare-container{
      position: relative;
      text-align: center;
    }
    figure{
      position: relative;
      overflow: hidden;
      img{
        @include transition(opacity .6s ease);
      }
      .img-after{
        position: absolute;
        top: 0;left: 0;
      }
    }
    .compare-switch{
      position: absolute;
      bottom: 0;left: 0;right: 0;
      margin: 0 auto 20px;
      z-index: 3;
    }
    .switch-container{margin: 0;}
    .switch{
      button{
        width: 4em;
        @include font-en-overline-1();
        color: $color-txt-grey-2;
      }
    }
  }
  .module-credit{
    @extend .module-intro;
    padding: 60px 0 100px !important;
    .container{
      position: relative;
    }
    h2{
      text-transform: none;
      margin-bottom: 30px;
    }
    .info{
      display: grid;
      ul{
        width: auto;
        margin-left: 0;
      }
      li{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .scroll-top-container{
      height: 0;
    }
  }
  .is-blank{
    video,
    img{display: none;}
  }
  #scroll-top{
    width: 60px;
    height: 60px;
    @include transition(transform .25s);
    &:hover,
    &:focus-visible{
      @include translate(0, -4px);
    }
    &:active{
      @include translate(0, -2px);
    }
  }
}
/* -------------RWD--------------- */
@media (min-width: 2042px) {
  .sec-work{
    .module-intro{
      .info{
        ul{
          width: 230px;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .sec-work{
    .module-intro{
      .content{
        h3{margin-bottom: 60px;}
      }
    }
    .module-txt{
      padding: 140px 0;
      .content{
        grid-column: auto/span 8;
        grid-column-start: 3;
      }
    }
  }
  #page-work-article{
    .sec-more{
      h2{
        font-size: 6.5625em;
        line-height: 1;
        letter-spacing: -.0381em;
      }
    }
  }
}
@media (max-width: 1280px) {
  .sec-main-title{
    &.title-work,
    &.title-news{
      padding-top: 20px;
    }
  }
  .sec-work{
    .module-intro{
      padding: 40px 0 60px;
      .switch-container{
        // order: 0;
        margin: 0 0 35px 0;
      }
      .content{
        grid-column: auto/span 12;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 60px;
        // > *{
        //   order: 1;
        // }
        h3{margin-bottom: 40px;}
      }
      .info{
        grid-column: auto/span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-row-gap: 0;
        grid-column-gap: 24px;
        ul{
          width: auto;
          grid-column: auto/span 6;
          margin-left: 0;
        }
        li{margin-bottom: 15px;}
      }
    }
    .module-txt{
      padding: 120px 0;
      .content{
        grid-column: auto/span 10;
        grid-column-start: 2;
      }
    }
    .module-img,
    .module-img-left,
    .module-img-right,
    .module-img-col2,
    .module-img-col3,
    .module-img-col12{
      padding: 20px 0;
      +.module-txt,
      +.module-quote{
        margin-top: -20px;
      }
    }
    .module-img-left,
    .module-img-right{
      .g-col-8{
        grid-column: auto/span 12;
        grid-column-start: 0;
      }
    }
    .module-img-col2{
      // padding-top: 20px;
      .grid{grid-row-gap: 20px; }
      .g-col-6{
        grid-column: auto/span 12;
        margin-bottom: 0;
        &.is-blank{display: none;}
      }
    }
    .module-img-col3{
      .grid{grid-row-gap: 20px; }
      .g-col-4{
        grid-column: auto/span 12;
        margin-bottom: 0;
        &.is-blank{display: none;}
      }
    }
    .module-img-col12{
      .g-col-4,
      .g-col-8{
        grid-column: auto/span 12;
        margin-bottom: 20px;
        &:last-child{margin-bottom: 0;}
      }
    }
    .module-carousel{
      .swiper-slide{
        padding: 0 5px;
      }
    }
    .module-compare{
      .g-col-10{
        grid-column: auto/span 12;
        grid-column-start: 0;
      }
      .compare-switch{
        position: relative;
        margin-top: 10px;
        margin-bottom: 0;
      }
      .switch{
        button{
          @include font-en-overline-1();
          color: $color-txt-grey-2;
        }
      }
    }
    .module-credit{
      padding: 40px 0 100px !important;
      .info{
        h4{margin-bottom: 4px;}
      }
    }
  }
  #page-work-article{
    .sec-more{
      h2{
        font-size: 8.2031vw;
      }
    }
  }
}
@media (max-width: 1024px) {
  .sec-work{
    .module-txt{
      padding: 100px 0;
      .content{
        grid-column: auto/span 12;
        grid-column-start: 0;
      }
    }
    .module-credit{
      padding: 80px 0 !important;
      li{margin-bottom: 20px;}
    }
    #scroll-top{
      width: 40px;
      height: 40px;
    }
  }
}
@media (max-width: 1023px) {
  .sec-work{
    .module-txt{
      padding: 80px 0;
    }
    .module-carousel{
      .swiper-width{
        grid-column: auto/span 6;
        grid-column-start: 1;
      }
    }
  }
}
@media (max-width: 768px) {
  .sec-main-title{
    &.title-work,
    &.title-news{
      padding-top: 54px;
    }
  }
  .sec-work{
    .module-hero{
      height: 75vw;
      img,
      video,
      .vid-container{
        height: 75vw;
      }
      iframe{
        min-height: 75vw;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      }
    }
    .module-intro{
      .content{
        h2{
          @include font-en-m-body-4();
          margin-bottom: 8px;
        }
        h3{
          @include font-en-m-subtitle-1();
          font-weight: 400;
        }
        p{
          @include font-zh-m-subtitle-2();
          letter-spacing: 0.0125em;
        }
      }
    }
    .module-txt{
      h2{
        @include font-zh-m-subtitle-3();
        font-weight: 500;
      }
      p{
        @include font-zh-m-subtitle-2();
        letter-spacing: .0125em;
        font-weight: 300;
      }
      .link-underline{
        margin-top: 15px;
      }
      &.font-en{
        p{font-weight: 400;}
      }
    }
    .module-quote{
      .name{
        letter-spacing: .05em;
      }
      &.font-en{
        .name{
          letter-spacing: .01333em;
        }
      }
    }
    .module-credit{
      padding: 60px 0 100px !important;
    }
  }
  #page-work-article{
    .sec-more{
      .title-container{
        margin-bottom: 20px;
      }
      h2{
        @include font-en-m-h6();
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 414px) {
  .sec-main-title{
    &.title-work,
    &.title-news{
      padding-top: 60px;
      h2{margin-bottom: 15px;}
    }
  }
  #page-work-article{
    .sec-more{
      padding-bottom: 40px;
    }
  }
  .sec-work{
    .module-txt{
      h2{
        margin-bottom: 15px;
      }
    }
    .module-credit{
      padding-bottom: 60px !important;
    }
    .module-img,
    .module-img-left,
    .module-img-right,
    .module-img-col2,
    .module-img-col3,
    .module-img-col12,
    .module-carousel,
    .module-compare,
    .module-full-carousel{
      padding: 9px 0;
      +.module-txt,
      +.module-quote{
        margin-top: -9px;
      }
      + .module-img-full,
      + .module-full-carousel,
      + .module-compare{
        padding-top: 9px;
      }
    }
    .module-compare{padding-top: 0;}
    .module-img-full,
    .module-full-carousel,
    .module-compare{
      + .module-img,
      + .module-img-left,
      + .module-img-right,
      + .module-img-col2,
      + .module-img-col3,
      + .module-img-col12,
      + .module-carousel,
      + .module-compare{
        padding-top: 18px;
      }
    }
    .module-img-col2,
    .module-img-col3{
      .grid{grid-row-gap: 18px; }
    }
  }
}
@media (max-width: 360px) {
  .sec-work{
  }
}