@charset 'utf-8';
.work-item{
  display: block;
  margin-bottom: 160px;
  figure{
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    background: $bg-light;
    &[data-aos=fade-up]{
      transform: translate3d(0,100px,0);
    }
    .content{
      &[data-aos=fade-up]{
        transform: translate3d(0,60px,0);
      }
    }
    video,
    iframe,
    img{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;left: 0;
      object-fit:cover;
      // background: $bg-light;
    }
    iframe{
      pointer-events: none;
    }
    // span{
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;left: 0;
    //   // background: white;
    //   @include linear-gradient(0deg, transparentize(white, 0) 20%, transparentize(white, 1));
    //   background-color: transparent;
    //   pointer-events: none;
    //   transform: translate3d(0,0,0);
    //   transition-duration: 1s !important;
    //   transition-timing-function: linear !important;
    //   will-change: transform;
    //   backface-visibility: hidden;
    //   &.aos-animate{
    //     transform: translate3d(0,100%,0);
    //   }
    // }
  }
  h3{
    margin-bottom: 3px;
  }
  p{
    font-weight: 400;
    letter-spacing: 0;
    &.font-zh{
      font-family: $font-zh;
      letter-spacing: .05em;
    }
  }
  .tag-container{
    margin-top: 12px;
    > li{
      @include font-en-overline-1();
      color: $color-txt-grey-1;
      margin-right: 12px;
    }
  }
  &:nth-child(6n + 1){
    grid-column: auto/span 7;
  }
  &:nth-child(6n + 2){
    grid-column: auto/span 5;
  }
  &:nth-child(6n + 3){
    grid-column: auto/span 6;
    grid-column-start: 5;
  }
  &:nth-child(6n + 4){
    grid-column: auto/span 5;
  }
  &:nth-child(6n + 5){
    grid-column: auto/span 7;
  }
  &:nth-child(6n + 6){
    grid-column: auto/span 9;
  }
}
.news-item{
  @extend .work-item;
  margin-bottom: 120px;
  &:nth-child(n){
    grid-column: auto/span 4 !important;
  }
  .date{
    margin-bottom: 12px;
    b{
      @include font-en-caption-2();
      font-weight: bold;
    }
    time{
      display: inline-block;
      @include font-en-caption-2();
      font-weight: 300;
      margin-left: 10px;
    }
  }
  .content{
    hr{
      margin: 10px 0;
      border-top: 1px solid $color-stroke-grey-1;
    }
    h3{
      font-weight: 400;
      line-height: 1.9;
      letter-spacing: .04em;
      text-transform: none;
    }
    p{
      @include font-zh-caption-1();
      font-weight: 400;
      line-height: 1.7333;
      letter-spacing: .05333em;
      color: $color-txt-grey-1;
    }
  }
}
.hover-bg{
  border-bottom: 2px solid $color-stroke-grey-2;
  @include transition(border-color .25s);
  position: relative;
  z-index: 2;
  &:before{
    content:'';
    display: block;
    position: absolute;
    inset: 0;
    background: $bg-light;
    z-index: -1;
    transform-origin: center bottom;
    @include scale(1, 0);
    @include transition(transform .25s);
  }
  &:hover,
  &:focus-visible{
    border-color: $color-black !important;
    &:before{
      @include scale(1, 1);
    }
  }
}
.bg-light{
  .hover-bg{
    &:before{
      background: $color-stroke-grey-2;
    }
  }
}
.accordion-item{
  .title{
    border-bottom: 2px solid $color-stroke-grey-2;
    @include transition(border-color .25s);
    position: relative;
    cursor: pointer;
    i{
      display: block;
      width: 1.25em;
      height: 1.25em;
      position: absolute;
      top: 0;bottom: 0;
      right: 24px;
      margin: auto 0;
      @include transition(transform .25s .25s);
      &:before,
      &:after{
        content:'';
        display: block;
        width: 1em;
        height: 2px;
        background: $color-stroke-grey-2;;
        position: absolute;
        top: 0;right: 0;bottom: 0;left: 0;
        margin:auto;
        @include transition(transform .25s, background .25s);
      }
      &:after{@include rotate(90); }
    }
    &:hover,
    &:focus-visible{
      i{
        &:before,
        &:after{
          background: $color-black;
        }
      }
    }
  }
  .content{
    display: none;
    border-bottom: 2px solid $color-stroke-grey-2;
  }
  &.is-active{
    .title{
      border-bottom-color: $color-black;
      i{
        @include rotate(-45);
        @include transition(transform .25s ease);
        &:before,
        &:after{
          background: $color-black;
          @include transition(transform .25s .25s ease);
        }
        &:before{@include rotate(45); }
        &:after{@include rotate(45); }
      }
    }
    .content{
      display: block;
      opacity: 0;
      @include animation(showFilter, .6s, .1s, ease, 1, forwards);
    }
  }
}
@media (max-width: 1440px) {
  .work-item{
    figure{margin-bottom: 15px; }
    p{
      @include font-en-m-caption-1();
      // font-weight: 500;
    }
    .tag-container{
      margin-bottom: -8px;
      > li{
        margin-bottom: 8px;
      }
    }
  }
  .news-item{
    .date{
      font-size: 1em;
      line-height: 1;
    }
  }
}
@media (max-width: 1280px) {
  .news-item{
    margin-bottom: 80px;
    figure{margin-bottom: 20px;}
    .content{
      h3{
        @include font-zh-m-subtitle-2();
      }
      p{
        @include font-zh-m-caption-1();
      }
    }
  }
  .accordion-item{
    .title{
      i{right: 15px;}
    }
  }
}
@media (max-width: 1024px) {
  .news-item{
    &:nth-child(n){
      grid-column: auto/span 6 !important;
    }
  }
}
@media (max-width: 1023px) {
  .work-item{
    .tag-container{margin-top: 10px;}
    &:nth-child(3n + 1),{
      grid-column: auto/span 5;
    }
    &:nth-child(3n + 2){
      grid-column: auto/span 5;
      grid-column-start: 2;
    }
    &:nth-child(3n + 3){
      grid-column: auto/span 6;
    }
  }
}
@media (max-width: 768px) {
  .accordion-item{
    .title{
      border-bottom-width: 1px;
    }
  }
}
@media (max-width: 414px) {
  .work-item{
    margin-bottom: 100px;
    h3{
      font-size: 1.1em;
    }
  }
  .news-item{
    margin-bottom: 100px;
  }
  .accordion-item{
    .title{
      i{
        width: 1em;
        height: 1em;
        &:before,
        &:after{
          width: .75em;
        }
      }
    }
  }
}