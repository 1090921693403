/*  Text Truncate */
@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin line-clamp($height, $line) {
  height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

/* TRANSITION */
@mixin transition($transition...) {
  transition: $transition;
}
@mixin transition-property($property...) {
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  transition-delay: $delay;
}

/* TRANSFORM */
// generic transform
@mixin transform($transforms) {
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale ($x, $y) {
  @include transform(scale($x, $y));
}
// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
// translateZ
@mixin translateZ ($z) {
  @include transform(translateZ($z));
}
// skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
  transform-origin: $origin;
}

/* ANIMATION */
@mixin animation ($animation, $duration, $delay, $easing, $iteration, $fillmode) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $easing;
  animation-iteration-count: $iteration;
  animation-fill-mode: $fillmode;
}
@mixin animation-name($animation-name) {
  animation-name: $animation-name;
}
@mixin animation-delay($delay) {
  animation-delay: $delay;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

/* GRADIENT */
@mixin linear-gradient($direction, $color-stops...) {
  background-color: nth(nth($color-stops, 1), 1);
  background-image: -moz-linear-gradient($direction, $color-stops);
  background-image: -webkit-linear-gradient($direction, $color-stops);
  background-image: linear-gradient($direction, $color-stops);
}