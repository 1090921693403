@charset 'utf-8';
.list-disc{
	> li{
		line-height: 1.5;
		position: relative;
		padding-left: 1.5em;
		margin-bottom: .125em;
		&:before{
			content:'';
			display: block;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background:$color-black;
			position: absolute;
			top: .75em;
			left: .5em;
		}
	}
}
.list-decimal{
	list-style-type: decimal;
	list-style-position: inside;
	> li{
		line-height: 1.5;
		position: relative;
		padding-left: 1em;
		margin-bottom: .125em;
		text-indent: -1em;
	}
}
@media (max-width: 768px){
}
@media (max-width: 414px){
}