@charset 'utf-8';
#page-search{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  @supports (height: 100dvh) {
    min-height: calc(100dvh);
  }
  >.vertical-align-center{
    margin-bottom: auto;
  }
  main{
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }
}
.sec-search{
  padding-top: 190px;
  h2{
    @include font-en-h4();
    margin-bottom: 40px;
  }
  .search-container{
    max-width: 768px;
    padding-bottom: 1px;
    margin: 0 auto;
    position: relative;
  }
  .category-container,
  .result-container{
    @include animation(showFilter, .6s, 0s, ease, 1, both);
    @include transition(height .6s ease);
    position: relative;
    overflow: hidden;
    &.is-hidden{
      max-height: 0;
      height: 0;
      padding: 0;
      animation: none;
    }
  }
  #search{
    display: block;
    width: 100%;
    padding: 3px 50px 8px 15px;
    @include font-zh-subtitle-1();
    border-bottom: 1px solid $color-black;
    position: relative;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-stroke-grey-1;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {/* Internet Explorer 10-11 */
      color: red;
    }
    &::-ms-input-placeholder {/* Microsoft Edge */
      color: red;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    &.is-enabled{
      ~#search-reset{
        background-image: url(/assets/img/icon/close-black.svg);
      }
    }
    &:focus{
      border-bottom-width: 2px;
      margin-bottom: -1px;
    }
  }
  #search-reset{
    width: .875rem;
    height: .875rem;
    font-size: 0;
    color: transparent;
    position: absolute;
    top: 0; bottom: 15px;
    right: 18px;
    background: url(/assets/img/icon/close.svg) 0 0 no-repeat;
    background-size: contain;
    margin: auto 0;
    @include transition(background .25s);
    &:hover,
    &:focus-visible{
      background-image: url(/assets/img/icon/close-black.svg);
    }
  }
  #filter-container{
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    @include transition(all .6s ease);
    padding-inline: 0;
    margin-inline: -4px;
    li{
      margin-inline: 4px;
    }
  }
  .result-container{
    padding: 30px 0;
  }
}
#search-list{
  .no-result{
    margin: 90px 0 120px;
    p{
      @include font-en-btn-3();
    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .sec-search{
    padding-top: 83px;
    h2{
      font-size: 2.625em;
      line-height: 1.238;
      letter-spacing: -.0385em;
    }
    #search{
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: .0125em;
    }
  }
}
@media (max-width: 1024px) {
  .sec-search{
    padding-top: 115px;
    .category-container{
      padding: 25px 0 100px;
    }
    .result-container{
      padding: 25px 0;
    }
  }
}
@media (max-width: 1023px) {
  .sec-search{
    .g-col-6.g-start-4{
      grid-column: auto/span 4;
      grid-column-start: 2;
    }
  }
}
@media (max-width: 768px) {
  .sec-search{
    padding-top: 74px;
    .g-col-6.g-start-4{
      grid-column: auto/span 6;
      grid-column-start: 1;
    }
  }
  #search-list{
    padding-top: 30px;
  }
}
@media (max-width: 414px) {
  .sec-search{
    padding-top: 80px;
    h2{
      margin-bottom: 60px;
      @include font-en-h5();
      font-weight: bold;
    }
  }
  #search-list{
    .no-result{
      margin: 70px 0 100px;
    }
  }
}
@media (max-width: 360px) {
  .sec-search{
    padding-top: 40px;
    h2{
      font-size: 2em;
      margin-bottom: 40px;
    }
    #filter-container{
      margin-inline: 0;
    }
  }
}