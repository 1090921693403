@charset 'utf-8';
.sec-hiring-intro{
  padding: 50px 0 100px;
  figure{
    width: 420px;
    margin-right: 68px;
    flex-shrink: 0;
    img{width: 100%;}
  }
  .content{
    max-width: 51.875em;
    h2{
      font-size: 1rem;
      text-transform: none;
      margin-bottom: 40px;
      span{
        display: block;
        font-weight: 500 !important;
        &.font-zh{
          font-family: $font-zh;
          font-size: 3.25em;
          line-height: 1.3846;
          letter-spacing: .0289em;
          margin-top: 5px;
        }
      }
    }
    p{
      margin-bottom: 12px;
      .type,
      .period{
        display: inline-block;
      }
      .type{
        margin-right: 15px;
      }
    }
    .description{
      font-weight: 300;
    }
  }
}
.sec-hiring{
  padding-bottom: 100px;
  hr.hr-stroke-grey-2{
    margin-bottom: 140px;
  }
  h3{
    margin-bottom: 20px;
  }
  article{
    padding: 30px 0 60px;
  }
  ul{
    @extend .list-disc;
    > li{
      @include font-zh-body-2();
    }
  }
  ol{
    @extend .list-decimal;
    > li{
      @include font-zh-body-2();
    }
  }
  .content.g-col-8 > div,
  p{
    @include font-zh-body-2();
  }
  b,
  strong{font-weight: 500;}
  .btn{
    margin-top: 30px;
  }
  a{
    text-decoration: underline;
    text-underline-offset: .125em ;
    &:hover,
    &:focus-visible{
      color: $color-txt-grey-1;
    }
    &.link-mail{
      @include font-en-m-subtitle-3();
      text-decoration: none;
      text-underline-offset: 0;
      .link-underline{
        padding-bottom: 0;
      }
    }
  }
}
/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-hiring-intro{
    figure{
      width: 460px;
    }
  }
}
@media (max-width: 1440px) {
  .sec-hiring-intro{
    .content{
      max-width: none;
    }
  }
}
@media (max-width: 1280px) {
  .sec-hiring-intro{
    padding-top: 0;
    .content{
      h2{
        span{
          &.font-en-h4{font-size: 2.625em;}
          &.font-zh{font-size: 2.3125em;}
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .sec-hiring-intro{
    padding: 17px 0 80px;
    .container{
      flex-direction: column;
    }
    figure{
      width: 100%;
      margin-bottom: 40px;
    }
    .content{
      // max-width: none;
      h2{
        span{
          &.font-en-h4{
            @include font-en-m-h5();
          }
          &.font-zh{
            font-size: 1.875em;
            line-height: 1.0666;
            letter-spacing: 0;
            margin-top: 12px;
          }
        }
      }
      .description{
        @include font-zh-m-subtitle-1();
      }
    }
  }
  .sec-hiring{
    padding-bottom: 40px;
    hr.hr-stroke-grey-2{
      margin-bottom: 80px;
    }
    h3{
      @include font-en-m-subtitle-1();
      margin-bottom: 15px;
    }
    .title{
      grid-column: auto/span 12;
      h4{
        margin-bottom: 30px;
      }
    }
    .content{
      grid-column: auto/span 12;
    }
  }
}
@media (max-width: 1023px) {
  .sec-hiring-intro{
  }
  .sec-hiring{
    a{
      &.link-mail{
        @include font-en-m-body-1();
      }
    }
  }
}
@media (max-width: 768px) {

}
@media (max-width: 414px) {
  .sec-hiring-intro{
    padding-bottom: 80px;
    .content{
      .description{
        @include font-zh-m-subtitle-2();
      }
    }
  }
  .sec-hiring{
    .title{
      h4{
        @include font-zh-m-subtitle-3();
        font-weight: 500;
      }
    }
    ul > li,
    ol > li,
    .content.g-col-8 > div,
    p{
      font-size: .9375em;
      line-height: 2;
      letter-spacing: .0266em;
    }
  }
}
@media (max-width: 360px) {
  .sec-hiring-intro{
    figure{
      margin-bottom: 30px;
    }
  }
}