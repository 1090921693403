@charset 'utf-8';
#page-404{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  @supports (height: 100dvh) {
    min-height: calc(100dvh);
  }
  >.vertical-align-center{
    margin: auto 0;
  }
  main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }
}
.sec-404{
  h2{
    margin-bottom: 40px;
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .sec-404{
  }
}
@media (max-width: 1024px) {
  .sec-404{
  }
}
@media (max-width: 1023px) {
  .sec-404{
  }
}
@media (max-width: 768px) {

}
@media (max-width: 414px) {
  .sec-404{
    h2{
      @include font-en-m-h5();
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 360px) {
  .sec-404{
  }
}