@charset 'utf-8';
/* ---------BLOCK------------ */
.container {
  width: 100%;
  padding-inline: 60px;
}
.grid{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 24px;
}

.col-10 {width: 10%; }
.col-20 {width: 20%; }
.col-30 {width: 30%; }
.col-40 {width: 40%; }
.col-50 {width: 50%; }
.col-60 {width: 60%; }
.col-70 {width: 70%; }
.col-80 {width: 80%; }
.col-90 {width: 90%; }
.col-100{width: 100%; }


.g-col-1 {grid-column: auto/span 1 }
.g-col-2 {grid-column: auto/span 2 }
.g-col-3 {grid-column: auto/span 3 }
.g-col-4 {grid-column: auto/span 4 }
.g-col-5 {grid-column: auto/span 5 }
.g-col-6 {grid-column: auto/span 6 }
.g-col-7 {grid-column: auto/span 7 }
.g-col-8 {grid-column: auto/span 8 }
.g-col-9 {grid-column: auto/span 9 }
.g-col-10 {grid-column: auto/span 10 }
.g-col-11 {grid-column: auto/span 11 }
.g-col-12 {grid-column: auto/span 12 }
.g-start-1 {grid-column-start: 1 }
.g-start-2 {grid-column-start: 2 }
.g-start-3 {grid-column-start: 3 }
.g-start-4 {grid-column-start: 4 }
.g-start-5 {grid-column-start: 5 }
.g-start-6 {grid-column-start: 6 }
.g-start-7 {grid-column-start: 7 }
.g-start-8 {grid-column-start: 8 }
.g-start-9 {grid-column-start: 9 }
.g-start-10 {grid-column-start: 10 }
.g-start-11 {grid-column-start: 11 }

.z-2{
  position: relative;
  z-index: 2;
}

.ib-top {
  display: inline-block;
  vertical-align: top;
}
.ib-bottom {
  display: inline-block;
  vertical-align: bottom;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

.m-auto  {margin: auto; }
.mx-auto {margin-inline: auto; }
.ml-auto {margin-left: auto; }
.mr-auto {margin-right: auto; }


hr {
  border: 0;
  height: 0;
  border-top: 2px solid $color-black;
  margin: 0;
  transform-origin: 0 0;
  &[data-aos]{
    transform: scaleX(0);
    transition: transform .6s ease;
    &.aos-animate{
      transform: scaleX(1);
    }
  }
  &.hr-stroke-grey-2{
    border-color: $color-stroke-grey-2;
  }
}

.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .container {
    padding-inline: 40px;
  }
}
@media (max-width: 1024px) {
  .d-1024-block {
    display: block !important;
  }
  .d-1024-flex {
    display: flex !important;
  }
  .d-1024-none {
    display: none !important;
  }
}
@media (max-width: 1023px) {
  .container {
    padding-inline: 25px;
  }
  .grid{
    grid-template-columns: repeat(6, 1fr);
  }

  .g-col-md-1 {grid-column: auto/span 1 }
  .g-col-md-2 {grid-column: auto/span 2 }
  .g-col-md-3 {grid-column: auto/span 3 }
  .g-col-md-4 {grid-column: auto/span 4 }
  .g-col-md-5 {grid-column: auto/span 5 }
  .g-col-md-6 {grid-column: auto/span 6 }
  .g-start-md-1 {grid-column-start: 1 }
  .g-start-md-2 {grid-column-start: 2 }
  .g-start-md-3 {grid-column-start: 3 }
  .g-start-md-4 {grid-column-start: 4 }
  .g-start-md-5 {grid-column-start: 5 }
  .g-start-md-6 {grid-column-start: 6 }

  .d-1023-block {
    display: block !important;
  }
  .d-1023-flex {
    display: flex !important;
  }
  .d-1023-none {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .d-768-block {
    display: block !important;
  }
  .d-768-none {
    display: none !important;
  }
}
@media (max-width: 414px) {
  .container {
    padding-inline: 17px;
  }
  .grid {
    grid-column-gap: 10px;
  }
  .g-col-sm-1 {grid-column: auto/span 1 }
  .g-col-sm-2 {grid-column: auto/span 2 }
  .g-col-sm-3 {grid-column: auto/span 3 }
  .g-col-sm-4 {grid-column: auto/span 4 }
  .g-col-sm-5 {grid-column: auto/span 5 }
  .g-col-sm-6 {grid-column: auto/span 6 }
  .g-start-sm-1 {grid-column-start: 1 }
  .g-start-sm-2 {grid-column-start: 2 }
  .g-start-sm-3 {grid-column-start: 3 }
  .g-start-sm-4 {grid-column-start: 4 }
  .g-start-sm-5 {grid-column-start: 5 }
  .g-start-sm-6 {grid-column-start: 6 }

  .d-480-block {
    display: block !important;
  }
  .d-480-none {
    display: none !important;
  }
}