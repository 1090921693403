@charset 'utf-8';
/* -------------FOOTER------------ */
	#footer{
		padding: 31px 0 27px;
		position: relative;
		overflow: hidden;
		p{
			@include font-en-btn-4();
			font-weight: 500;
			text-transform: capitalize;
			span.font-zh{
				font-size: .875rem;
				font-weight: 400;
  			letter-spacing: .0571em;
				line-height: 1;
				margin-left: 10px;
			}
		}
		nav{
			margin-left: auto;
			> a{
				@include font-en-btn-4();
				font-weight: 500;
				margin-left: 20px;
				padding-block: 15px;
				margin-block: -15px;
		    transition: opacity .2s;
		    &:hover,
		    &:focus-visible{
		      opacity: 0.5;
		    }
			}
		}
	}
	#trigger-privacy{
		display: block;
		margin-left: 20px;
		border-left: 1px solid $color-stroke-grey-1;
		padding-left: 20px;
		padding-top: 2px;
		transition-property: opacity, transform, color;
		&:hover,
		&:focus-visible{
			color: $color-black;
		}
	}
/* -------------RWD--------------- */
	@media (max-width: 12800x){
		#footer{
		}
	}
	@media (max-width: 1024px){
		#footer{
			padding: 25px 0 22px;
			nav{
				> a{margin-left: 15px; }
			}
		}
		#trigger-privacy{
			margin-left: 15px;
			padding-left: 15px;
		}
	}
	@media (max-width: 1023px){
		#footer{
			.container{
				flex-direction: column-reverse;
			}
			p{
				@include font-en-overline-1();
			}
			nav{
				margin-left: 0;
				margin-bottom: 27px;
				> a{
					@include font-en-btn-4();
					margin-left: 0;
					margin-right: 10px;
				}
			}
		}
		#trigger-privacy{
			@include font-en-overline-1();
			padding-left: 0;
			border-left: none;
			margin-left: auto;
		}
	}
	@media (max-width: 768px){
		#footer{
			padding: 30px 0;
		}
	}
	@media (max-width: 414px){
	}
	@media (max-width: 360px){
		#footer{
			nav{
				> a{font-size: .875em; }
			}
			.content{
				flex-direction: column;
			}
		}
		#trigger-privacy{
			margin-left: 0;
			margin-top: 12px;
		}
	}