 @charset 'utf-8';
/* -------------LOADER------------ */
  #loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    z-index: 10;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 1.4s ease;
      will-change: transform;
      backface-visibility: hidden;
    }
    // img {
    //   position: fixed;
    //   width: 150px;
    //   height: 150px;
    //   top: 50%;
    //   left: 50%;
    //   @include transform(translate(-50%, -50%));
    // }
    .sprite-container {
      position: fixed;
      width: 150px;
      height: 150px;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      transition: opacity .4s;
    }
    .sprite {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;left: 0;
      background-image: url(/assets/img/loader-sprite.png);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 1600%;
      animation: playSpriteH .3s steps(15) infinite forwards,
                 playSpriteV 2.1s steps(7) infinite forwards;
      &.is-paused{animation: none; }
    }
  }
  html.is-loaded{
    #loader{
      .overlay{
        @include translate(0, -100%);
      }
      .sprite-container{
        opacity: 0;
      }
    }
    #page-index{
      .btn-container{
        @include animation(fadeInUp, .8s, 1s, ease, 1, both);
        // visibility: visible !important;
      }
    }
  }
/* -------------INDEX------------ */
  #page-index {
    // background: $bg-black;
    padding-top: 0 !important;
    #header{
      background: transparent;
      &.theme-white{background: white;}
    }
    .btn-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: 35px;
      cursor: default;
      z-index: 2;
      pointer-events: none;
      user-select: none;
      img {
        width: .8em;
        margin-top: .8em;
      }
    }
    .scroller{
      height: 400vh !important;
      max-height: none !important;
    }
    .sec-hero {
      position: relative;
      overflow: hidden;
      .container{
        display: flex;
        align-items: center;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        padding: 0;
        @supports (height: 100dvh) {
          min-height: calc(100dvh);
        }
      }
      .wrapper{
        width: 100%;
      }
      .canvas-container,
      .vid-container{
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;
      }
      canvas,
      video{
        width: 100%;
        height: 100%;
        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        @supports (height: 100dvh) {
          max-height: calc(100dvh);
        }
        position: absolute;
        top: 0;left: 0;
        object-fit: contain;
      }
      canvas{
        filter: contrast(1.3) brightness(.99);
        backface-visibility: hidden;
      }
      // canvas{
      //   width: 124%;
      //   height: 110%;
      //   margin-left: -12%;
      //   object-fit: cover;
      // }
    }
    .sec-intro{
      width: 100%;
      position: absolute;
      top: 0;left: 0;
      z-index: 2;
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
      }
      p{
        font-weight: 500;
        br{
          content:'';
          display: block;
          margin: 0;
          margin-top: .47em;
        }
      }
    }
    .sec-reels{
      width: 100%;
      position: absolute;
      top: 0;left: 0;
      z-index: 2;
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
      }
      .vid-container{
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: absolute;
        top: 0;left: 0;right: 0;
        margin: 0 auto;
      }
      iframe{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0; right: 0;margin: 0 auto;
        object-fit: cover;
      }
      .cover{
        background: transparentize(black, .6);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        @include transition(background-color .4s ease);
      }
      .btn-pos{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;right: 0;bottom: 0;left: 0;
        margin: auto;
        pointer-events: none;
      }
      .btn{
        @include font-en-caption-1();
        color: white;
        padding: .625em 1.375em .5em;
        border-width: 1px;
        opacity: 0.6;
        pointer-events: all;
        @include transition(opacity .25s ease, padding .8s ease);
        &:before,
        &:after{display: none;}
        i{
          display: inline-block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: .375em 0 .375em .5625em;
          border-color: transparent transparent transparent #FFFFFF;
          margin-right: 0.3em;
        }
        &:hover,
        &:focus-visible{
          opacity: 1;
          background: none;
          color: white;
          border-color: white;
          padding: .75em 1.875em .625em;
        }
      }
      &.is-playing{
        .cover{
          background: transparentize(black, 1);
          pointer-events: none;
        }
        .btn-pos{
          @include animation(fadeOut, .25s, 0s, ease, 1, both);
        }
        .btn{
          pointer-events: none;
        }
      }
    }
    .sec-featured{
      padding-top: 120px;
    }
    .sec-clients{
      padding: 120px 0 80px;
      position: relative;
      overflow: hidden;
      h2{
        text-transform: none;
        margin-bottom: 80px;
      }
      .swiper-wrapper{transition-timing-function : linear; }
      .swiper-slide{
        img{margin: 0 auto;}
      }
      a{display: block;}
    }
  }
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  #page-index{
    .sec-clients{
      padding: 100px 0 60px;
      h2{margin-bottom: 60px;}
    }
  }
}
@media (max-width: 1280px) {
  #page-index{
    .btn-container {
      margin-bottom: 25px;
    }
    .sec-hero{
      .canvas-container{
        width: 1024px;
        height: 576px;
        max-height: 100vh;
        padding-bottom: 0;
        margin: 0 auto;
      }
      canvas{
        width: 124%;
        margin-left: -12%;
        object-fit: cover;
      }
    }
    .sec-intro{
      p{
        font-size: 2.89vw;
        line-height: 1.1892;
        letter-spacing: -.027em;
        // margin: .108em 0;
        br{
          margin-top: .216em;
        }
      }
    }
    .sec-clients{
      h2{
        font-size: 1.75em;
        line-height: 1.3571em;
      }
    }
  }
}
@media (max-width: 1024px) {
  #page-index{
    .sec-hero{
      // canvas{
      //   width: 124%;
      //   height: 110%;
      //   margin-left: -12%;
      //   object-fit: cover;
      // }
    }
    .sec-intro{
      p{
        font-size: 2.9297vw;
      }
    }
    .sec-reels{
      .vid-container{
        width: 177.7777vh;
        height: 100%;
        margin-left: calc((177.7777vh - 100vw) / -2);
      }
      &.is-playing{
        .vid-container{
          width: 100%;
          margin-left: 0;
        }
      }
    }
    .sec-clients{
      h2{
        margin-bottom: 40px;
      }
    }
  }
}
@media (max-width: 1023px) {
  #page-index{
    .btn-container {
      img {
        margin-top: .6em;
      }
    }
    .sec-hero{
      .wrapper{
        width: 100%;
        margin-inline: 0;
      }
    }
    .sec-clients{
      .swiper{padding-inline: 25px; }
    }
  }
}
@media (max-width: 768px) {
  #page-index {
    .scroller{
      height: 200vh !important;
    }
    .sec-hero{
      .wrapper{
        width: 140%;
        margin-inline: -20%;
      }
    }
    .sec-intro{
      .container{
        text-align: left;
        align-items: flex-start;
      }
      p{
        max-width: 16em;
        font-size: 5.4686vw;
        line-height: 1.1952;
        margin: .2976em 0;
        br{
          margin-top: .5952em;
        }
      }
    }
    .sec-clients{
      padding-top: 80px;
      h2{
        font-size: 1.5625em;
        line-height: 1.28em;
      }
      .swiper{padding: 0; }
    }
  }
}
@media (max-width: 414px) {
  #loader{
    .sprite-container{
      width: 120px;
      height: 120px;
    }
  }
  #page-index {
    .sec-hero{
      .wrapper{
        width: 200%;
        margin-inline: -50%;
      }
      .vid-container{
        padding-bottom: 64.25%;
      }
    }
    .sec-intro{
      // .container{
      //   text-align: left;
      //   align-items: flex-start;
      // }
      p{
        font-size: 7.4vw;
        letter-spacing: -.03125em;
        margin: .3906em 0;
        br{
          margin-top: .7812em;
        }
      }
    }
    .sec-featured{
      padding-top: 100px;
    }
    .sec-clients{
      padding: 60px 0 50px;
      h2{
        font-size: 1.375em;
        line-height: 1.3182em;
      }
      .swiper{
        width: 110%;
        margin-inline: -5%;
      }
    }
  }
}

@media (max-width: 360px) {
  #page-index{
    .sec-intro{
      p{
        letter-spacing: -.004em;
      }
    }
  }
}