@charset 'utf-8';
	[data-aos=fade-up]{
		transform: translate3d(0,20px,0);
	}
	[data-aos=fade-down]{
		transform: translate3d(0,-20px,0);
	}
/* ---------ANIMATION-------- */
	@include keyframes(showContent) {
		0%{
			opacity: 0;
			@include transform(scale(.95, .95));
		}
		100%{
			opacity: 1;
			@include transform(scale(1, 1));
		}
	}
	@include keyframes(fadeIn) {
		0%{
			opacity: 0;
		}
		100%{
			opacity: 1;
		}
	}
	@include keyframes(showFilter) {
		0%{
			opacity: 0;
			@include transform(translate(0, 20px));
		}
		100%{
			opacity: 1;
			@include transform(translate(0, 0));
		}
	}
	@include keyframes(fadeInUp) {
		0%{
			opacity: 0;
			@include transform(translate(0, 30px));
		}
		100%{
			opacity: 1;
			@include transform(translate(0, 0));
		}
	}
	@include keyframes(fadeInDown) {
		0%{
			opacity: 0;
			@include transform(translate(0, -30px));
		}
		100%{
			opacity: 1;
			@include transform(translate(0, 0));
		}
	}
	@include keyframes(fadeOut) {
		0%{
			opacity: 1;
		}
		100%{
			opacity: 0;
		}
	}
	@include keyframes(fadeOutDown) {
		0%{
			opacity: 1;
			@include transform(translate(0, 0));
		}
		100%{
			opacity: 0;
			@include transform(translate(0, 30px));
		}
	}

	@include keyframes(playSpriteH) {
		0% {background-position-x: 0% }
		100% {background-position-x: 100% }
	}
	@include keyframes(playSpriteV) {
		0% {background-position-y: 0% }
		100% {background-position-y: 100% }
	}