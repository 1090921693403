@charset 'utf-8';
@font-face {
    font-family: 'Messina Sans';
    src: url('/assets/font/MessinaSansWeb-Book.woff2') format('woff2'),
        url('/assets/font/MessinaSansWeb-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Messina Sans';
    src: url('/assets/font/MessinaSansWeb-Regular.woff2') format('woff2'),
        url('/assets/font/MessinaSansWeb-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Messina Sans';
    src: url('/assets/font/MessinaSansWeb-SemiBold.woff2') format('woff2'),
        url('/assets/font/MessinaSansWeb-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* ---------TEXT------------- */
html {
  font-size: 16px;
}
body {
  font-family: $font-content;
  background: $bg-white;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
}
h1, h2, h3, h4, h5, h6,
a, p, b, small {
  font: inherit;
  color: inherit;
}
h1{@include font-en-h1();}
h2{@include font-en-h2();}
h3{@include font-en-h3();}
h4{@include font-en-h4();}
h5{@include font-en-h5();}
h6{@include font-en-h6();}
p {
  font-size: 1em;
}
b,
strong {
  font: inherit;
  letter-spacing: inherit;
  font-weight: 600;
}
small {
  font-size: .875em;
  line-height: inherit;
}
a {
  display: inline-block;
  font:inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: none;
  color: inherit;
}
code {
  font-family: "Consolas", monospace;
}
option {
  font-family: $font-content;
}

.color-white {
  color: $color-white;
}
.color-dark {
  color: $color-dark;
}
.color-black {
  color: $color-black;
}
.color-txt-grey-1{
  color: $color-txt-grey-1;
}
.color-txt-grey-2{
  color: $color-txt-grey-2;
}
.color-stroke-grey-1{
  color: $color-stroke-grey-1;
}
.color-stroke-grey-2{
  color: $color-stroke-grey-2;
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.align-justify {
  text-align: justify;
}

.font-italic {
  font-style: italic;
}
.font-uppercase {
  text-transform: uppercase;
}
.font-en-h1{@include font-en-h1();}
.font-en-h2{@include font-en-h2();}
.font-en-h3{@include font-en-h3();}
.font-en-h4{@include font-en-h4();}
.font-en-h5{@include font-en-h5();}
.font-en-h6{@include font-en-h6();}
.font-en-subtitle-1{@include font-en-subtitle-1();}
.font-en-subtitle-2{@include font-en-subtitle-2();}
.font-en-subtitle-3{@include font-en-subtitle-3();}
.font-en-subtitle-4{@include font-en-subtitle-4();}
.font-en-subtitle-5{@include font-en-subtitle-5();}
.font-en-body-1{@include font-en-body-1();}
.font-en-body-2{@include font-en-body-2();}
.font-en-body-3{@include font-en-body-3();}
.font-en-btn-1{@include font-en-btn-1();}
.font-en-btn-2{@include font-en-btn-2();}
.font-en-btn-3{@include font-en-btn-3();}
.font-en-btn-4{@include font-en-btn-4();}
.font-en-btn-m-1{@include font-en-btn-m-1();}
.font-en-btn-m-2{@include font-en-btn-m-2();}
.font-en-btn-m-3{@include font-en-btn-m-3();}
.font-en-smal{@include font-en-smal();}
.font-en-caption-1{@include font-en-caption-1();}
.font-en-caption-2{@include font-en-caption-2();}
.font-en-overline-1{@include font-en-overline-1();}
.font-en-overline-2{@include font-en-overline-2();}
.font-en-m-h1{@include font-en-m-h1();}
.font-en-m-h2{@include font-en-m-h2();}
.font-en-m-h3{@include font-en-m-h3();}
.font-en-m-h4{@include font-en-m-h4();}
.font-en-m-h5{@include font-en-m-h5();}
.font-en-m-h6{@include font-en-m-h6();}
.font-en-m-subtitle-1{@include font-en-m-subtitle-1();}
.font-en-m-subtitle-2{@include font-en-m-subtitle-2();}
.font-en-m-subtitle-3{@include font-en-m-subtitle-3();}
.font-en-m-subtitle-4{@include font-en-m-subtitle-4();}
.font-en-m-caption-1{@include font-en-m-caption-1();}
.font-en-m-overline-1{@include font-en-m-overline-1();}
.font-en-m-overline-2{@include font-en-m-overline-2();}
.font-zh-h1{@include font-zh-h1();}
.font-zh-h2{@include font-zh-h2();}
.font-zh-h3{@include font-zh-h3();}
.font-zh-subtitle-1{@include font-zh-subtitle-1();}
.font-zh-subtitle-2{@include font-zh-subtitle-2();}
.font-zh-body-1{@include font-zh-body-1();}
.font-zh-body-2{@include font-zh-body-2();}
.font-zh-body-3{@include font-zh-body-3();}
.font-zh-caption-1{@include font-zh-caption-1();}
.font-zh-caption-2{@include font-zh-caption-2();}
.font-zh-overline-1{@include font-zh-overline-1();}
.font-zh-btn-1{@include font-zh-btn-1();}

.underline{
  text-decoration: underline;
  text-underline-offset: .125em;
  text-decoration-thickness: 2px;
  // border-bottom: .05em solid $color-black;
}

/* -------------RWD--------------- */
@media (min-width: 2560px) {
  html{
    font-size: 20px;
  }
}
@media (min-width: 3200px) {
  html{
    font-size: 28px;
  }
}
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 768px) {
}
@media (max-width: 414px) {
}