@charset 'utf-8';
.sec-more{
  background: $bg-white;
  position: relative;
  overflow: hidden;
  padding: 40px 0 100px;
  h2{margin-bottom: 60px;}
  .work-item{
    margin-bottom: 40px;
  }
  .news-item{
    margin-bottom: 0;
  }
}
#swiper-more{
  padding: 0 24px;
  margin: 0 -24px;
  background: $bg-white;
  position: relative;
  overflow: hidden;
  &:before,
  &:after{
    content:'';
    display: block;
    width: 24px;
    height: 100%;
    background: inherit;
    position: absolute;
    top: 0;
    pointer-events: none;
    z-index: 2;
  }
  &:before{
    left: 0;
  }
  &:after{
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next{
    width: 0;
    height: 0;
    pointer-events: none;
    position: absolute;
    top: 38%;
    margin: auto -20px;
    &:after{
      opacity: 0;
      pointer-events: all;
      @include transition(opacity .25s, transform .4s);
    }
    &.swiper-button-disabled{
      &:after{
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .swiper-button-prev{
    &:after{
      @include translate(100% ,0);
    }
  }
  .swiper-button-next{
    &:after{
      @include translate(-100% ,0);
    }
  }
  &:hover,
  &:focus-within{
    .swiper-button-prev,
    .swiper-button-next{
      &:after{
        opacity: 1;
        @include translate(0,0);
      }
    }
  }
}
/* -------------RWD--------------- */
@media (min-width: 2042px) {
}
@media (max-width: 1440px) {
  .sec-more{
    h2{
      // font-size: 6.5625em;
      // line-height: 1;
      // letter-spacing: -.0381em;
    }
  }
}
@media (max-width: 1280px) {
  .sec-more{
    h2{
      font-size: 8.2031vw;
    }
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 768px) {
  .sec-more{
    padding: 20px 0 60px;
    .title-container{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    h2{
      @include font-en-m-h6();
      margin-bottom: 0;
    }
    .btn-container{
      margin-right: 0;
    }
    .swiper-button-m-prev,
    .swiper-button-m-next{
      padding: 0 15px;
      margin-right: -15px;
      &.swiper-button-disabled{
        opacity: 0.2;
      }
      img{
        width: 1.25em;
        height: 1.25em;
      }
    }
    .work-item{margin-bottom: 0;}
  }
  #swiper-more{
    padding: 0;
    margin: 0;
    &:before,
    &:after{display: none;}
  }
}
@media (max-width: 414px) {
  .sec-more{
    padding-bottom: 80px;
    .swiper-button-next,
    .swiper-button-prev{
      width: calc(50% - 5px);
    }
  }
}
@media (max-width: 360px) {
}