@charset 'utf-8';
.btn{
  display: inline-block;
  padding: .45em 1.0625em .5em;
  border-radius: 200px;
  border: 2px solid $color-stroke-grey-2;
  margin: 15px;
  color: black;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
  transition: border-color .3s;
  @include transform(translate3d(0,0,0));
  backface-visibility: hidden;
  &:before,
  &:after{
    content:'';
    position: absolute;
    background: $bg-white;
    z-index: -1;
    backface-visibility: hidden;
  }
  &:before{
    width: 120%;
    height: 100%;
    top: 0;left: 0;right: 0;
    margin: 0 -10%;
    border-radius: 200px;
    @include transform(scale(0,1) translateZ(0));
  }
  &:after{
    width: 100%;
    height: 100%;
    top: 0;left: 0;
    opacity: 0;
    transition: opacity .6s;
  }
  svg{
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    margin-left: 0.2rem;
    margin-right: -0.2rem;
    margin-top: -0.06em;
    transition: transform .3s;
  }
  @media (hover: hover) {
    &:hover{
      border-color: $color-black;
      transition: border-color .6s .15s;
      &:before{
        @include transform(scale(1,1) translateZ(0));
        transition: all .6s cubic-bezier(0,.89,.41,1);
      }
      &:after{
        opacity: 1;
        transition: opacity .6s .15s;
      }
    }
  }
  &:focus-visible {
    border-color: $color-black;
    transition: border-color .6s .15s;
    &:before{
      @include transform(scale(1,1) translateZ(0));
      transition: all .6s cubic-bezier(0,.89,.41,1);
    }
    &:after{
      opacity: 1;
      transition: opacity .6s .15s;
    }
  }
  &:active {
    svg{
      @include translate(.125em, 0);
    }
  }
  &.btn-white{
    &:before,
    &:after{
      background: $bg-black;
    }
    @media (hover: hover) {
      &:hover{
        color: $color-white;
        svg{
          path{
            fill: $color-white;
          }
        }
      }
    }
    &:focus-visible{
      color: $color-white;
      svg{
        path{
          fill: $color-white;
        }
      }
    }
  }
  &.btn-black{
    border-color: $color-black;
    @media (hover: hover) {
      &:hover{
        color: $color-white;
        svg{
          path{
            fill: $color-white;
          }
        }
      }
    }
    &:focus-visible{
      color: $color-white;
      svg{
        path{
          fill: $color-white;
        }
      }
    }
  }
  &.btn-s{
    border-width: 1px;
    margin: 0;
  }
  &.btn-m{
    padding: 14px 31px;
    margin: 0;
    text-decoration: none;
    @include font-en-m-subtitle-2();
    &.btn-zh{
      padding: 14px 33px;
      @include font-zh-subtitle-1();
    }
    svg{
      width: 1rem;
      margin-top: -0.35rem;
    }
  }
}
.btn-close{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid transparentize($color-white, .7);
  position: relative;
  @include transition(border-color .25s ease);
  img{
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    margin: auto;
  }
  &:hover,
  &:focus-visible{
    border-color: $color-white;
  }
}
.link-underline{
  padding-bottom: 2px;
  border-bottom: 1px solid;
  @include transition(color .25s, border-color .25s);
  &:hover,
  &:focus-visible{
    color: $color-txt-grey-1;
  }
}
.link-arrow{
  @include transition(color .25s);
  i{
    display: inline-block;
    vertical-align: top;
    width: 1em;
    height: 1em;
    margin: .4em 0 -.4em 5px;
    background-image: url(/assets/img/icon/arrow-right-black.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    // @include transition(background .25s);
    &.white{
      background-image: url(/assets/img/icon/arrow-right-white.svg);
    }
  }
  &:hover,
  &:focus-visible{
    color: $color-txt-grey-1;
    i{
      background-image: url(/assets/img/icon/arrow-right-grey.svg) !important;
    }
  }
}
@media (max-width: 1024px) {
  .btn{
    &.btn-m{
      padding: 6px 20px;
      border-width: 1px;
      @include font-en-m-body-1();
      &.btn-zh{
        padding: 14px 20px;
        @include font-zh-subtitle-2();
        font-weight: 400;
      }
      svg{
        width: 0.625rem;
        margin-left: 0.2rem;
        margin-right: 0;
      }
    }
  }
  .btn-close{
    width: 26px;
    height: 26px;
    img{
      width: 9.45px;
    }
  }
}