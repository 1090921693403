@charset "UTF-8";
/*  Text Truncate */
/* TRANSITION */
/* TRANSFORM */
/* ANIMATION */
/* GRADIENT */
/* -----RESET---------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, input, textarea, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, main, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer,
header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  border: none;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

input::-moz-focus-inner,
button::-moz-focus-inner,
select::-moz-focus-inner {
  border: 0;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

input[type=search],
input[type=search]:hover,
input[type=search]:focus,
input[type=search]:active {
  background: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
}

input[type=search]:-internal-autofill-selected,
input[type=search]:-webkit-autofill,
input[type=search]:-webkit-autofill:hover,
input[type=search]:-webkit-autofill:focus,
input[type=search]:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

select::-ms-expand {
  display: none;
}

button,
input[type=submit],
input[type=reset] {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

option {
  background-color: transparent;
}

button {
  padding: 0;
  margin: 0;
  color: inherit;
}

/* -------------SMOOTH SCROLL----- */
html {
  height: -webkit-fill-available;
  overflow: hidden;
}
html.is-loaded {
  overflow: unset;
}
html.is-closed header,
html.is-closed main,
html.is-closed footer,
html.is-closed .modal {
  opacity: 0;
}
html.is-noscroll {
  overflow: hidden;
}
@supports (-webkit-touch-callout: none) {
  html {
    /* CSS specific to iOS devices */
  }
  html main {
    clip-path: inset(0);
  }
  html:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
  }
}

/* -------------SELECTION--------- */
::selection {
  background: #000000;
  color: #ffffff;
}

::-moz-selection {
  background: #000000;
  color: #ffffff;
}

/* -------------MAIN-------------- */
body {
  padding-top: var(--top_padding);
}

/* -------------HEADER------------ */
#header-position {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;
}
#header-position > * {
  pointer-events: all;
}

#header {
  padding: 35px 0;
  background: #000000;
  color: #ffffff;
  transition: background 0.1s, color 0.1s, padding 0.4s ease-out, transform 0.4s linear;
}
#header h1 {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.1666;
  letter-spacing: 0;
  text-transform: uppercase;
}
#header h1 span {
  display: block;
  opacity: 0;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
#header h1 svg {
  opacity: 0;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
#header .menu-container {
  margin-left: auto;
}
#header nav > a,
#header #trigger-search span {
  transition: opacity 0.2s;
}
#header nav > a:hover, #header nav > a:focus-visible,
#header #trigger-search span:hover,
#header #trigger-search span:focus-visible {
  opacity: 0.5;
}
#header nav > a {
  display: block;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  margin-left: 35px;
}
#header.theme-white, body:not(#page-index) #header {
  padding: 16px 0;
  background: #ffffff;
  color: #000000;
}
#header.theme-white .container, body:not(#page-index) #header .container {
  align-items: center;
}
#header.theme-white #header-logo h1, body:not(#page-index) #header #header-logo h1 {
  display: none !important;
  opacity: 0;
}
#header.theme-white #header-logo svg, body:not(#page-index) #header #header-logo svg {
  display: block !important;
  opacity: 0;
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-name: fadeInUp;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
#header.theme-white #header-logo svg path, body:not(#page-index) #header #header-logo svg path {
  fill: #000000;
}
#header.theme-white #trigger-search svg path, body:not(#page-index) #header #trigger-search svg path {
  fill: #000000;
}
#header.is-hidden {
  transform: translate(0, -100%);
}

#header-logo img,
#header-logo svg {
  width: 38px;
  height: 38px;
  transition: all 0.25s;
}

#trigger-search {
  margin-left: 40px;
}
#trigger-search span {
  display: block;
  width: 44px;
  height: 44px;
  margin: -12px;
  position: relative;
}
#trigger-search img,
#trigger-search svg {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

#hamburger {
  display: none;
}

body:not(#page-index) #header {
  padding: 25px 0;
}
body:not(#page-index) #header.is-shrink {
  padding: 16px 0;
}
body:not(#page-index) #header.is-shrink #header-logo svg {
  width: 38px;
  height: 38px;
}
body:not(#page-index) #header.is-shrink #header-menu {
  margin-top: 0;
}
body:not(#page-index) #header-logo svg {
  width: 80px;
  height: 80px;
}
body:not(#page-index) #header-menu {
  margin-top: -30px;
  transition: margin-top 0.25s;
}

@media (max-width: 1280px) {
  #header {
    padding: 25px 0;
  }
  #header h1 {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.2;
  }
  #header nav > a {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    margin-left: 30px;
  }
  #header.theme-white, body:not(#page-index) #header {
    padding: 14px 0;
  }
  #trigger-search {
    margin-left: 35px;
  }
  #trigger-search span {
    margin: -14px;
  }
  #trigger-search img,
#trigger-search svg {
    width: 16px;
  }
  body:not(#page-index) #header {
    padding: 20px 0;
  }
  body:not(#page-index) #header-logo svg {
    width: 60px;
    height: 60px;
  }
  body:not(#page-index) #header-menu {
    margin-top: -20px;
  }
}
@media (max-width: 1023px) {
  #hamburger:after, #hamburger:before, #hamburger span {
    content: "";
    display: block;
    width: 26px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 22px auto 0;
    transition: background 0.3s ease, top 0.3s ease 0.3s, transform 0.3s ease;
  }
  #hamburger {
    display: block;
    width: 44px;
    height: 44px;
    margin: -10px;
    margin-left: auto;
    position: relative;
    cursor: pointer;
  }
  #hamburger:before {
    top: -9px;
  }
  #hamburger:after {
    top: 9px;
  }
  #hamburger.is-active:before, #hamburger.is-active:after {
    opacity: 1;
    top: 0;
    transition: top 0.3s ease, transform 0.3s ease 0.3s;
  }
  #hamburger.is-active:before {
    transform: rotate(45deg);
  }
  #hamburger.is-active:after {
    transform: rotate(-45deg);
  }
  #hamburger.is-active span {
    background: transparent !important;
  }
  #header nav {
    flex-direction: column;
  }
  #header nav > a {
    font-size: 1.875em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.0666;
    font-weight: 400;
    margin-left: 0;
    margin-bottom: 24px;
  }
  #header.theme-white #header-logo img, body:not(#page-index) #header #header-logo img,
#header.theme-white #header-logo svg,
body:not(#page-index) #header #header-logo svg {
    width: 38px;
    height: 38px;
  }
  #header.theme-white #hamburger span, #hamburger #header.theme-white span, body:not(#page-index) #header #hamburger span, #hamburger body:not(#page-index) #header span, #header.theme-white #hamburger:before, body:not(#page-index) #header #hamburger:before, #header.theme-white #hamburger:after, body:not(#page-index) #header #hamburger:after {
    background: #000000;
  }
  #header.theme-white #header-menu:before, body:not(#page-index) #header #header-menu:before {
    background: #ffffff;
  }
  #header.is-active {
    transform: none !important;
  }
  #header.is-active #header-logo h1 {
    display: none;
  }
  #header.is-active #header-logo svg {
    display: block !important;
    opacity: 0;
    animation-delay: 0s;
    animation-duration: 0.25s;
    animation-name: fadeInUp;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.is-active #header-logo svg path {
    fill: #ffffff;
  }
  #header.is-active.theme-white #header-logo svg path, body:not(#page-index) #header.is-active #header-logo svg path {
    fill: #000000;
  }
  #header.is-active #header-menu {
    display: flex !important;
  }
  #header.is-active #header-menu:before {
    transform: scale(1, 1);
  }
  #header.is-active #header-menu nav > a {
    animation-delay: 0s;
    animation-duration: 0.8s;
    animation-name: fadeInUp;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.is-active #header-menu nav > a:nth-child(1) {
    animation-delay: 0.12s;
  }
  #header.is-active #header-menu nav > a:nth-child(2) {
    animation-delay: 0.24s;
  }
  #header.is-active #header-menu nav > a:nth-child(3) {
    animation-delay: 0.36s;
  }
  #header.is-active #header-menu nav > a:nth-child(4) {
    animation-delay: 0.48s;
  }
  #header.is-active #header-menu nav > a:nth-child(5) {
    animation-delay: 0.6s;
  }
  #header.is-active #header-menu nav > a:nth-child(6) {
    animation-delay: 0.72s;
  }
  #header.is-active #header-menu nav > a:nth-child(7) {
    animation-delay: 0.84s;
  }
  #header.is-active #header-menu nav > a:nth-child(8) {
    animation-delay: 0.96s;
  }
  #header.is-active #header-menu nav > a:nth-child(9) {
    animation-delay: 1.08s;
  }
  #header.is-active #header-menu nav > a:nth-child(10) {
    animation-delay: 1.2s;
  }
  #header.is-active #header-menu > p {
    animation-delay: 0.8s;
    animation-duration: 0.8s;
    animation-name: fadeInUp;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.is-active #header-menu > small {
    animation-delay: 1s;
    animation-duration: 0.8s;
    animation-name: fadeInUp;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.animate-out #header-menu:before {
    transform: scale(1, 0);
    transform-origin: center bottom;
    transition: transform 0.8s 0.4s ease;
  }
  #header.animate-out #header-menu nav > a {
    animation-delay: 0s;
    animation-duration: 0.25s;
    animation-name: fadeOutDown;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.animate-out #header-menu nav > a:nth-child(1) {
    animation-delay: 0.4s;
  }
  #header.animate-out #header-menu nav > a:nth-child(2) {
    animation-delay: 0.3s;
  }
  #header.animate-out #header-menu nav > a:nth-child(3) {
    animation-delay: 0.2s;
  }
  #header.animate-out #header-menu nav > a:nth-child(4) {
    animation-delay: 0.1s;
  }
  #header.animate-out #header-menu nav > a:nth-child(5) {
    animation-delay: 0s;
  }
  #header.animate-out #header-menu nav > a:nth-child(6) {
    animation-delay: -0.1s;
  }
  #header.animate-out #header-menu nav > a:nth-child(7) {
    animation-delay: -0.2s;
  }
  #header.animate-out #header-menu nav > a:nth-child(8) {
    animation-delay: -0.3s;
  }
  #header.animate-out #header-menu nav > a:nth-child(9) {
    animation-delay: -0.4s;
  }
  #header.animate-out #header-menu nav > a:nth-child(10) {
    animation-delay: -0.5s;
  }
  #header.animate-out #header-menu > p {
    animation-delay: 0.1s;
    animation-duration: 0.25s;
    animation-name: fadeOutDown;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header.animate-out #header-menu > small {
    animation-delay: 0s;
    animation-duration: 0.25s;
    animation-name: fadeOutDown;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  #header-logo img,
#header-logo svg {
    width: 48px;
    height: 48px;
  }
  #header-menu {
    flex-direction: column;
    height: 100%;
    padding-top: 180px;
    padding-inline: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  #header-menu:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    z-index: -1;
    transform: scale(1, 0);
    transform-origin: center top;
    transition: transform 1s 0.4s ease;
  }
  #header-menu nav > a {
    opacity: 0;
  }
  #header-menu > p,
#header-menu > small {
    opacity: 0;
  }
  #header-menu > p {
    margin-top: 60px;
    margin-bottom: 10px;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.2;
    font-weight: 400;
  }
  #header-menu > small {
    display: block;
    margin-top: auto;
    margin-bottom: 40px;
  }
  #cookie-banner:not(.is-hidden) ~ #header #header-menu {
    height: calc(100% - 37px);
    top: 37px;
  }
  body:not(#page-index) #hamburger {
    margin-top: -20px;
  }
  body:not(#page-index) #header-menu {
    margin-top: 0;
  }
  body:not(#page-index).is-shrink #hamburger {
    margin-top: -10px;
  }
}
@media (max-width: 414px) {
  #header {
    padding: 17px 0;
  }
  #header.theme-white, body:not(#page-index) #header {
    padding: 11px 0;
  }
  body:not(#page-index) #header.is-shrink {
    padding: 11px 0;
  }
  #header-logo img,
#header-logo svg {
    width: 38px;
    height: 38px;
  }
  #header-menu {
    padding-top: 120px;
    padding-inline: 30px;
  }
  #header-menu > small {
    margin-bottom: 30px;
  }
}
@media (max-width: 1023px) and (max-height: 730px) {
  #header nav > a {
    margin-bottom: 16px;
  }
  #header-menu {
    padding-top: 100px;
  }
  #header-menu > p {
    margin-top: 20px;
  }
  #header-menu > small {
    margin-bottom: 20px;
  }
}
/* -------------COOKIE------------ */
#cookie-banner {
  height: 37px;
  padding: 12px 0;
  position: relative;
  z-index: 2;
  transition: margin 0.4s;
}
#cookie-banner p {
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  margin-right: 15px;
}
#cookie-banner button {
  display: block;
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  color: #ffffff;
  transition: opacity 0.2s;
}
#cookie-banner.is-hidden {
  margin-top: -37px;
}

#trigger-cookie-accept {
  text-decoration: underline;
}
#trigger-cookie-accept:hover, #trigger-cookie-accept:focus-visible {
  opacity: 0.8;
}

#trigger-cookie-decline {
  opacity: 0.4;
}
#trigger-cookie-decline:hover, #trigger-cookie-decline:focus-visible {
  opacity: 0.8;
}

/* -------------FOOTER------------ */
#footer {
  padding: 31px 0 27px;
  position: relative;
  overflow: hidden;
}
#footer p {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 500;
  text-transform: capitalize;
}
#footer p span.font-zh {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0571em;
  line-height: 1;
  margin-left: 10px;
}
#footer nav {
  margin-left: auto;
}
#footer nav > a {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 500;
  margin-left: 20px;
  padding-block: 15px;
  margin-block: -15px;
  transition: opacity 0.2s;
}
#footer nav > a:hover, #footer nav > a:focus-visible {
  opacity: 0.5;
}

#trigger-privacy {
  display: block;
  margin-left: 20px;
  border-left: 1px solid #D0D0D0;
  padding-left: 20px;
  padding-top: 2px;
  transition-property: opacity, transform, color;
}
#trigger-privacy:hover, #trigger-privacy:focus-visible {
  color: #000000;
}

/* -------------RWD--------------- */
@media (max-width: 1024px) {
  #footer {
    padding: 25px 0 22px;
  }
  #footer nav > a {
    margin-left: 15px;
  }
  #trigger-privacy {
    margin-left: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 1023px) {
  #footer .container {
    flex-direction: column-reverse;
  }
  #footer p {
    font-size: 0.8125em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
  }
  #footer nav {
    margin-left: 0;
    margin-bottom: 27px;
  }
  #footer nav > a {
    font-size: 0.9375em;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
    margin-left: 0;
    margin-right: 10px;
  }
  #trigger-privacy {
    font-size: 0.8125em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    padding-left: 0;
    border-left: none;
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  #footer {
    padding: 30px 0;
  }
}
@media (max-width: 360px) {
  #footer nav > a {
    font-size: 0.875em;
  }
  #footer .content {
    flex-direction: column;
  }
  #trigger-privacy {
    margin-left: 0;
    margin-top: 12px;
  }
}
/* -------------CTA SECTION------------ */
.sec-cta {
  padding-bottom: 90px;
}
.sec-cta hr {
  border-top: 2px solid #000000;
  margin-bottom: 60px;
}
.sec-cta h2 {
  max-width: 10em;
}
.sec-cta .btn {
  margin: 20px 0 0;
}
.sec-cta .btn i {
  width: 1.5625rem;
  height: 1.5625rem;
}

/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-cta {
    padding-bottom: 80px;
  }
  .sec-cta hr {
    margin-bottom: 40px;
  }
  .sec-cta h2 {
    font-size: 9.027vw;
    line-height: 1.15384em;
  }
  .sec-cta .btn {
    font-size: 1.75em;
  }
}
@media (max-width: 768px) {
  .sec-cta {
    padding-bottom: 60px;
  }
  .sec-cta hr {
    margin-bottom: 60px;
  }
  .sec-cta h2 {
    font-size: 14.0625vw;
    line-height: 1;
    letter-spacing: -0.037em;
  }
  .sec-cta .btn {
    font-size: 2em;
    font-weight: 500;
    line-height: 1.3125;
    letter-spacing: 0;
    font-size: 1.5625em;
    font-weight: 500;
    line-height: 1.28;
  }
}
@media (max-width: 414px) {
  .sec-cta hr {
    margin-bottom: 50px;
  }
  .sec-cta h2 {
    font-size: 15.1vw;
  }
  .sec-cta .btn {
    font-size: 1.5625em;
    line-height: 1.28;
    letter-spacing: 0;
  }
}
@media (max-width: 360px) {
  .sec-cta .btn {
    font-size: 1.375em;
  }
}
/*----lightbox---------*/
[data-aos=fade-up] {
  transform: translate3d(0, 20px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -20px, 0);
}

/* ---------ANIMATION-------- */
@-webkit-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@-moz-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@-ms-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@-o-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes showFilter {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-moz-keyframes showFilter {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-ms-keyframes showFilter {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-o-keyframes showFilter {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes showFilter {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 30px);
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 30px);
  }
}
@-ms-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 30px);
  }
}
@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 30px);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 30px);
  }
}
@-webkit-keyframes playSpriteH {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
@-moz-keyframes playSpriteH {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
@-ms-keyframes playSpriteH {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
@-o-keyframes playSpriteH {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
@keyframes playSpriteH {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
@-webkit-keyframes playSpriteV {
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}
@-moz-keyframes playSpriteV {
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}
@-ms-keyframes playSpriteV {
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}
@-o-keyframes playSpriteV {
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}
@keyframes playSpriteV {
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: 100%;
  }
}
@font-face {
  font-family: "Messina Sans";
  src: url("/assets/font/MessinaSansWeb-Book.woff2") format("woff2"), url("/assets/font/MessinaSansWeb-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Messina Sans";
  src: url("/assets/font/MessinaSansWeb-Regular.woff2") format("woff2"), url("/assets/font/MessinaSansWeb-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Messina Sans";
  src: url("/assets/font/MessinaSansWeb-SemiBold.woff2") format("woff2"), url("/assets/font/MessinaSansWeb-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* ---------TEXT------------- */
html {
  font-size: 16px;
}

body {
  font-family: "Messina Sans", -apple-system, "Pingfang TC", BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #ffffff;
  color: #000000;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6,
a, p, b, small {
  font: inherit;
  color: inherit;
}

h1 {
  font-size: 15.625em;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
}

h2 {
  font-size: 9.375em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1.1333;
  text-transform: uppercase;
}

h3 {
  font-size: 8.75em;
  font-weight: 600;
  letter-spacing: -0.02857em;
  line-height: 1;
}

h4 {
  font-size: 3.875em;
  font-weight: 600;
  letter-spacing: -0.0323em;
  line-height: 1.1613;
}

h5 {
  font-size: 2.625em;
  font-weight: 600;
  letter-spacing: -0.0238em;
  line-height: 1.2;
}

h6 {
  font-size: 2.375em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.3158;
}

p {
  font-size: 1em;
}

b,
strong {
  font: inherit;
  letter-spacing: inherit;
  font-weight: 600;
}

small {
  font-size: 0.875em;
  line-height: inherit;
}

a {
  display: inline-block;
  font: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: none;
  color: inherit;
}

code {
  font-family: "Consolas", monospace;
}

option {
  font-family: "Messina Sans", -apple-system, "Pingfang TC", BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.color-white {
  color: #ffffff;
}

.color-dark {
  color: #444444;
}

.color-black {
  color: #000000;
}

.color-txt-grey-1 {
  color: #808080;
}

.color-txt-grey-2 {
  color: #B9B9B9;
}

.color-stroke-grey-1 {
  color: #D0D0D0;
}

.color-stroke-grey-2 {
  color: #EEEEEE;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
}

.font-italic {
  font-style: italic;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-en-h1 {
  font-size: 15.625em;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
}

.font-en-h2 {
  font-size: 9.375em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1.1333;
  text-transform: uppercase;
}

.font-en-h3 {
  font-size: 8.75em;
  font-weight: 600;
  letter-spacing: -0.02857em;
  line-height: 1;
}

.font-en-h4 {
  font-size: 3.875em;
  font-weight: 600;
  letter-spacing: -0.0323em;
  line-height: 1.1613;
}

.font-en-h5 {
  font-size: 2.625em;
  font-weight: 600;
  letter-spacing: -0.0238em;
  line-height: 1.2;
}

.font-en-h6 {
  font-size: 2.375em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.3158;
}

.font-en-subtitle-1 {
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 1.4286;
  letter-spacing: 0.0286em;
}

.font-en-subtitle-2 {
  font-size: 2em;
  font-weight: 500;
  line-height: 1.3125;
  letter-spacing: 0;
}

.font-en-subtitle-3 {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 1.53333;
  letter-spacing: 0;
}

.font-en-subtitle-4 {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.1666;
  letter-spacing: 0;
  text-transform: uppercase;
}

.font-en-subtitle-5 {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.font-en-body-1 {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.7777;
  letter-spacing: -0.0111em;
}

.font-en-body-2 {
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-body-3 {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.0133em;
}

.font-en-btn-1 {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.0066em;
}

.font-en-btn-2 {
  font-size: 1.375em;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.0455em;
  text-transform: uppercase;
}

.font-en-btn-3 {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-btn-4 {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
}

.font-en-btn-m-1 {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-btn-m-2 {
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-btn-m-3 {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-smal {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.008em;
}
.font-en-smal b,
.font-en-smal strong {
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.0133em;
}

.font-en-caption-1 {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -0.0125em;
}

.font-en-caption-2 {
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
}

.font-en-overline-1 {
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.font-en-overline-2 {
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.025em;
}

.font-en-m-h1 {
  font-size: 15.625em;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  font-size: 5.625em;
  letter-spacing: -0.01666em;
}

.font-en-m-h2 {
  font-size: 4.375em;
  font-weight: 600;
  letter-spacing: -0.0171em;
  line-height: 1;
}

.font-en-m-h3 {
  font-size: 3.625em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.0345;
}

.font-en-m-h4 {
  font-size: 2em;
  font-weight: 500;
  letter-spacing: -0.0039em;
  line-height: 1.125;
}

.font-en-m-h5 {
  font-size: 1.875em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.0666;
}

.font-en-m-h6 {
  font-size: 2.375em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.3158;
  font-size: 1.6875em;
  letter-spacing: 0;
  line-height: 1.3704;
}

.font-en-m-subtitle-1 {
  font-size: 1.625em;
  font-weight: 600;
  line-height: 1.3077;
  letter-spacing: 0;
}

.font-en-m-subtitle-2 {
  font-size: 2em;
  font-weight: 500;
  line-height: 1.3125;
  letter-spacing: 0;
  font-size: 1.5625em;
  font-weight: 500;
  line-height: 1.28;
}

.font-en-m-subtitle-3 {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
}

.font-en-m-subtitle-4 {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.2;
}

.font-en-m-caption-1 {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.4286;
  letter-spacing: 0;
}

.font-en-m-overline-1 {
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  letter-spacing: 0;
}

.font-en-m-overline-2 {
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.025em;
  letter-spacing: 0;
}

.font-zh-h1 {
  font-size: 2.625em;
  font-weight: 500;
  letter-spacing: 0.0357em;
  line-height: 1.4048;
}

.font-zh-h2 {
  font-size: 2.375em;
  font-weight: 500;
  letter-spacing: 0.0263em;
  line-height: 1.7368;
}

.font-zh-h3 {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.8333;
}

.font-zh-subtitle-1 {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
}

.font-zh-subtitle-2 {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.3888;
  letter-spacing: 0.0444em;
}

.font-zh-body-1 {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.7777;
  letter-spacing: 0.0444em;
}

.font-zh-body-2 {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}

.font-zh-body-3 {
  font-size: 0.9375em;
  font-weight: 400;
  line-height: 1.8667;
  letter-spacing: 0.013em;
}

.font-zh-caption-1 {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.04em;
}

.font-zh-caption-2 {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5714;
  letter-spacing: 0.0143em;
}

.font-zh-overline-1 {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0571em;
}

.font-zh-btn-1 {
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0313em;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 0.125em;
  text-decoration-thickness: 2px;
}

/* -------------RWD--------------- */
@media (min-width: 2560px) {
  html {
    font-size: 20px;
  }
}
@media (min-width: 3200px) {
  html {
    font-size: 28px;
  }
}
/* ---------caption-------------- */
.caption {
  display: inline-block;
}

/* ---------overline-------------- */
.overline {
  display: inline-block;
}

.bg-white {
  background-color: #ffffff;
}

.bg-light {
  background-color: #f9f9f9;
}

.bg-dark {
  background-color: #222222;
}

.bg-black {
  background-color: #000000;
}

.btn {
  display: inline-block;
  padding: 0.45em 1.0625em 0.5em;
  border-radius: 200px;
  border: 2px solid #EEEEEE;
  margin: 15px;
  color: black;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.btn:before, .btn:after {
  content: "";
  position: absolute;
  background: #ffffff;
  z-index: -1;
  backface-visibility: hidden;
}
.btn:before {
  width: 120%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 -10%;
  border-radius: 200px;
  transform: scale(0, 1) translateZ(0);
}
.btn:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.6s;
}
.btn svg {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  margin-left: 0.2rem;
  margin-right: -0.2rem;
  margin-top: -0.06em;
  transition: transform 0.3s;
}
@media (hover: hover) {
  .btn:hover {
    border-color: #000000;
    transition: border-color 0.6s 0.15s;
  }
  .btn:hover:before {
    transform: scale(1, 1) translateZ(0);
    transition: all 0.6s cubic-bezier(0, 0.89, 0.41, 1);
  }
  .btn:hover:after {
    opacity: 1;
    transition: opacity 0.6s 0.15s;
  }
}
.btn:focus-visible {
  border-color: #000000;
  transition: border-color 0.6s 0.15s;
}
.btn:focus-visible:before {
  transform: scale(1, 1) translateZ(0);
  transition: all 0.6s cubic-bezier(0, 0.89, 0.41, 1);
}
.btn:focus-visible:after {
  opacity: 1;
  transition: opacity 0.6s 0.15s;
}
.btn:active svg {
  transform: translate(0.125em, 0);
}
.btn.btn-white:before, .btn.btn-white:after {
  background: #000000;
}
@media (hover: hover) {
  .btn.btn-white:hover {
    color: #ffffff;
  }
  .btn.btn-white:hover svg path {
    fill: #ffffff;
  }
}
.btn.btn-white:focus-visible {
  color: #ffffff;
}
.btn.btn-white:focus-visible svg path {
  fill: #ffffff;
}
.btn.btn-black {
  border-color: #000000;
}
@media (hover: hover) {
  .btn.btn-black:hover {
    color: #ffffff;
  }
  .btn.btn-black:hover svg path {
    fill: #ffffff;
  }
}
.btn.btn-black:focus-visible {
  color: #ffffff;
}
.btn.btn-black:focus-visible svg path {
  fill: #ffffff;
}
.btn.btn-s {
  border-width: 1px;
  margin: 0;
}
.btn.btn-m {
  padding: 14px 31px;
  margin: 0;
  text-decoration: none;
  font-size: 2em;
  font-weight: 500;
  line-height: 1.3125;
  letter-spacing: 0;
  font-size: 1.5625em;
  font-weight: 500;
  line-height: 1.28;
}
.btn.btn-m.btn-zh {
  padding: 14px 33px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
}
.btn.btn-m svg {
  width: 1rem;
  margin-top: -0.35rem;
}

.btn-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  transition: border-color 0.25s ease;
}
.btn-close img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.btn-close:hover, .btn-close:focus-visible {
  border-color: #ffffff;
}

.link-underline {
  padding-bottom: 2px;
  border-bottom: 1px solid;
  transition: color 0.25s, border-color 0.25s;
}
.link-underline:hover, .link-underline:focus-visible {
  color: #808080;
}

.link-arrow {
  transition: color 0.25s;
}
.link-arrow i {
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  margin: 0.4em 0 -0.4em 5px;
  background-image: url(/assets/img/icon/arrow-right-black.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
.link-arrow i.white {
  background-image: url(/assets/img/icon/arrow-right-white.svg);
}
.link-arrow:hover, .link-arrow:focus-visible {
  color: #808080;
}
.link-arrow:hover i, .link-arrow:focus-visible i {
  background-image: url(/assets/img/icon/arrow-right-grey.svg) !important;
}

@media (max-width: 1024px) {
  .btn.btn-m {
    padding: 6px 20px;
    border-width: 1px;
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
  }
  .btn.btn-m.btn-zh {
    padding: 14px 20px;
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.3888;
    letter-spacing: 0.0444em;
    font-weight: 400;
  }
  .btn.btn-m svg {
    width: 0.625rem;
    margin-left: 0.2rem;
    margin-right: 0;
  }
  .btn-close {
    width: 26px;
    height: 26px;
  }
  .btn-close img {
    width: 9.45px;
  }
}
/* ---------BLOCK------------ */
.container {
  width: 100%;
  padding-inline: 60px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 24px;
}

.col-10 {
  width: 10%;
}

.col-20 {
  width: 20%;
}

.col-30 {
  width: 30%;
}

.col-40 {
  width: 40%;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: 60%;
}

.col-70 {
  width: 70%;
}

.col-80 {
  width: 80%;
}

.col-90 {
  width: 90%;
}

.col-100 {
  width: 100%;
}

.g-col-1 {
  grid-column: auto/span 1;
}

.g-col-2 {
  grid-column: auto/span 2;
}

.g-col-3 {
  grid-column: auto/span 3;
}

.g-col-4 {
  grid-column: auto/span 4;
}

.g-col-5 {
  grid-column: auto/span 5;
}

.g-col-6 {
  grid-column: auto/span 6;
}

.g-col-7 {
  grid-column: auto/span 7;
}

.g-col-8 {
  grid-column: auto/span 8;
}

.g-col-9 {
  grid-column: auto/span 9;
}

.g-col-10 {
  grid-column: auto/span 10;
}

.g-col-11 {
  grid-column: auto/span 11;
}

.g-col-12 {
  grid-column: auto/span 12;
}

.g-start-1 {
  grid-column-start: 1;
}

.g-start-2 {
  grid-column-start: 2;
}

.g-start-3 {
  grid-column-start: 3;
}

.g-start-4 {
  grid-column-start: 4;
}

.g-start-5 {
  grid-column-start: 5;
}

.g-start-6 {
  grid-column-start: 6;
}

.g-start-7 {
  grid-column-start: 7;
}

.g-start-8 {
  grid-column-start: 8;
}

.g-start-9 {
  grid-column-start: 9;
}

.g-start-10 {
  grid-column-start: 10;
}

.g-start-11 {
  grid-column-start: 11;
}

.z-2 {
  position: relative;
  z-index: 2;
}

.ib-top {
  display: inline-block;
  vertical-align: top;
}

.ib-bottom {
  display: inline-block;
  vertical-align: bottom;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-inline: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

hr {
  border: 0;
  height: 0;
  border-top: 2px solid #000000;
  margin: 0;
  transform-origin: 0 0;
}
hr[data-aos] {
  transform: scaleX(0);
  transition: transform 0.6s ease;
}
hr[data-aos].aos-animate {
  transform: scaleX(1);
}
hr.hr-stroke-grey-2 {
  border-color: #EEEEEE;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .container {
    padding-inline: 40px;
  }
}
@media (max-width: 1024px) {
  .d-1024-block {
    display: block !important;
  }
  .d-1024-flex {
    display: flex !important;
  }
  .d-1024-none {
    display: none !important;
  }
}
@media (max-width: 1023px) {
  .container {
    padding-inline: 25px;
  }
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .g-start-md-1 {
    grid-column-start: 1;
  }
  .g-start-md-2 {
    grid-column-start: 2;
  }
  .g-start-md-3 {
    grid-column-start: 3;
  }
  .g-start-md-4 {
    grid-column-start: 4;
  }
  .g-start-md-5 {
    grid-column-start: 5;
  }
  .g-start-md-6 {
    grid-column-start: 6;
  }
  .d-1023-block {
    display: block !important;
  }
  .d-1023-flex {
    display: flex !important;
  }
  .d-1023-none {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .d-768-block {
    display: block !important;
  }
  .d-768-none {
    display: none !important;
  }
}
@media (max-width: 414px) {
  .container {
    padding-inline: 17px;
  }
  .grid {
    grid-column-gap: 10px;
  }
  .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .g-start-sm-1 {
    grid-column-start: 1;
  }
  .g-start-sm-2 {
    grid-column-start: 2;
  }
  .g-start-sm-3 {
    grid-column-start: 3;
  }
  .g-start-sm-4 {
    grid-column-start: 4;
  }
  .g-start-sm-5 {
    grid-column-start: 5;
  }
  .g-start-sm-6 {
    grid-column-start: 6;
  }
  .d-480-block {
    display: block !important;
  }
  .d-480-none {
    display: none !important;
  }
}
.list-disc > li, .sec-hiring ul > li {
  line-height: 1.5;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.125em;
}
.list-disc > li:before, .sec-hiring ul > li:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000000;
  position: absolute;
  top: 0.75em;
  left: 0.5em;
}

.list-decimal, .sec-hiring ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.list-decimal > li, .sec-hiring ol > li {
  line-height: 1.5;
  position: relative;
  padding-left: 1em;
  margin-bottom: 0.125em;
  text-indent: -1em;
}

.work-item, .news-item {
  display: block;
  margin-bottom: 160px;
}
.work-item figure, .news-item figure {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background: #f9f9f9;
}
.work-item figure[data-aos=fade-up], .news-item figure[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}
.work-item figure .content[data-aos=fade-up], .news-item figure .content[data-aos=fade-up] {
  transform: translate3d(0, 60px, 0);
}
.work-item figure video, .news-item figure video,
.work-item figure iframe,
.news-item figure iframe,
.work-item figure img,
.news-item figure img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.work-item figure iframe, .news-item figure iframe {
  pointer-events: none;
}
.work-item h3, .news-item h3 {
  margin-bottom: 3px;
}
.work-item p, .news-item p {
  font-weight: 400;
  letter-spacing: 0;
}
.work-item p.font-zh, .news-item p.font-zh {
  font-family: "Pingfang TC", -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 0.05em;
}
.work-item .tag-container, .news-item .tag-container {
  margin-top: 12px;
}
.work-item .tag-container > li, .news-item .tag-container > li {
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  color: #808080;
  margin-right: 12px;
}
.work-item:nth-child(6n+1), .news-item:nth-child(6n+1) {
  grid-column: auto/span 7;
}
.work-item:nth-child(6n+2), .news-item:nth-child(6n+2) {
  grid-column: auto/span 5;
}
.work-item:nth-child(6n+3), .news-item:nth-child(6n+3) {
  grid-column: auto/span 6;
  grid-column-start: 5;
}
.work-item:nth-child(6n+4), .news-item:nth-child(6n+4) {
  grid-column: auto/span 5;
}
.work-item:nth-child(6n+5), .news-item:nth-child(6n+5) {
  grid-column: auto/span 7;
}
.work-item:nth-child(6n+6), .news-item:nth-child(6n+6) {
  grid-column: auto/span 9;
}

.news-item {
  margin-bottom: 120px;
}
.news-item:nth-child(n) {
  grid-column: auto/span 4 !important;
}
.news-item .date {
  margin-bottom: 12px;
}
.news-item .date b {
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
  font-weight: bold;
}
.news-item .date time {
  display: inline-block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
  font-weight: 300;
  margin-left: 10px;
}
.news-item .content hr {
  margin: 10px 0;
  border-top: 1px solid #D0D0D0;
}
.news-item .content h3 {
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
  text-transform: none;
}
.news-item .content p {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.04em;
  font-weight: 400;
  line-height: 1.7333;
  letter-spacing: 0.05333em;
  color: #808080;
}

.hover-bg {
  border-bottom: 2px solid #EEEEEE;
  transition: border-color 0.25s;
  position: relative;
  z-index: 2;
}
.hover-bg:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: #f9f9f9;
  z-index: -1;
  transform-origin: center bottom;
  transform: scale(1, 0);
  transition: transform 0.25s;
}
.hover-bg:hover, .hover-bg:focus-visible {
  border-color: #000000 !important;
}
.hover-bg:hover:before, .hover-bg:focus-visible:before {
  transform: scale(1, 1);
}

.bg-light .hover-bg:before {
  background: #EEEEEE;
}

.accordion-item .title {
  border-bottom: 2px solid #EEEEEE;
  transition: border-color 0.25s;
  position: relative;
  cursor: pointer;
}
.accordion-item .title i {
  display: block;
  width: 1.25em;
  height: 1.25em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  margin: auto 0;
  transition: transform 0.25s 0.25s;
}
.accordion-item .title i:before, .accordion-item .title i:after {
  content: "";
  display: block;
  width: 1em;
  height: 2px;
  background: #EEEEEE;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: transform 0.25s, background 0.25s;
}
.accordion-item .title i:after {
  transform: rotate(90deg);
}
.accordion-item .title:hover i:before, .accordion-item .title:hover i:after, .accordion-item .title:focus-visible i:before, .accordion-item .title:focus-visible i:after {
  background: #000000;
}
.accordion-item .content {
  display: none;
  border-bottom: 2px solid #EEEEEE;
}
.accordion-item.is-active .title {
  border-bottom-color: #000000;
}
.accordion-item.is-active .title i {
  transform: rotate(-45deg);
  transition: transform 0.25s ease;
}
.accordion-item.is-active .title i:before, .accordion-item.is-active .title i:after {
  background: #000000;
  transition: transform 0.25s 0.25s ease;
}
.accordion-item.is-active .title i:before {
  transform: rotate(45deg);
}
.accordion-item.is-active .title i:after {
  transform: rotate(45deg);
}
.accordion-item.is-active .content {
  display: block;
  opacity: 0;
  animation-delay: 0.1s;
  animation-duration: 0.6s;
  animation-name: showFilter;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media (max-width: 1440px) {
  .work-item figure, .news-item figure {
    margin-bottom: 15px;
  }
  .work-item p, .news-item p {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.4286;
    letter-spacing: 0;
  }
  .work-item .tag-container, .news-item .tag-container {
    margin-bottom: -8px;
  }
  .work-item .tag-container > li, .news-item .tag-container > li {
    margin-bottom: 8px;
  }
  .news-item .date {
    font-size: 1em;
    line-height: 1;
  }
}
@media (max-width: 1280px) {
  .news-item {
    margin-bottom: 80px;
  }
  .news-item figure {
    margin-bottom: 20px;
  }
  .news-item .content h3 {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
  }
  .news-item .content p {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.7143;
    letter-spacing: 0.05714em;
  }
  .accordion-item .title i {
    right: 15px;
  }
}
@media (max-width: 1024px) {
  .news-item:nth-child(n) {
    grid-column: auto/span 6 !important;
  }
}
@media (max-width: 1023px) {
  .work-item .tag-container, .news-item .tag-container {
    margin-top: 10px;
  }
  .work-item:nth-child(3n+1), .news-item:nth-child(3n+1) {
    grid-column: auto/span 5;
  }
  .work-item:nth-child(3n+2), .news-item:nth-child(3n+2) {
    grid-column: auto/span 5;
    grid-column-start: 2;
  }
  .work-item:nth-child(3n+3), .news-item:nth-child(3n+3) {
    grid-column: auto/span 6;
  }
}
@media (max-width: 768px) {
  .accordion-item .title {
    border-bottom-width: 1px;
  }
}
@media (max-width: 414px) {
  .work-item, .news-item {
    margin-bottom: 100px;
  }
  .work-item h3, .news-item h3 {
    font-size: 1.1em;
  }
  .news-item {
    margin-bottom: 100px;
  }
  .accordion-item .title i {
    width: 1em;
    height: 1em;
  }
  .accordion-item .title i:before, .accordion-item .title i:after {
    width: 0.75em;
  }
}
.sec-main-title {
  position: relative;
  overflow: hidden;
  z-index: 3;
  transition: height linear 0.6s;
}
.sec-main-title h2 {
  margin-bottom: 20px;
}
.sec-main-title video {
  width: 80%;
  max-width: 1000px;
  margin: 10px auto 100px;
}

/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-main-title video {
    max-width: 1002px;
  }
}
@media (max-width: 1440px) {
  .sec-main-title h2 {
    font-size: 14.8438vw;
  }
}
@media (max-width: 1280px) {
  .sec-main-title video {
    width: 61.875%;
  }
}
@media (max-width: 1024px) {
  .sec-main-title video {
    width: 73.8281%;
  }
}
@media (max-width: 768px) {
  .sec-main-title h2 {
    font-size: 23.4375vw;
  }
  .sec-main-title video {
    width: 110%;
    margin: 60px -5% 140px;
  }
}
@media (max-width: 360px) {
  .sec-main-title video {
    margin-bottom: 120px;
  }
}
.category-container {
  padding: 30px 0;
}
.category-container nav {
  display: flex;
  align-items: flex-start;
}
.category-container nav a {
  display: block;
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  color: #808080;
  padding: 6px 20px;
  transition: color 0.25s;
}
.category-container nav a:first-child {
  padding-left: 15px;
}
.category-container nav a:hover, .category-container nav a:focus-visible, .category-container nav a.is-active {
  color: #000000;
}
.category-container nav > span {
  display: block;
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  position: relative;
}
.category-container nav > span:after {
  content: "";
  width: 1px;
  height: 1.5em;
  background: #D0D0D0;
  position: absolute;
  top: 0.25em;
  right: 0;
}

#trigger-filter {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  padding: 6px 15px;
  color: #808080;
  box-shadow: 0 0 0 1px #EEEEEE inset;
  border-radius: 15px;
  transition: color 0.25s, box-shadow 0.25s;
}
#trigger-filter svg {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  margin-left: 5px;
  background-size: contain;
}
#trigger-filter path {
  fill: #808080;
  transition: fill 0.25s;
}
#trigger-filter:hover, #trigger-filter:focus-visible, #trigger-filter.is-active {
  color: #000000;
  box-shadow: 0 0 0 1px #000000 inset;
}
#trigger-filter:hover path, #trigger-filter:focus-visible path, #trigger-filter.is-active path {
  fill: #000000;
}
#trigger-filter.is-active svg {
  transform: scale(1, -1);
}

#filter-container {
  width: calc(100% + 120px);
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: -6px;
  margin-inline: -60px;
  padding-inline: 60px;
  overflow-x: auto;
  overflow-y: hidden;
  transition: margin 0.3s ease;
}
#filter-container::-webkit-scrollbar {
  height: 0;
}
#filter-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#filter-container::-webkit-scrollbar-thumb {
  background: #888;
}
#filter-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#filter-container.is-animated {
  animation-delay: 0s;
  animation-duration: 0.4s;
  animation-name: showFilter;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
#filter-container[style*="height: 0px"] {
  margin-top: 0;
  margin-bottom: 0;
}
#filter-container li {
  flex-shrink: 0;
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  padding: 6px 15px;
  box-shadow: 0 0 0 1px #EEEEEE inset;
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: box-shadow 0.25s;
}
#filter-container li:last-child {
  margin-right: 0;
}
#filter-container li:hover, #filter-container li:focus-visible {
  box-shadow: 0 0 0 1px #000000 inset;
}
#filter-container li.is-active {
  cursor: default;
  color: #808080;
  box-shadow: 0 0 0 1px #D0D0D0 inset;
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  #filter-container {
    width: calc(100% + 80px);
    margin-inline: -40px;
    padding-inline: 40px;
  }
  .category-container {
    padding: 25px 0;
  }
}
@media (max-width: 1024px) {
  .category-container {
    padding: 20px 0;
  }
}
@media (max-width: 1023px) {
  #filter-container {
    width: calc(100% + 50px);
    margin-inline: -25px;
    padding-inline: 25px;
  }
  .category-container {
    padding: 15px 0;
  }
  .category-container nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .category-container nav a {
    padding: 6px 15px;
  }
  .category-container nav a:first-child {
    padding-left: 0;
  }
  #trigger-filter {
    margin-left: auto;
  }
}
@media (max-width: 414px) {
  #filter-container {
    width: calc(100% + 34px);
    margin-inline: -17px;
    padding-inline: 17px;
  }
  #mobile-sticky-filter {
    position: sticky;
    top: -1px;
    z-index: 3;
    background: white;
    transition: transform 0.4s linear;
  }
  #mobile-sticky-filter .category-container {
    transition-duration: 0.4s !important;
  }
  #mobile-sticky-filter.header-show {
    transform: translateY(var(--header_height));
  }
  #mobile-sticky-filter.not-sticky {
    transform: translateY(0);
  }
}
@media (max-width: 360px) {
  .category-container nav a,
.category-container nav > span {
    font-size: 0.8125em;
  }
  #trigger-filter span {
    font-size: 0.8125em;
  }
  #filter-container li {
    font-size: 0.8125em;
  }
}
.editor-content {
  font-size: 16px;
  color: #000000;
  word-break: break-all;
  word-break: break-word;
  /* 標記背景色 */
  /* 單行引用文字 */
  /* 引用來源 */
  /* keyboard input */
}
.editor-content h2,
.editor-content h3,
.editor-content h4,
.editor-content h5,
.editor-content h6 {
  font-weight: 500;
  margin: 0.5rem 0;
  line-height: 1.75;
  color: #000000;
}
.editor-content h2 {
  font-size: 2em;
}
.editor-content h3 {
  font-size: 1.75em;
}
.editor-content h4 {
  font-size: 1.5em;
}
.editor-content h5 {
  font-size: 1.25em;
}
.editor-content h6 {
  font-size: 1.125em;
}
.editor-content p {
  margin-bottom: 1.5rem;
}
.editor-content p, .editor-content li {
  font-size: 1em;
  line-height: 1.75;
}
.editor-content a {
  display: inline;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: #000000;
}
.editor-content a:hover {
  text-decoration: underline;
}
.editor-content .marker {
  background: #000000;
}
.editor-content big {
  font-size: 1.25em;
}
.editor-content small {
  font-size: 0.85em;
}
.editor-content b,
.editor-content strong {
  font-weight: bold;
}
.editor-content q {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}
.editor-content cite {
  font-style: italic;
}
.editor-content kbd {
  font-family: "Consolas", monospace;
}
.editor-content samp { /* output範例 */
  display: block;
  border: 1px solid #eeeeee;
  padding: 10px;
}
.editor-content blockquote { /* 引用段落文字 */
  display: block;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  line-height: 1.48;
  letter-spacing: -0.012rem;
  margin: 1rem 0;
  padding: 0 1rem;
}
.editor-content img,
.editor-content iframe {
  height: auto;
  max-width: 100%;
  max-height: none;
}
.editor-content .vid-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
.editor-content iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.editor-content ul {
  margin: 0.5rem 0;
  list-style-position: inside;
}
.editor-content ul li {
  list-style-type: disc;
}
.editor-content ul li li {
  list-style-type: circle;
  margin-left: 2rem;
}
.editor-content ul li li li {
  list-style-type: block;
  margin-left: 2rem;
}
.editor-content ol {
  margin: 10px 0;
  list-style-position: inside;
}
.editor-content ol li {
  list-style-type: decimal;
}
.editor-content ol li li {
  list-style-type: upper-alpha;
  margin-left: 2rem;
}
.editor-content ol li li li {
  list-style-type: lower-alpha;
  margin-left: 2rem;
}
.editor-content ol li li li li {
  list-style-type: lower-latin;
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .editor-content {
    font-size: 14px;
  }
}
.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  margin-bottom: 100px;
}
.pagination a,
.pagination p {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  color: #B9B9B9;
  cursor: pointer;
  transition: color 0.25s;
}
.pagination a:hover, .pagination a:focus-visible,
.pagination p:hover,
.pagination p:focus-visible {
  color: #000000;
}
.pagination a.is-active,
.pagination p.is-active {
  color: #000000;
}
.pagination a.prev, .pagination a.next,
.pagination p.prev,
.pagination p.next {
  padding: 10px 0;
  margin: -10px 0;
  color: #000000;
}
.pagination a.prev.is-disabled, .pagination a.next.is-disabled,
.pagination p.prev.is-disabled,
.pagination p.next.is-disabled {
  color: #B9B9B9;
  pointer-events: none;
}
.pagination a.prev,
.pagination p.prev {
  margin-right: 20px;
}
.pagination a.next,
.pagination p.next {
  margin-left: 20px;
}
.pagination ol {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.pagination ol a,
.pagination ol p {
  padding: 10px 7.5px;
  margin: -10px 0;
}
.pagination ol li:first-child a {
  padding-left: 0;
}
.pagination ol li:last-child a {
  padding-right: 0;
}

@media (max-width: 1280px) {
  .pagination {
    margin-bottom: 80px;
  }
}
@media (max-width: 1023px) {
  .pagination {
    justify-content: space-between;
    margin-top: 20px;
  }
  .pagination ol a,
.pagination ol p {
    padding: 10px 5px;
  }
  .pagination p {
    color: #000000;
  }
  .pagination p.total:before {
    content: "/";
    display: inline-block;
    margin-right: 10px;
  }
}
.switch-container {
  display: inline-block;
  padding: 2px 0;
  border-radius: 2em;
  background: #ffffff;
  box-shadow: 0 0 0 1px #EEEEEE;
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}

.switch {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0 2px;
  position: relative;
  z-index: 2;
}
.switch button {
  display: block;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.025em;
  padding: 4px 6px;
  border-radius: 2em;
  color: #B9B9B9;
  transition: color 0.25s 0.25s;
  touch-action: none;
}
.switch button.is-active, .switch button:hover, .switch button:focus-visible {
  color: #ffffff;
}
.switch .highlight-span {
  width: 2em;
  height: 100%;
  position: absolute;
  z-index: -2;
  inset: 0px;
  background: #000000;
  border-radius: 2em;
  transform: translateX(0);
  transition: transform 0.3s ease;
}
.switch:hover button.is-active {
  color: #B9B9B9;
}
.switch:hover button.is-active:hover, .switch:hover button.is-active:focus-visible {
  color: #ffffff;
}

/* -------------RWD--------------- */
.swiper-button-next,
.swiper-button-prev {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  margin: 0;
  cursor: pointer;
  mix-blend-mode: exclusion;
  z-index: 3;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
  display: block;
  width: 38px;
  height: 46px;
  padding: 5px 0;
  background: url(/assets/img/icon/slide-next.svg) no-repeat center center;
  opacity: 0;
  right: 24px;
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
}
.swiper-button-next:hover:after, .swiper-button-next:focus-visible:after,
.swiper-button-prev:hover:after,
.swiper-button-prev:focus-visible:after {
  opacity: 1;
  transform: translate(4px, 0);
}

.swiper-button-prev {
  right: auto;
  left: 0;
}
.swiper-button-prev:after {
  background: url(/assets/img/icon/slide-prev.svg) no-repeat center center;
  right: auto;
  left: 24px;
}
.swiper-button-prev:hover:after, .swiper-button-prev:focus-visible:after {
  transform: translate(-4px, 0);
}

.swiper-pagination {
  position: relative;
  margin-top: 10px;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
  font-weight: 300;
  text-align: left;
  z-index: 3;
}

/* -------------RWD--------------- */
.modal {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 10;
}
.modal.is-hidden {
  pointer-events: none;
}
.modal.is-hidden .filter {
  opacity: 0;
}
.modal.is-hidden .bg-white {
  transform: translate(100%, 0);
}
.modal.is-hidden .modal-title,
.modal.is-hidden .btn-close {
  opacity: 0;
  animation-name: none;
}
.modal.is-hidden .modal-content {
  opacity: 0;
  animation-name: none;
}
.modal .filter {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
  transition: opacity 0.4s ease;
}
.modal .bg-white {
  min-height: 100%;
  margin-left: 120px;
  transition: transform 0.6s ease;
  position: relative;
  z-index: 2;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.modal .modal-title {
  padding-block: 66px 10px;
  animation-delay: 0.6s;
  animation-duration: 0.6s;
  animation-name: fadeIn;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  pointer-events: none;
}
.modal .modal-title > div {
  display: inline-block;
  pointer-events: all;
}
.modal .modal-content {
  animation-delay: 0.75s;
  animation-duration: 0.6s;
  animation-name: fadeInUp;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
.modal .btn-container {
  position: sticky;
  top: 60px;
  mix-blend-mode: exclusion;
  z-index: 3;
}
@supports (-webkit-touch-callout: none) {
  .modal .btn-container {
    /* CSS specific to iOS devices */
    filter: invert(1);
  }
}
.modal .btn-close {
  position: absolute;
  right: 60px;
}

_::-webkit-full-page-media, _:future, :root .modal .btn-container { /* Safari (7.1+) */
  filter: invert(1);
}

@media (min-width: 1681px) {
  .modal .bg-white {
    margin-left: 140px;
  }
}
@media (max-width: 1440px) {
  .modal .bg-white {
    margin-left: 100px;
  }
}
@media (max-width: 1280px) {
  .modal .bg-white {
    margin-left: 80px;
  }
  .modal .modal-title {
    padding-block: 46px;
  }
  .modal .btn-container {
    top: 40px;
  }
  .modal .btn-close {
    right: 40px;
  }
}
@media (max-width: 1024px) {
  .modal .modal-title .font-en-subtitle-5 {
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.0143em;
    font-weight: bold;
  }
}
@media (max-width: 1023px) {
  .modal .btn-close {
    right: 25px;
  }
}
@media (max-width: 768px) {
  .modal .bg-white {
    margin-left: 60px;
  }
  .modal .modal-title {
    padding-block: 36px 19px;
  }
  .modal .btn-container {
    top: 30px;
  }
}
@media (max-width: 414px) {
  .modal .bg-white {
    margin-left: 40px;
  }
  .modal .btn-close {
    right: 17px;
  }
}
@media (max-width: 360px) {
  .modal .bg-white {
    margin-left: 20px;
  }
}
/* -------------LOADER------------ */
#loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
}
#loader .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1.4s ease;
  will-change: transform;
  backface-visibility: hidden;
}
#loader .sprite-container {
  position: fixed;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s;
}
#loader .sprite {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/assets/img/loader-sprite.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 1600%;
  animation: playSpriteH 0.3s steps(15) infinite forwards, playSpriteV 2.1s steps(7) infinite forwards;
}
#loader .sprite.is-paused {
  animation: none;
}

html.is-loaded #loader .overlay {
  transform: translate(0, -100%);
}
html.is-loaded #loader .sprite-container {
  opacity: 0;
}
html.is-loaded #page-index .btn-container {
  animation-delay: 1s;
  animation-duration: 0.8s;
  animation-name: fadeInUp;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

/* -------------INDEX------------ */
#page-index {
  padding-top: 0 !important;
}
#page-index #header {
  background: transparent;
}
#page-index #header.theme-white, #page-index body:not(#page-index) #header, body:not(#page-index) #page-index #header {
  background: white;
}
#page-index .btn-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 35px;
  cursor: default;
  z-index: 2;
  pointer-events: none;
  user-select: none;
}
#page-index .btn-container img {
  width: 0.8em;
  margin-top: 0.8em;
}
#page-index .scroller {
  height: 400vh !important;
  max-height: none !important;
}
#page-index .sec-hero {
  position: relative;
  overflow: hidden;
}
#page-index .sec-hero .container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 0;
}
@supports (height: 100dvh) {
  #page-index .sec-hero .container {
    min-height: 100dvh;
  }
}
#page-index .sec-hero .wrapper {
  width: 100%;
}
#page-index .sec-hero .canvas-container,
#page-index .sec-hero .vid-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}
#page-index .sec-hero canvas,
#page-index .sec-hero video {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}
@supports (height: 100dvh) {
  #page-index .sec-hero canvas,
#page-index .sec-hero video {
    max-height: 100dvh;
  }
}
#page-index .sec-hero canvas {
  filter: contrast(1.3) brightness(0.99);
  backface-visibility: hidden;
}
#page-index .sec-intro {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
#page-index .sec-intro .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
#page-index .sec-intro p {
  font-weight: 500;
}
#page-index .sec-intro p br {
  content: "";
  display: block;
  margin: 0;
  margin-top: 0.47em;
}
#page-index .sec-reels {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
#page-index .sec-reels .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
#page-index .sec-reels .vid-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#page-index .sec-reels iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: cover;
}
#page-index .sec-reels .cover {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.4s ease;
}
#page-index .sec-reels .btn-pos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  pointer-events: none;
}
#page-index .sec-reels .btn {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -0.0125em;
  color: white;
  padding: 0.625em 1.375em 0.5em;
  border-width: 1px;
  opacity: 0.6;
  pointer-events: all;
  transition: opacity 0.25s ease, padding 0.8s ease;
}
#page-index .sec-reels .btn:before, #page-index .sec-reels .btn:after {
  display: none;
}
#page-index .sec-reels .btn i {
  display: inline-block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0.375em 0 0.375em 0.5625em;
  border-color: transparent transparent transparent #FFFFFF;
  margin-right: 0.3em;
}
#page-index .sec-reels .btn:hover, #page-index .sec-reels .btn:focus-visible {
  opacity: 1;
  background: none;
  color: white;
  border-color: white;
  padding: 0.75em 1.875em 0.625em;
}
#page-index .sec-reels.is-playing .cover {
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}
#page-index .sec-reels.is-playing .btn-pos {
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-name: fadeOut;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
#page-index .sec-reels.is-playing .btn {
  pointer-events: none;
}
#page-index .sec-featured {
  padding-top: 120px;
}
#page-index .sec-clients {
  padding: 120px 0 80px;
  position: relative;
  overflow: hidden;
}
#page-index .sec-clients h2 {
  text-transform: none;
  margin-bottom: 80px;
}
#page-index .sec-clients .swiper-wrapper {
  transition-timing-function: linear;
}
#page-index .sec-clients .swiper-slide img {
  margin: 0 auto;
}
#page-index .sec-clients a {
  display: block;
}

/* -------------RWD--------------- */
@media (max-width: 1440px) {
  #page-index .sec-clients {
    padding: 100px 0 60px;
  }
  #page-index .sec-clients h2 {
    margin-bottom: 60px;
  }
}
@media (max-width: 1280px) {
  #page-index .btn-container {
    margin-bottom: 25px;
  }
  #page-index .sec-hero .canvas-container {
    width: 1024px;
    height: 576px;
    max-height: 100vh;
    padding-bottom: 0;
    margin: 0 auto;
  }
  #page-index .sec-hero canvas {
    width: 124%;
    margin-left: -12%;
    object-fit: cover;
  }
  #page-index .sec-intro p {
    font-size: 2.89vw;
    line-height: 1.1892;
    letter-spacing: -0.027em;
  }
  #page-index .sec-intro p br {
    margin-top: 0.216em;
  }
  #page-index .sec-clients h2 {
    font-size: 1.75em;
    line-height: 1.3571em;
  }
}
@media (max-width: 1024px) {
  #page-index .sec-intro p {
    font-size: 2.9297vw;
  }
  #page-index .sec-reels .vid-container {
    width: 177.7777vh;
    height: 100%;
    margin-left: calc((177.7777vh - 100vw) / -2);
  }
  #page-index .sec-reels.is-playing .vid-container {
    width: 100%;
    margin-left: 0;
  }
  #page-index .sec-clients h2 {
    margin-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  #page-index .btn-container img {
    margin-top: 0.6em;
  }
  #page-index .sec-hero .wrapper {
    width: 100%;
    margin-inline: 0;
  }
  #page-index .sec-clients .swiper {
    padding-inline: 25px;
  }
}
@media (max-width: 768px) {
  #page-index .scroller {
    height: 200vh !important;
  }
  #page-index .sec-hero .wrapper {
    width: 140%;
    margin-inline: -20%;
  }
  #page-index .sec-intro .container {
    text-align: left;
    align-items: flex-start;
  }
  #page-index .sec-intro p {
    max-width: 16em;
    font-size: 5.4686vw;
    line-height: 1.1952;
    margin: 0.2976em 0;
  }
  #page-index .sec-intro p br {
    margin-top: 0.5952em;
  }
  #page-index .sec-clients {
    padding-top: 80px;
  }
  #page-index .sec-clients h2 {
    font-size: 1.5625em;
    line-height: 1.28em;
  }
  #page-index .sec-clients .swiper {
    padding: 0;
  }
}
@media (max-width: 414px) {
  #loader .sprite-container {
    width: 120px;
    height: 120px;
  }
  #page-index .sec-hero .wrapper {
    width: 200%;
    margin-inline: -50%;
  }
  #page-index .sec-hero .vid-container {
    padding-bottom: 64.25%;
  }
  #page-index .sec-intro p {
    font-size: 7.4vw;
    letter-spacing: -0.03125em;
    margin: 0.3906em 0;
  }
  #page-index .sec-intro p br {
    margin-top: 0.7812em;
  }
  #page-index .sec-featured {
    padding-top: 100px;
  }
  #page-index .sec-clients {
    padding: 60px 0 50px;
  }
  #page-index .sec-clients h2 {
    font-size: 1.375em;
    line-height: 1.3182em;
  }
  #page-index .sec-clients .swiper {
    width: 110%;
    margin-inline: -5%;
  }
}
@media (max-width: 360px) {
  #page-index .sec-intro p {
    letter-spacing: -0.004em;
  }
}
.sec-more {
  background: #ffffff;
  position: relative;
  overflow: hidden;
  padding: 40px 0 100px;
}
.sec-more h2 {
  margin-bottom: 60px;
}
.sec-more .work-item, .sec-more .news-item {
  margin-bottom: 40px;
}
.sec-more .news-item {
  margin-bottom: 0;
}

#swiper-more {
  padding: 0 24px;
  margin: 0 -24px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}
#swiper-more:before, #swiper-more:after {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: inherit;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 2;
}
#swiper-more:before {
  left: 0;
}
#swiper-more:after {
  right: 0;
}
#swiper-more .swiper-button-prev,
#swiper-more .swiper-button-next {
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  top: 38%;
  margin: auto -20px;
}
#swiper-more .swiper-button-prev:after,
#swiper-more .swiper-button-next:after {
  opacity: 0;
  pointer-events: all;
  transition: opacity 0.25s, transform 0.4s;
}
#swiper-more .swiper-button-prev.swiper-button-disabled:after,
#swiper-more .swiper-button-next.swiper-button-disabled:after {
  opacity: 0;
  pointer-events: none;
}
#swiper-more .swiper-button-prev:after {
  transform: translate(100%, 0);
}
#swiper-more .swiper-button-next:after {
  transform: translate(-100%, 0);
}
#swiper-more:hover .swiper-button-prev:after,
#swiper-more:hover .swiper-button-next:after, #swiper-more:focus-within .swiper-button-prev:after,
#swiper-more:focus-within .swiper-button-next:after {
  opacity: 1;
  transform: translate(0, 0);
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .sec-more h2 {
    font-size: 8.2031vw;
  }
}
@media (max-width: 768px) {
  .sec-more {
    padding: 20px 0 60px;
  }
  .sec-more .title-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .sec-more h2 {
    font-size: 2.375em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.3158;
    font-size: 1.6875em;
    letter-spacing: 0;
    line-height: 1.3704;
    margin-bottom: 0;
  }
  .sec-more .btn-container {
    margin-right: 0;
  }
  .sec-more .swiper-button-m-prev,
.sec-more .swiper-button-m-next {
    padding: 0 15px;
    margin-right: -15px;
  }
  .sec-more .swiper-button-m-prev.swiper-button-disabled,
.sec-more .swiper-button-m-next.swiper-button-disabled {
    opacity: 0.2;
  }
  .sec-more .swiper-button-m-prev img,
.sec-more .swiper-button-m-next img {
    width: 1.25em;
    height: 1.25em;
  }
  .sec-more .work-item, .sec-more .news-item {
    margin-bottom: 0;
  }
  #swiper-more {
    padding: 0;
    margin: 0;
  }
  #swiper-more:before, #swiper-more:after {
    display: none;
  }
}
@media (max-width: 414px) {
  .sec-more {
    padding-bottom: 80px;
  }
  .sec-more .swiper-button-next,
.sec-more .swiper-button-prev {
    width: calc(50% - 5px);
  }
}
.sec-featured {
  padding-top: 30px;
  transition: all 0.6s ease;
  position: relative;
  z-index: 2;
}
.sec-featured h2 {
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 80px;
}

.sec-list {
  padding-top: 30px;
}
.sec-list .work-item, .sec-list .news-item {
  margin-bottom: 80px;
}
.sec-list .work-item:nth-child(n), .sec-list .news-item:nth-child(n) {
  grid-column: auto/span 6;
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .sec-featured h2 {
    font-size: 1.75em;
    line-height: 1.3571;
    letter-spacing: 0;
  }
  .sec-list .work-item, .sec-list .news-item {
    margin-bottom: 60px;
  }
}
@media (max-width: 1024px) {
  .sec-featured h2 {
    font-size: 1.375em;
    line-height: 1.4545;
  }
}
@media (max-width: 1023px) {
  .sec-featured h2 {
    margin-top: 0;
  }
  .sec-list {
    padding-top: 80px;
  }
}
@media (max-width: 414px) {
  .sec-featured {
    padding-top: 80px;
  }
  .sec-featured h2 {
    font-size: 2em;
    font-weight: 500;
    line-height: 1.3125;
    letter-spacing: 0;
    font-size: 1.5625em;
    font-weight: 500;
    line-height: 1.28;
    margin-bottom: 80px;
  }
}
@media (max-width: 360px) {
  .sec-featured h2 {
    font-size: 6.88vw;
    line-height: 1.3182;
    letter-spacing: 0;
  }
}
.sec-main-title.title-work, .sec-main-title.title-news {
  padding-top: 10px;
}

#page-work-article {
  padding-top: 0 !important;
}
#page-work-article #header-position {
  position: fixed !important;
}
#page-work-article #header-position #header {
  background: transparent;
}
#page-work-article #header-position #header.is-shrink {
  background: white;
}
#page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) {
  color: white;
}
#page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) #header-logo svg path,
#page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) #trigger-search svg path {
  fill: white;
}
#page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) #hamburger:before, #page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) #hamburger:after,
#page-work-article #header-position.is-white:not(.is-open) #header:not(.is-shrink) #hamburger span {
  background: white;
}
#page-work-article #header-position.is-white #header.animate-out {
  color: white;
}
#page-work-article #header-position.is-white #header.animate-out #header-logo svg path,
#page-work-article #header-position.is-white #header.animate-out #trigger-search svg path {
  fill: white;
}
#page-work-article #header-position.is-white #header.animate-out #hamburger:before, #page-work-article #header-position.is-white #header.animate-out #hamburger:after,
#page-work-article #header-position.is-white #header.animate-out #hamburger span {
  background: white;
}
#page-work-article #header-position.is-open {
  background: white;
}
#page-work-article .sec-more {
  padding-bottom: 60px;
}
#page-work-article .sec-more h2 {
  margin-bottom: 30px;
}

.sec-work .bg-container {
  background: #ffffff;
  color: #000000;
  position: relative;
  z-index: 2;
}
.sec-work .bg-container h2,
.sec-work .bg-container h3,
.sec-work .bg-container p,
.sec-work .bg-container a {
  color: inherit;
}
.sec-work .module-hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.sec-work .module-hero img,
.sec-work .module-hero video,
.sec-work .module-hero .vid-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
}
.sec-work .module-hero iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sec-work .module-intro, .sec-work .module-credit {
  padding: 100px 0 160px;
  background: #ffffff;
  position: relative;
  z-index: 2;
}
.sec-work .module-intro .switch-container, .sec-work .module-credit .switch-container {
  margin-top: 0;
  margin-bottom: 60px;
}
.sec-work .module-intro .content h2, .sec-work .module-credit .content h2 {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.0133em;
  line-height: 1;
  letter-spacing: 0.08em;
  margin-bottom: 16px;
  font-weight: 600;
}
.sec-work .module-intro .content h3, .sec-work .module-credit .content h3 {
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 1.4286;
  letter-spacing: 0.0286em;
  font-weight: 400;
  margin-bottom: 80px;
}
.sec-work .module-intro .content p, .sec-work .module-credit .content p {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #404040;
}
.sec-work .module-intro .content.lang-zh p, .sec-work .module-credit .content.lang-zh p, .sec-work .module-intro .content.lang-jp p, .sec-work .module-credit .content.lang-jp p {
  text-align: justify;
}
.sec-work .module-intro .content.lang-en p, .sec-work .module-credit .content.lang-en p {
  letter-spacing: 0.01em;
  line-height: 1.75;
}
.sec-work .module-intro .info, .sec-work .module-credit .info {
  display: flex;
  justify-content: flex-end;
}
.sec-work .module-intro .info ul, .sec-work .module-credit .info ul {
  width: 178px;
  margin-left: 24px;
}
.sec-work .module-intro .info li, .sec-work .module-credit .info li {
  margin-bottom: 20px;
}
.sec-work .module-intro .info h4, .sec-work .module-credit .info h4 {
  font-weight: bold;
  margin-bottom: 6px;
}
.sec-work .module-intro .info p, .sec-work .module-credit .info p {
  color: #808080;
}
.sec-work .module-intro .info .list-related p, .sec-work .module-credit .info .list-related p,
.sec-work .module-intro .info .list-related a,
.sec-work .module-credit .info .list-related a {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5714;
  letter-spacing: 0.0143em;
  color: #808080;
  margin-bottom: 6px;
}
.sec-work .module-intro .info .list-related p a, .sec-work .module-credit .info .list-related p a {
  margin-bottom: 0;
}
.sec-work .module-intro .info .list-related div > br, .sec-work .module-credit .info .list-related div > br {
  display: none;
}
.sec-work .module-intro .info a, .sec-work .module-credit .info a {
  display: block;
  color: #808080;
  text-decoration: underline;
  margin-bottom: 6px;
}
.sec-work .module-intro .info a:hover, .sec-work .module-credit .info a:hover, .sec-work .module-intro .info a:focus-visible, .sec-work .module-credit .info a:focus-visible {
  color: #000000;
}
.sec-work .module-txt, .sec-work .module-quote {
  padding: 160px 0;
}
.sec-work .module-txt h2, .sec-work .module-quote h2 {
  margin-bottom: 14px;
}
.sec-work .module-txt p, .sec-work .module-quote p {
  font-weight: 400;
  text-align: justify;
}
.sec-work .module-txt .link-underline, .sec-work .module-quote .link-underline {
  margin-top: 30px;
  font-weight: 400;
}
.sec-work .module-txt .link-underline:hover, .sec-work .module-quote .link-underline:hover, .sec-work .module-txt .link-underline:focus-visible, .sec-work .module-quote .link-underline:focus-visible {
  color: #808080 !important;
}
.sec-work .module-txt.lang-en h2, .sec-work .lang-en.module-quote h2 {
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 20px;
}
.sec-work .module-txt.lang-en p, .sec-work .lang-en.module-quote p {
  letter-spacing: 0.01em;
  line-height: 1.75;
  text-align: left;
}
.sec-work .module-txt.lang-en .link-underline, .sec-work .lang-en.module-quote .link-underline {
  font-weight: 500;
}
.sec-work .module-txt + .module-img, .sec-work .module-quote + .module-img,
.sec-work .module-txt + .module-img-left,
.sec-work .module-quote + .module-img-left,
.sec-work .module-txt + .module-img-right,
.sec-work .module-quote + .module-img-right,
.sec-work .module-txt + .module-img-col2,
.sec-work .module-quote + .module-img-col2,
.sec-work .module-txt + .module-carousel,
.sec-work .module-quote + .module-carousel,
.sec-work .module-txt + .module-compare,
.sec-work .module-quote + .module-compare {
  padding-top: 0;
}
.sec-work .module-quote .name {
  margin-top: 20px;
  font-weight: 400;
  text-align: left;
}
.sec-work .module-quote.lang-en p {
  line-height: 1.75;
}
.sec-work .module-quote.lang-en .name {
  font-weight: 500;
  letter-spacing: 0.0111em;
}
.sec-work .module-img,
.sec-work .module-img-left,
.sec-work .module-img-right,
.sec-work .module-img-col2,
.sec-work .module-img-col3,
.sec-work .module-img-col12,
.sec-work .module-carousel,
.sec-work .module-compare,
.sec-work .module-full-carousel {
  padding: 30px 0;
}
.sec-work .module-img + .module-txt,
.sec-work .module-img + .module-quote,
.sec-work .module-img-left + .module-txt,
.sec-work .module-img-left + .module-quote,
.sec-work .module-img-right + .module-txt,
.sec-work .module-img-right + .module-quote,
.sec-work .module-img-col2 + .module-txt,
.sec-work .module-img-col2 + .module-quote,
.sec-work .module-img-col3 + .module-txt,
.sec-work .module-img-col3 + .module-quote,
.sec-work .module-img-col12 + .module-txt,
.sec-work .module-img-col12 + .module-quote,
.sec-work .module-carousel + .module-txt,
.sec-work .module-carousel + .module-quote,
.sec-work .module-compare + .module-txt,
.sec-work .module-compare + .module-quote,
.sec-work .module-full-carousel + .module-txt,
.sec-work .module-full-carousel + .module-quote {
  margin-top: -30px;
}
.sec-work .module-img + .module-img-full,
.sec-work .module-img + .module-full-carousel,
.sec-work .module-img + .module-compare,
.sec-work .module-img-left + .module-img-full,
.sec-work .module-img-left + .module-full-carousel,
.sec-work .module-img-left + .module-compare,
.sec-work .module-img-right + .module-img-full,
.sec-work .module-img-right + .module-full-carousel,
.sec-work .module-img-right + .module-compare,
.sec-work .module-img-col2 + .module-img-full,
.sec-work .module-img-col2 + .module-full-carousel,
.sec-work .module-img-col2 + .module-compare,
.sec-work .module-img-col3 + .module-img-full,
.sec-work .module-img-col3 + .module-full-carousel,
.sec-work .module-img-col3 + .module-compare,
.sec-work .module-img-col12 + .module-img-full,
.sec-work .module-img-col12 + .module-full-carousel,
.sec-work .module-img-col12 + .module-compare,
.sec-work .module-carousel + .module-img-full,
.sec-work .module-carousel + .module-full-carousel,
.sec-work .module-carousel + .module-compare,
.sec-work .module-compare + .module-img-full,
.sec-work .module-compare + .module-full-carousel,
.sec-work .module-compare + .module-compare,
.sec-work .module-full-carousel + .module-img-full,
.sec-work .module-full-carousel + .module-full-carousel,
.sec-work .module-full-carousel + .module-compare {
  padding-top: 30px;
}
.sec-work .module-compare {
  padding-top: 0;
}
.sec-work .module-img-full + .module-img,
.sec-work .module-img-full + .module-img-left,
.sec-work .module-img-full + .module-img-right,
.sec-work .module-img-full + .module-img-col2,
.sec-work .module-img-full + .module-img-col3,
.sec-work .module-img-full + .module-img-col12,
.sec-work .module-img-full + .module-carousel,
.sec-work .module-img-full + .module-compare,
.sec-work .module-full-carousel + .module-img,
.sec-work .module-full-carousel + .module-img-left,
.sec-work .module-full-carousel + .module-img-right,
.sec-work .module-full-carousel + .module-img-col2,
.sec-work .module-full-carousel + .module-img-col3,
.sec-work .module-full-carousel + .module-img-col12,
.sec-work .module-full-carousel + .module-carousel,
.sec-work .module-full-carousel + .module-compare,
.sec-work .module-compare + .module-img,
.sec-work .module-compare + .module-img-left,
.sec-work .module-compare + .module-img-right,
.sec-work .module-compare + .module-img-col2,
.sec-work .module-compare + .module-img-col3,
.sec-work .module-compare + .module-img-col12,
.sec-work .module-compare + .module-carousel,
.sec-work .module-compare + .module-compare {
  padding-top: 60px;
}
.sec-work .module-carousel {
  background: inherit;
  position: relative;
  overflow: hidden;
}
.sec-work .module-carousel .swiper-carousel {
  position: relative;
}
.sec-work .module-carousel .swiper-slide {
  background: inherit;
  padding: 0 calc((100vw - 120px + 24px) / 12 / 2);
}
.sec-work .module-carousel .swiper-button-next,
.sec-work .module-carousel .swiper-container-rtl .swiper-button-prev,
.sec-work .module-carousel .swiper-button-prev,
.sec-work .module-carousel .swiper-container-rtl .swiper-button-next {
  margin: 0 calc((100vw - 120px + 24px) / 12 / 4);
}
.sec-work .module-full-carousel {
  background: inherit;
  position: relative;
  overflow: hidden;
}
.sec-work .module-full-carousel .full-swiper-carousel {
  position: relative;
  overflow: hidden;
}
.sec-work .module-full-carousel .swiper-slide {
  display: block;
  position: relative;
}
.sec-work .module-full-carousel .swiper-slide:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.4s ease;
}
.sec-work .module-full-carousel .swiper-slide img {
  object-fit: cover;
}
.sec-work .module-full-carousel .swiper-controls {
  margin-top: 10px;
}
.sec-work .module-full-carousel .swiper-pagination {
  top: 0;
  margin-top: 0;
}
.sec-work .module-full-carousel .swiper-button-prev,
.sec-work .module-full-carousel .swiper-button-next {
  display: block;
  width: auto;
  height: auto;
  position: relative;
  mix-blend-mode: unset;
  padding-left: 15px;
}
.sec-work .module-full-carousel .swiper-button-prev img,
.sec-work .module-full-carousel .swiper-button-next img {
  width: 16px;
}
.sec-work .module-compare .compare-container {
  position: relative;
  text-align: center;
}
.sec-work .module-compare figure {
  position: relative;
  overflow: hidden;
}
.sec-work .module-compare figure img {
  transition: opacity 0.6s ease;
}
.sec-work .module-compare figure .img-after {
  position: absolute;
  top: 0;
  left: 0;
}
.sec-work .module-compare .compare-switch {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 20px;
  z-index: 3;
}
.sec-work .module-compare .switch-container {
  margin: 0;
}
.sec-work .module-compare .switch button {
  width: 4em;
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  color: #B9B9B9;
}
.sec-work .module-credit {
  padding: 60px 0 100px !important;
}
.sec-work .module-credit .container {
  position: relative;
}
.sec-work .module-credit h2 {
  text-transform: none;
  margin-bottom: 30px;
}
.sec-work .module-credit .info {
  display: grid;
}
.sec-work .module-credit .info ul {
  width: auto;
  margin-left: 0;
}
.sec-work .module-credit .info li:last-child {
  margin-bottom: 0;
}
.sec-work .module-credit .scroll-top-container {
  height: 0;
}
.sec-work .is-blank video,
.sec-work .is-blank img {
  display: none;
}
.sec-work #scroll-top {
  width: 60px;
  height: 60px;
  transition: transform 0.25s;
}
.sec-work #scroll-top:hover, .sec-work #scroll-top:focus-visible {
  transform: translate(0, -4px);
}
.sec-work #scroll-top:active {
  transform: translate(0, -2px);
}

/* -------------RWD--------------- */
@media (min-width: 2042px) {
  .sec-work .module-intro .info ul, .sec-work .module-credit .info ul {
    width: 230px;
  }
}
@media (max-width: 1440px) {
  .sec-work .module-intro .content h3, .sec-work .module-credit .content h3 {
    margin-bottom: 60px;
  }
  .sec-work .module-txt, .sec-work .module-quote {
    padding: 140px 0;
  }
  .sec-work .module-txt .content, .sec-work .module-quote .content {
    grid-column: auto/span 8;
    grid-column-start: 3;
  }
  #page-work-article .sec-more h2 {
    font-size: 6.5625em;
    line-height: 1;
    letter-spacing: -0.0381em;
  }
}
@media (max-width: 1280px) {
  .sec-main-title.title-work, .sec-main-title.title-news {
    padding-top: 20px;
  }
  .sec-work .module-intro, .sec-work .module-credit {
    padding: 40px 0 60px;
  }
  .sec-work .module-intro .switch-container, .sec-work .module-credit .switch-container {
    margin: 0 0 35px 0;
  }
  .sec-work .module-intro .content, .sec-work .module-credit .content {
    grid-column: auto/span 12;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;
  }
  .sec-work .module-intro .content h3, .sec-work .module-credit .content h3 {
    margin-bottom: 40px;
  }
  .sec-work .module-intro .info, .sec-work .module-credit .info {
    grid-column: auto/span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 24px;
  }
  .sec-work .module-intro .info ul, .sec-work .module-credit .info ul {
    width: auto;
    grid-column: auto/span 6;
    margin-left: 0;
  }
  .sec-work .module-intro .info li, .sec-work .module-credit .info li {
    margin-bottom: 15px;
  }
  .sec-work .module-txt, .sec-work .module-quote {
    padding: 120px 0;
  }
  .sec-work .module-txt .content, .sec-work .module-quote .content {
    grid-column: auto/span 10;
    grid-column-start: 2;
  }
  .sec-work .module-img,
.sec-work .module-img-left,
.sec-work .module-img-right,
.sec-work .module-img-col2,
.sec-work .module-img-col3,
.sec-work .module-img-col12 {
    padding: 20px 0;
  }
  .sec-work .module-img + .module-txt,
.sec-work .module-img + .module-quote,
.sec-work .module-img-left + .module-txt,
.sec-work .module-img-left + .module-quote,
.sec-work .module-img-right + .module-txt,
.sec-work .module-img-right + .module-quote,
.sec-work .module-img-col2 + .module-txt,
.sec-work .module-img-col2 + .module-quote,
.sec-work .module-img-col3 + .module-txt,
.sec-work .module-img-col3 + .module-quote,
.sec-work .module-img-col12 + .module-txt,
.sec-work .module-img-col12 + .module-quote {
    margin-top: -20px;
  }
  .sec-work .module-img-left .g-col-8,
.sec-work .module-img-right .g-col-8 {
    grid-column: auto/span 12;
    grid-column-start: 0;
  }
  .sec-work .module-img-col2 .grid {
    grid-row-gap: 20px;
  }
  .sec-work .module-img-col2 .g-col-6 {
    grid-column: auto/span 12;
    margin-bottom: 0;
  }
  .sec-work .module-img-col2 .g-col-6.is-blank {
    display: none;
  }
  .sec-work .module-img-col3 .grid {
    grid-row-gap: 20px;
  }
  .sec-work .module-img-col3 .g-col-4 {
    grid-column: auto/span 12;
    margin-bottom: 0;
  }
  .sec-work .module-img-col3 .g-col-4.is-blank {
    display: none;
  }
  .sec-work .module-img-col12 .g-col-4,
.sec-work .module-img-col12 .g-col-8 {
    grid-column: auto/span 12;
    margin-bottom: 20px;
  }
  .sec-work .module-img-col12 .g-col-4:last-child,
.sec-work .module-img-col12 .g-col-8:last-child {
    margin-bottom: 0;
  }
  .sec-work .module-carousel .swiper-slide {
    padding: 0 5px;
  }
  .sec-work .module-compare .g-col-10 {
    grid-column: auto/span 12;
    grid-column-start: 0;
  }
  .sec-work .module-compare .compare-switch {
    position: relative;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .sec-work .module-compare .switch button {
    font-size: 0.8125em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    color: #B9B9B9;
  }
  .sec-work .module-credit {
    padding: 40px 0 100px !important;
  }
  .sec-work .module-credit .info h4 {
    margin-bottom: 4px;
  }
  #page-work-article .sec-more h2 {
    font-size: 8.2031vw;
  }
}
@media (max-width: 1024px) {
  .sec-work .module-txt, .sec-work .module-quote {
    padding: 100px 0;
  }
  .sec-work .module-txt .content, .sec-work .module-quote .content {
    grid-column: auto/span 12;
    grid-column-start: 0;
  }
  .sec-work .module-credit {
    padding: 80px 0 !important;
  }
  .sec-work .module-credit li {
    margin-bottom: 20px;
  }
  .sec-work #scroll-top {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1023px) {
  .sec-work .module-txt, .sec-work .module-quote {
    padding: 80px 0;
  }
  .sec-work .module-carousel .swiper-width {
    grid-column: auto/span 6;
    grid-column-start: 1;
  }
}
@media (max-width: 768px) {
  .sec-main-title.title-work, .sec-main-title.title-news {
    padding-top: 54px;
  }
  .sec-work .module-hero {
    height: 75vw;
  }
  .sec-work .module-hero img,
.sec-work .module-hero video,
.sec-work .module-hero .vid-container {
    height: 75vw;
  }
  .sec-work .module-hero iframe {
    min-height: 75vw;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  }
  .sec-work .module-intro .content h2, .sec-work .module-credit .content h2 {
    font-size: 0.75em;
    letter-spacing: 0.05714em;
    line-height: 1.5714;
    margin-bottom: 8px;
  }
  .sec-work .module-intro .content h3, .sec-work .module-credit .content h3 {
    font-size: 1.625em;
    font-weight: 600;
    line-height: 1.3077;
    letter-spacing: 0;
    font-weight: 400;
  }
  .sec-work .module-intro .content p, .sec-work .module-credit .content p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
    letter-spacing: 0.0125em;
  }
  .sec-work .module-txt h2, .sec-work .module-quote h2 {
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 1.6667;
    letter-spacing: 0.0533em;
    font-weight: 500;
  }
  .sec-work .module-txt p, .sec-work .module-quote p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
    letter-spacing: 0.0125em;
    font-weight: 300;
  }
  .sec-work .module-txt .link-underline, .sec-work .module-quote .link-underline {
    margin-top: 15px;
  }
  .sec-work .module-txt.font-en p, .sec-work .font-en.module-quote p {
    font-weight: 400;
  }
  .sec-work .module-quote .name {
    letter-spacing: 0.05em;
  }
  .sec-work .module-quote.font-en .name {
    letter-spacing: 0.01333em;
  }
  .sec-work .module-credit {
    padding: 60px 0 100px !important;
  }
  #page-work-article .sec-more .title-container {
    margin-bottom: 20px;
  }
  #page-work-article .sec-more h2 {
    font-size: 2.375em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.3158;
    font-size: 1.6875em;
    letter-spacing: 0;
    line-height: 1.3704;
    margin-bottom: 0;
  }
}
@media (max-width: 414px) {
  .sec-main-title.title-work, .sec-main-title.title-news {
    padding-top: 60px;
  }
  .sec-main-title.title-work h2, .sec-main-title.title-news h2 {
    margin-bottom: 15px;
  }
  #page-work-article .sec-more {
    padding-bottom: 40px;
  }
  .sec-work .module-txt h2, .sec-work .module-quote h2 {
    margin-bottom: 15px;
  }
  .sec-work .module-credit {
    padding-bottom: 60px !important;
  }
  .sec-work .module-img,
.sec-work .module-img-left,
.sec-work .module-img-right,
.sec-work .module-img-col2,
.sec-work .module-img-col3,
.sec-work .module-img-col12,
.sec-work .module-carousel,
.sec-work .module-compare,
.sec-work .module-full-carousel {
    padding: 9px 0;
  }
  .sec-work .module-img + .module-txt,
.sec-work .module-img + .module-quote,
.sec-work .module-img-left + .module-txt,
.sec-work .module-img-left + .module-quote,
.sec-work .module-img-right + .module-txt,
.sec-work .module-img-right + .module-quote,
.sec-work .module-img-col2 + .module-txt,
.sec-work .module-img-col2 + .module-quote,
.sec-work .module-img-col3 + .module-txt,
.sec-work .module-img-col3 + .module-quote,
.sec-work .module-img-col12 + .module-txt,
.sec-work .module-img-col12 + .module-quote,
.sec-work .module-carousel + .module-txt,
.sec-work .module-carousel + .module-quote,
.sec-work .module-compare + .module-txt,
.sec-work .module-compare + .module-quote,
.sec-work .module-full-carousel + .module-txt,
.sec-work .module-full-carousel + .module-quote {
    margin-top: -9px;
  }
  .sec-work .module-img + .module-img-full,
.sec-work .module-img + .module-full-carousel,
.sec-work .module-img + .module-compare,
.sec-work .module-img-left + .module-img-full,
.sec-work .module-img-left + .module-full-carousel,
.sec-work .module-img-left + .module-compare,
.sec-work .module-img-right + .module-img-full,
.sec-work .module-img-right + .module-full-carousel,
.sec-work .module-img-right + .module-compare,
.sec-work .module-img-col2 + .module-img-full,
.sec-work .module-img-col2 + .module-full-carousel,
.sec-work .module-img-col2 + .module-compare,
.sec-work .module-img-col3 + .module-img-full,
.sec-work .module-img-col3 + .module-full-carousel,
.sec-work .module-img-col3 + .module-compare,
.sec-work .module-img-col12 + .module-img-full,
.sec-work .module-img-col12 + .module-full-carousel,
.sec-work .module-img-col12 + .module-compare,
.sec-work .module-carousel + .module-img-full,
.sec-work .module-carousel + .module-full-carousel,
.sec-work .module-carousel + .module-compare,
.sec-work .module-compare + .module-img-full,
.sec-work .module-compare + .module-full-carousel,
.sec-work .module-compare + .module-compare,
.sec-work .module-full-carousel + .module-img-full,
.sec-work .module-full-carousel + .module-full-carousel,
.sec-work .module-full-carousel + .module-compare {
    padding-top: 9px;
  }
  .sec-work .module-compare {
    padding-top: 0;
  }
  .sec-work .module-img-full + .module-img,
.sec-work .module-img-full + .module-img-left,
.sec-work .module-img-full + .module-img-right,
.sec-work .module-img-full + .module-img-col2,
.sec-work .module-img-full + .module-img-col3,
.sec-work .module-img-full + .module-img-col12,
.sec-work .module-img-full + .module-carousel,
.sec-work .module-img-full + .module-compare,
.sec-work .module-full-carousel + .module-img,
.sec-work .module-full-carousel + .module-img-left,
.sec-work .module-full-carousel + .module-img-right,
.sec-work .module-full-carousel + .module-img-col2,
.sec-work .module-full-carousel + .module-img-col3,
.sec-work .module-full-carousel + .module-img-col12,
.sec-work .module-full-carousel + .module-carousel,
.sec-work .module-full-carousel + .module-compare,
.sec-work .module-compare + .module-img,
.sec-work .module-compare + .module-img-left,
.sec-work .module-compare + .module-img-right,
.sec-work .module-compare + .module-img-col2,
.sec-work .module-compare + .module-img-col3,
.sec-work .module-compare + .module-img-col12,
.sec-work .module-compare + .module-carousel,
.sec-work .module-compare + .module-compare {
    padding-top: 18px;
  }
  .sec-work .module-img-col2 .grid,
.sec-work .module-img-col3 .grid {
    grid-row-gap: 18px;
  }
}
.sec-news-list {
  padding-top: 30px;
  transition: all 0.6s ease;
  position: relative;
  z-index: 2;
}
.sec-news-list h2 {
  max-width: 15em;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 80px;
}
.sec-news-list .grid-highlight .news-item .content h3 {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
  line-height: 1.9;
  text-transform: none;
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .sec-news-list .grid-highlight .g-col-4 {
    grid-column: auto/span 5;
  }
  .sec-news-list .grid-highlight .g-col-8 {
    grid-column: auto/span 7;
    grid-column-start: 6;
  }
  .sec-news-list h2 {
    font-size: 1.75em;
    line-height: 1.3571;
    letter-spacing: 0;
  }
}
@media (max-width: 1024px) {
  .sec-news-list h2 {
    font-size: 1.375em;
    line-height: 1.4545;
  }
}
@media (max-width: 1023px) {
  .sec-news-list .grid-highlight .g-col-4 {
    grid-column: auto/span 6;
  }
  .sec-news-list .grid-highlight .g-col-8 {
    grid-column: auto/span 6;
    grid-column-start: 1;
  }
  .sec-news-list h2 {
    font-size: 1.5625em;
    margin-top: 0;
    max-width: none;
  }
  .sec-news-list h2 nobr:last-child {
    display: block;
  }
}
@media (max-width: 414px) {
  .sec-news-list {
    padding-top: 80px;
  }
  .sec-news-list h2 {
    font-size: 2em;
    font-weight: 500;
    line-height: 1.3125;
    letter-spacing: 0;
    font-size: 1.5625em;
    font-weight: 500;
    line-height: 1.28;
    margin-bottom: 80px;
  }
}
@media (max-width: 360px) {
  .sec-news-list h2 {
    font-size: 6.48vw;
    line-height: 1.3182;
  }
}
.sec-news {
  padding-bottom: 120px;
}
.sec-news .module-title {
  padding: 70px 0 80px;
}
.sec-news .module-title .date {
  margin-bottom: 30px;
}
.sec-news .module-title .date b {
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
  font-weight: bold;
}
.sec-news .module-title .date time {
  display: inline-block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.0143em;
  font-weight: 300;
  margin-left: 10px;
}
.sec-news .module-title h2 {
  margin-bottom: 30px;
}
.sec-news .module-title .description {
  font-weight: 400;
}
.sec-news .module-carousel-news {
  margin-bottom: 100px;
}
.sec-news .module-carousel-news .swiper-width {
  background: #ffffff;
  padding: 0 24px;
  margin: 0 -24px;
  position: relative;
}
.sec-news .module-carousel-news .swiper-container {
  position: relative;
  overflow: hidden;
}
.sec-news .module-carousel-news .swiper-slide img {
  aspect-ratio: 1.5;
  object-fit: cover;
}
.sec-news .module-carousel-news .swiper-button-next:after,
.sec-news .module-carousel-news .swiper-button-prev:after {
  margin: 0 -20px;
}
.sec-news .module-carousel-news .swiper-pagination {
  top: 0;
}
.sec-news .module-article {
  position: relative;
}
.sec-news .module-content {
  margin-bottom: 60px;
}
.sec-news .module-content h3 {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 1.125em;
}
.sec-news .module-content > div,
.sec-news .module-content p {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
  letter-spacing: 0.05em;
}
.sec-news .module-content a.link-arrow {
  margin-top: 40px;
}
.sec-news .module-content a:not(.link-arrow) {
  text-decoration: underline;
  text-underline-offset: 0.3em;
}
.sec-news .module-figure {
  margin-bottom: 60px;
}
.sec-news .module-figure figcaption {
  font-weight: 400;
  margin-top: 8px;
  line-height: 1.7333;
}
.sec-news .share-pos {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 60px;
  pointer-events: none;
}
.sec-news .share-container {
  position: sticky;
  top: 140px;
}
.sec-news .share-container a {
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 12px;
  border-radius: 25px;
  transition: background 0.25s ease;
  pointer-events: all;
  position: relative;
}
.sec-news .share-container a:hover, .sec-news .share-container a:focus-visible {
  background: #EEEEEE;
}
.sec-news .share-container a:hover .tooltip, .sec-news .share-container a:focus-visible .tooltip {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.sec-news .share-container .tooltip {
  font-size: 0.75em;
  line-height: 1;
  letter-spacing: 0.0666em;
  color: #B9B9B9;
  display: flex;
  align-items: center;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0 auto 12px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-6px, 0);
  transition: transform 0.25s, opacity 0.4s;
}
.sec-news .module-author {
  padding-top: 80px;
}
.sec-news .module-author .bg-light {
  padding: 40px;
  color: #444444;
}
.sec-news .module-author h3 {
  margin-bottom: 25px;
}
.sec-news .module-author h3 b {
  font-weight: 500;
}
.sec-news .module-author h3 span {
  display: inline-block;
  font-weight: 400;
  margin-left: 0.8em;
}
.sec-news .module-author p {
  letter-spacing: 0.05em;
}
.sec-news .scroll-top-container {
  height: 0;
}
.sec-news #scroll-top {
  width: 60px;
  height: 60px;
  transition: transform 0.25s;
}
.sec-news #scroll-top:hover, .sec-news #scroll-top:focus-visible {
  transform: translate(0, -4px);
}
.sec-news #scroll-top:active {
  transform: translate(0, -2px);
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .sec-news .module-title .title-container {
    grid-column: auto/span 9;
    grid-column-start: 2;
  }
  .sec-news .module-title .date {
    margin-bottom: 15px;
  }
  .sec-news .module-carousel-news {
    margin-bottom: 100px;
  }
  .sec-news .module-author {
    padding-top: 60px;
  }
  .sec-news .share-pos {
    margin-left: 40px;
  }
}
@media (max-width: 1024px) {
  .sec-news .module-author .bg-light {
    padding: 25px;
  }
  .sec-news #scroll-top {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1023px) {
  .sec-news .module-title .title-container {
    grid-column: auto/span 12;
    grid-column-start: 1;
  }
  .sec-news .module-title h2 {
    font-size: 1.625em;
    font-weight: 500;
    letter-spacing: 0.01538em;
    line-height: 1.6154;
    margin-bottom: 25px;
  }
  .sec-news .module-title p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
    font-weight: 400;
  }
  .sec-news .module-carousel-news .swiper-width {
    grid-column: auto/span 12;
    grid-column-start: 1;
  }
  .sec-news .module-article .g-col-8 {
    grid-column: auto/span 12;
    grid-column-start: 1;
  }
  .sec-news .scroll-top-container {
    margin-top: 70px;
    margin-bottom: -70px;
  }
  .sec-news .share-pos {
    height: auto;
    position: relative;
    margin-left: 25px;
    padding-top: 60px;
  }
  .sec-news .share-container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    top: 0;
  }
  .sec-news .share-container a {
    margin-right: 12px;
    margin-bottom: 0;
  }
  .sec-news .share-container .tooltip {
    display: none;
  }
}
@media (max-width: 768px) {
  .sec-news {
    padding-bottom: 60px;
  }
  .sec-news .module-content p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
    letter-spacing: 0.0125em;
  }
  .sec-news .module-carousel-news {
    position: relative;
    overflow: hidden;
  }
  .sec-news .module-carousel-news .swiper-button-prev,
.sec-news .module-carousel-news .swiper-button-next {
    width: 16px;
    height: 16px;
    position: absolute;
    margin: 0;
    top: auto;
    mix-blend-mode: unset;
  }
  .sec-news .module-carousel-news .swiper-button-prev:after,
.sec-news .module-carousel-news .swiper-button-next:after {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transform: none;
    background-size: contain;
  }
  .sec-news .module-carousel-news .swiper-button-prev {
    bottom: 0;
    left: auto;
    right: 55px;
  }
  .sec-news .module-carousel-news .swiper-button-prev:after {
    background-image: url(/assets/img/icon/arrow-left-black.svg);
  }
  .sec-news .module-carousel-news .swiper-button-next {
    bottom: 0;
    right: 0;
    margin-right: 24px;
  }
  .sec-news .module-carousel-news .swiper-button-next:after {
    background-image: url(/assets/img/icon/arrow-right-black.svg);
  }
}
@media (max-width: 414px) {
  .sec-news .module-title {
    padding: 40px 0;
  }
  .sec-news .share-pos {
    margin-left: 17px;
  }
}
#page-search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
@supports (height: 100dvh) {
  #page-search {
    min-height: 100dvh;
  }
}
#page-search > .vertical-align-center {
  margin-bottom: auto;
}
#page-search main {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.sec-search {
  padding-top: 190px;
}
.sec-search h2 {
  font-size: 3.875em;
  font-weight: 600;
  letter-spacing: -0.0323em;
  line-height: 1.1613;
  margin-bottom: 40px;
}
.sec-search .search-container {
  max-width: 768px;
  padding-bottom: 1px;
  margin: 0 auto;
  position: relative;
}
.sec-search .category-container,
.sec-search .result-container {
  animation-delay: 0s;
  animation-duration: 0.6s;
  animation-name: showFilter;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transition: height 0.6s ease;
  position: relative;
  overflow: hidden;
}
.sec-search .category-container.is-hidden,
.sec-search .result-container.is-hidden {
  max-height: 0;
  height: 0;
  padding: 0;
  animation: none;
}
.sec-search #search {
  display: block;
  width: 100%;
  padding: 3px 50px 8px 15px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.04em;
  border-bottom: 1px solid #000000;
  position: relative;
}
.sec-search #search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D0D0D0;
  opacity: 1; /* Firefox */
}
.sec-search #search:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}
.sec-search #search::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}
.sec-search #search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.sec-search #search.is-enabled ~ #search-reset {
  background-image: url(/assets/img/icon/close-black.svg);
}
.sec-search #search:focus {
  border-bottom-width: 2px;
  margin-bottom: -1px;
}
.sec-search #search-reset {
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0;
  color: transparent;
  position: absolute;
  top: 0;
  bottom: 15px;
  right: 18px;
  background: url(/assets/img/icon/close.svg) 0 0 no-repeat;
  background-size: contain;
  margin: auto 0;
  transition: background 0.25s;
}
.sec-search #search-reset:hover, .sec-search #search-reset:focus-visible {
  background-image: url(/assets/img/icon/close-black.svg);
}
.sec-search #filter-container {
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.6s ease;
  padding-inline: 0;
  margin-inline: -4px;
}
.sec-search #filter-container li {
  margin-inline: 4px;
}
.sec-search .result-container {
  padding: 30px 0;
}

#search-list .no-result {
  margin: 90px 0 120px;
}
#search-list .no-result p {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

/* -------------RWD--------------- */
@media (max-width: 1280px) {
  .sec-search {
    padding-top: 83px;
  }
  .sec-search h2 {
    font-size: 2.625em;
    line-height: 1.238;
    letter-spacing: -0.0385em;
  }
  .sec-search #search {
    font-size: 1em;
    line-height: 1.75;
    letter-spacing: 0.0125em;
  }
}
@media (max-width: 1024px) {
  .sec-search {
    padding-top: 115px;
  }
  .sec-search .category-container {
    padding: 25px 0 100px;
  }
  .sec-search .result-container {
    padding: 25px 0;
  }
}
@media (max-width: 1023px) {
  .sec-search .g-col-6.g-start-4 {
    grid-column: auto/span 4;
    grid-column-start: 2;
  }
}
@media (max-width: 768px) {
  .sec-search {
    padding-top: 74px;
  }
  .sec-search .g-col-6.g-start-4 {
    grid-column: auto/span 6;
    grid-column-start: 1;
  }
  #search-list {
    padding-top: 30px;
  }
}
@media (max-width: 414px) {
  .sec-search {
    padding-top: 80px;
  }
  .sec-search h2 {
    margin-bottom: 60px;
    font-size: 2.625em;
    font-weight: 600;
    letter-spacing: -0.0238em;
    line-height: 1.2;
    font-weight: bold;
  }
  #search-list .no-result {
    margin: 70px 0 100px;
  }
}
@media (max-width: 360px) {
  .sec-search {
    padding-top: 40px;
  }
  .sec-search h2 {
    font-size: 2em;
    margin-bottom: 40px;
  }
  .sec-search #filter-container {
    margin-inline: 0;
  }
}
.sec-main-title.title-about h2 {
  margin-bottom: 0;
}

.sec-awards, .sec-team, .sec-client-about, .sec-intro-about {
  padding: 80px 0 160px;
}
.sec-awards h2.font-en-h3, .sec-team h2.font-en-h3, .sec-client-about h2.font-en-h3, .sec-intro-about h2.font-en-h3 {
  margin-bottom: 80px;
}

.sec-intro-about h2 {
  margin-bottom: 60px;
}
.sec-intro-about p {
  margin-bottom: 1.3333em;
}
.sec-intro-about p:last-child {
  margin-bottom: 0;
}
.sec-intro-about p span {
  display: block;
  margin-bottom: 1.25em;
}
.sec-intro-about p span:last-child {
  margin-bottom: 0;
}
.sec-intro-about .title p {
  max-width: 15em;
}

.sec-client-about .logo-container {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-row-gap: 140px;
  justify-content: space-between;
  margin-top: 60px;
}
.sec-client-about .logo-container {
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10%;
}
.sec-client-about .logo {
  max-width: none;
  width: 100%;
}
.sec-client-about .logo {
  max-width: 240px;
  display: block;
}
.sec-client-about img {
  width: 100%;
  margin: 0 auto;
}

.sec-team .accordion-item.is-active .content {
  display: grid;
}
.sec-team .title {
  padding: 22px 0 21px;
}
.sec-team .name {
  padding-left: 24px;
}
.sec-team .content {
  padding: 24px 0 60px;
}
.sec-team .content figure {
  margin-left: 24px;
}
.sec-team .content figure img {
  max-width: 240px;
}
.sec-team .txt-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 48px;
  padding-right: 24px;
}

.sec-awards li > div,
.sec-awards li > a {
  padding: 22px 0;
  border-bottom: 2px solid #D0D0D0;
  transition: border 0.25s;
}
.sec-awards .year {
  padding-left: 24px;
}
.sec-awards a {
  position: relative;
}
.sec-awards a .description {
  padding-right: 50px;
}
.sec-awards a:after {
  content: "";
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/img/icon/arrow-right-black.svg) center no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  margin: auto 0;
  opacity: 0.3;
  transform: translate(-15px, 0);
  transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.73, 1.32), opacity 0.25s;
}
.sec-awards a:hover, .sec-awards a:focus-visible {
  border-color: #000000;
}
.sec-awards a:hover:after, .sec-awards a:focus-visible:after {
  opacity: 1;
  transform: translate(0, 0);
}
.sec-awards a:hover p, .sec-awards a:focus-visible p {
  color: #000000;
}
.sec-awards button {
  display: block;
  padding: 12px 24px;
  margin: 15px auto 0;
  transition: color 0.25s;
  position: relative;
}
.sec-awards button i {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
}
.sec-awards button i:before, .sec-awards button i:after {
  content: "";
  display: block;
  width: 0.75rem;
  height: 1px;
  background: #808080;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: transform 0.25s, background 0.25s;
}
.sec-awards button i:after {
  transform: rotate(90deg);
}
.sec-awards button:hover, .sec-awards button:focus-visible {
  color: #000000;
}
.sec-awards button:hover i:before, .sec-awards button:hover i:after, .sec-awards button:focus-visible i:before, .sec-awards button:focus-visible i:after {
  background: #000000;
}
.sec-awards button.is-hidden {
  display: none;
  visibility: none;
}

#list-awards {
  position: relative;
}
#list-awards.has-more:after {
  content: "";
  display: block;
  width: 100%;
  height: 152px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0), white);
  background-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0), white);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
  z-index: 2;
  user-select: none;
}

/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-team .title {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    grid-column-gap: 0;
  }
  .sec-team .name {
    flex-shrink: 0;
    width: 21.75rem;
    padding-left: 0;
    margin-inline: 24px;
  }
  .sec-team .position {
    width: 100%;
  }
  .sec-team .accordion-item.is-active .content {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    grid-column-gap: 0;
  }
  .sec-team figure {
    flex-shrink: 0;
    width: 23.25rem;
  }
  .sec-team .txt-container {
    width: 100%;
  }
  .sec-awards li > div,
.sec-awards li > a {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    grid-column-gap: 0;
  }
  .sec-awards .year {
    flex-shrink: 0;
    width: 5.25rem;
    padding-left: 0;
    margin-inline: 24px;
  }
  .sec-awards .name {
    flex-shrink: 0;
    width: 23.25rem;
    margin-right: 24px;
  }
  .sec-awards .description {
    width: 100%;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1440px) {
  .sec-intro-about .title p {
    max-width: 12em;
  }
  .sec-intro-about .font-en-h5 {
    font-size: 2.4306vw;
    line-height: 1.2;
    letter-spacing: -0.0571em;
  }
  .sec-intro-about .font-zh-h1 {
    font-size: 2.4306vw;
    line-height: 1.4;
    letter-spacing: 0.04286em;
  }
  .sec-client-about img {
    max-width: 200px;
  }
}
@media (max-width: 1280px) {
  .sec-intro-about, .sec-client-about, .sec-team, .sec-awards {
    padding: 60px 0 120px;
  }
  .sec-intro-about h2.font-en-h3, .sec-client-about h2.font-en-h3, .sec-team h2.font-en-h3, .sec-awards h2.font-en-h3 {
    font-size: 6em;
    line-height: 1;
    letter-spacing: -0.04167em;
    margin-bottom: 80px;
  }
  .sec-client-about .logo-container {
    grid-template-columns: repeat(5, auto);
    margin-top: 0;
    grid-row-gap: 80px;
  }
  .sec-client-about .logo {
    max-width: 138px;
  }
  .sec-team .title {
    padding: 15px;
    display: block;
  }
  .sec-team .title p {
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.0125em;
    line-height: 1.75;
  }
  .sec-team .name {
    padding-left: 0;
    margin-bottom: 3px;
  }
  .sec-team .content {
    padding-bottom: 30px;
  }
  .sec-team .txt-container {
    display: block;
    padding-right: 24px;
  }
  .sec-team .txt-container p {
    width: 100%;
    margin-bottom: 24px;
  }
  .sec-team .txt-container p:last-child {
    margin-bottom: 0;
    padding-right: 0;
  }
  .sec-awards li > div,
.sec-awards li > a {
    padding: 20px 15px;
    display: block;
  }
  .sec-awards li .year {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    padding-left: 0;
    margin-bottom: 5px;
  }
  .sec-awards li .name {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
    margin-bottom: 3px;
  }
  .sec-awards li .description {
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 1.6667;
    letter-spacing: 0.0533em;
  }
  .sec-awards a:after {
    width: 1rem;
    height: 1rem;
    right: 15px;
    top: auto;
    bottom: 23px;
  }
}
@media (max-width: 1024px) {
  .sec-main-title.title-about {
    padding-top: 20px;
  }
  .sec-intro-about .g-col-5,
.sec-intro-about .g-col-7 {
    grid-column: auto/span 12;
    grid-column-start: 1;
  }
  .sec-intro-about .title p {
    max-width: 11em;
  }
  .sec-intro-about .font-en-h5 {
    font-size: 6.0547vw;
    line-height: 1.1613;
    letter-spacing: -0.03226em;
    margin-bottom: 32px !important;
  }
  .sec-intro-about .font-zh-h1 {
    font-size: 3.125vw;
    line-height: 1.4063;
    letter-spacing: 0.04688em;
  }
  .sec-intro-about h2 {
    margin-bottom: 80px;
  }
  .sec-team .accordion-item.is-active .content {
    display: block;
  }
  .sec-team .content {
    padding: 20px 15px 30px;
  }
  .sec-team .content figure {
    width: 185px;
    margin-left: 0;
    margin-bottom: 30px;
  }
  .sec-team .txt-container {
    padding-right: 0;
  }
  .sec-team .txt-container p {
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) {
  .sec-main-title.title-about {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .sec-main-title.title-about {
    padding-top: 80px;
  }
  .sec-intro-about .font-en-h5 {
    font-size: 8.0729vw;
    letter-spacing: 0;
  }
  .sec-intro-about .font-zh-h1 {
    font-size: 4.1666vw;
  }
  .sec-client-about .logo-container {
    grid-template-columns: repeat(3, auto);
  }
  .sec-awards li > div,
.sec-awards li > a {
    border-bottom-width: 1px;
  }
  .sec-awards a:after {
    transform: translate(0, 0);
  }
  .sec-awards a:hover, .sec-awards a:focus-visible {
    border-color: #000000;
  }
  .sec-awards a:hover p, .sec-awards a:focus-visible p {
    color: #000000;
  }
}
@media (max-width: 414px) {
  .sec-intro-about, .sec-client-about, .sec-team, .sec-awards {
    padding-bottom: 80px;
  }
  .sec-intro-about h2.font-en-h3, .sec-client-about h2.font-en-h3, .sec-team h2.font-en-h3, .sec-awards h2.font-en-h3 {
    font-size: 16.9082vw;
    line-height: 1;
    letter-spacing: -0.0171em;
    margin-bottom: 60px;
  }
  .sec-intro-about h2 {
    margin-bottom: 60px;
  }
  .sec-intro-about .font-zh-h1 {
    font-size: 6.2802vw;
  }
  .sec-intro-about .font-zh-body-1 {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
  }
  .sec-intro-about .font-en-body-1 {
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.0125em;
    line-height: 1.75;
  }
  .sec-client-about .logo-container {
    grid-row-gap: 60px;
  }
}
#page-contact main {
  clip-path: unset;
}

.sec-main-title.title-contact h2 {
  text-transform: none;
  margin-bottom: 0;
  white-space: nowrap;
}

.sec-careers-contact, .sec-visit-contact, .sec-share-contact, .sec-intro-contact {
  padding: 40px 0 160px;
}
.sec-careers-contact .grid-s, .sec-visit-contact .grid-s, .sec-share-contact .grid-s, .sec-intro-contact .grid-s {
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  text-align: left;
}
.sec-careers-contact h2.font-en-h3, .sec-visit-contact h2.font-en-h3, .sec-share-contact h2.font-en-h3, .sec-intro-contact h2.font-en-h3 {
  margin-bottom: 80px;
}
.sec-careers-contact p, .sec-visit-contact p, .sec-share-contact p, .sec-intro-contact p {
  margin-bottom: 22px;
}
.sec-careers-contact p:last-child, .sec-visit-contact p:last-child, .sec-share-contact p:last-child, .sec-intro-contact p:last-child {
  margin-bottom: 0;
}
.sec-careers-contact .link-group, .sec-visit-contact .link-group, .sec-share-contact .link-group, .sec-intro-contact .link-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}
.sec-careers-contact .link-group a, .sec-visit-contact .link-group a, .sec-share-contact .link-group a, .sec-intro-contact .link-group a {
  margin-bottom: 10px;
}
.sec-careers-contact .link-group a:last-child, .sec-visit-contact .link-group a:last-child, .sec-share-contact .link-group a:last-child, .sec-intro-contact .link-group a:last-child {
  box-sizing: border-box;
  margin-bottom: 0;
}
.sec-careers-contact .link-group a .link-underline, .sec-visit-contact .link-group a .link-underline, .sec-share-contact .link-group a .link-underline, .sec-intro-contact .link-group a .link-underline {
  padding-bottom: 0;
}
.sec-careers-contact .link-group + p, .sec-visit-contact .link-group + p, .sec-share-contact .link-group + p, .sec-intro-contact .link-group + p {
  margin-top: 40px;
}

.sec-careers-contact .font-en > div,
.sec-careers-contact .font-en > p,
.sec-careers-contact p:not(.font-zh-subtitle-1) {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 22px;
}

#list-career {
  margin-top: 80px;
}
#list-career .list-title {
  border-bottom: 1px solid #000000;
  padding: 20px 24px;
}
#list-career .list-content button {
  font: inherit;
  text-align: left;
  padding: 32px 24px;
}
#list-career .list-content button:after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background: url(/assets/img/icon/arrow-right-black.svg) center no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  margin: auto 0;
  opacity: 0.3;
}
#list-career .list-content button:hover:after, #list-career .list-content button:focus-visible:after {
  opacity: 1;
  transform: translate(0, 0);
}
#list-career .list-content button:hover p, #list-career .list-content button:focus-visible p {
  color: #000000;
}
#list-career .list-content p {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.3888;
  letter-spacing: 0.0444em;
  font-weight: 400;
  margin-bottom: 0;
}

/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-intro-contact .grid-s, .sec-share-contact .grid-s, .sec-visit-contact .grid-s, .sec-careers-contact .grid-s {
    width: 1296px;
    margin: 0 auto;
  }
  #hiring-detail .bg-white {
    margin-left: 140px;
  }
}
@media (min-width: 2560px) {
  .sec-intro-contact .grid-s, .sec-share-contact .grid-s, .sec-visit-contact .grid-s, .sec-careers-contact .grid-s {
    width: 80em;
    margin: 0 auto;
  }
}
@media (max-width: 1280px) {
  .sec-intro-contact, .sec-share-contact, .sec-visit-contact, .sec-careers-contact {
    padding-bottom: 120px;
  }
}
@media (max-width: 1024px) {
  .sec-intro-contact, .sec-share-contact, .sec-visit-contact, .sec-careers-contact {
    padding-bottom: 80px;
  }
  .sec-intro-contact .grid-s .title, .sec-share-contact .grid-s .title, .sec-visit-contact .grid-s .title, .sec-careers-contact .grid-s .title {
    grid-column: auto/span 3;
  }
  .sec-intro-contact .grid-s .g-col-6, .sec-share-contact .grid-s .g-col-6, .sec-visit-contact .grid-s .g-col-6, .sec-careers-contact .grid-s .g-col-6 {
    grid-column: auto/span 7;
  }
  .sec-main-title.title-contact {
    padding-top: 20px;
  }
  #list-career .list-content button {
    display: block;
    padding: 20px 15px;
  }
  #list-career .list-content button:after {
    top: auto;
    bottom: 20px;
    right: 15px;
  }
  #list-career .list-content p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.0111em;
    margin-bottom: 1px;
  }
  #list-career .list-content .role {
    font-weight: 500;
  }
  #list-career .list-content .period {
    margin-bottom: 0;
  }
}
@media (max-width: 1023px) {
  .sec-intro-contact .grid-s, .sec-share-contact .grid-s, .sec-visit-contact .grid-s, .sec-careers-contact .grid-s {
    grid-column: auto/span 6;
    display: block;
    width: 100%;
  }
  .sec-intro-contact .grid-s p, .sec-share-contact .grid-s p, .sec-visit-contact .grid-s p, .sec-careers-contact .grid-s p {
    margin-bottom: 32px;
  }
  .sec-intro-contact .grid-s .font-en-m-subtitle-3, .sec-share-contact .grid-s .font-en-m-subtitle-3, .sec-visit-contact .grid-s .font-en-m-subtitle-3, .sec-careers-contact .grid-s .font-en-m-subtitle-3 {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
  }
  .sec-intro-contact .grid-s .font-zh-subtitle-1, .sec-share-contact .grid-s .font-zh-subtitle-1, .sec-visit-contact .grid-s .font-zh-subtitle-1, .sec-careers-contact .grid-s .font-zh-subtitle-1 {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.7777;
    letter-spacing: 0.0444em;
  }
  .sec-intro-contact .grid-s .title, .sec-share-contact .grid-s .title, .sec-visit-contact .grid-s .title, .sec-careers-contact .grid-s .title {
    margin-bottom: 30px;
  }
  .sec-intro-contact .grid-s .title h3, .sec-share-contact .grid-s .title h3, .sec-visit-contact .grid-s .title h3, .sec-careers-contact .grid-s .title h3 {
    font-size: 1.875em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.0666;
  }
  .sec-intro-contact .link-group, .sec-share-contact .link-group, .sec-visit-contact .link-group, .sec-careers-contact .link-group {
    margin-top: 32px;
  }
  .sec-intro-contact .link-group a, .sec-share-contact .link-group a, .sec-visit-contact .link-group a, .sec-careers-contact .link-group a {
    margin-bottom: 0;
  }
  .sec-intro-contact .link-group + p, .sec-share-contact .link-group + p, .sec-visit-contact .link-group + p, .sec-careers-contact .link-group + p {
    margin-top: 32px;
    margin-bottom: 22px;
  }
  .sec-main-title.title-contact {
    padding-top: 60px;
  }
  .sec-share-contact p br {
    display: none;
  }
  .sec-careers-contact {
    padding-bottom: 100px;
  }
  .sec-careers-contact .font-en > div,
.sec-careers-contact .font-en > p,
.sec-careers-contact p:not(.font-zh-subtitle-1) {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
    margin-bottom: 32px;
  }
  #list-career {
    margin-top: 60px;
  }
}
@media (max-width: 768px) {
  .sec-main-title.title-contact {
    padding-top: 80px;
  }
}
.sec-hiring-intro {
  padding: 50px 0 100px;
}
.sec-hiring-intro figure {
  width: 420px;
  margin-right: 68px;
  flex-shrink: 0;
}
.sec-hiring-intro figure img {
  width: 100%;
}
.sec-hiring-intro .content {
  max-width: 51.875em;
}
.sec-hiring-intro .content h2 {
  font-size: 1rem;
  text-transform: none;
  margin-bottom: 40px;
}
.sec-hiring-intro .content h2 span {
  display: block;
  font-weight: 500 !important;
}
.sec-hiring-intro .content h2 span.font-zh {
  font-family: "Pingfang TC", -apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 3.25em;
  line-height: 1.3846;
  letter-spacing: 0.0289em;
  margin-top: 5px;
}
.sec-hiring-intro .content p {
  margin-bottom: 12px;
}
.sec-hiring-intro .content p .type,
.sec-hiring-intro .content p .period {
  display: inline-block;
}
.sec-hiring-intro .content p .type {
  margin-right: 15px;
}
.sec-hiring-intro .content .description {
  font-weight: 300;
}

.sec-hiring {
  padding-bottom: 100px;
}
.sec-hiring hr.hr-stroke-grey-2 {
  margin-bottom: 140px;
}
.sec-hiring h3 {
  margin-bottom: 20px;
}
.sec-hiring article {
  padding: 30px 0 60px;
}
.sec-hiring ul > li {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}
.sec-hiring ol > li {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}
.sec-hiring .content.g-col-8 > div,
.sec-hiring p {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}
.sec-hiring b,
.sec-hiring strong {
  font-weight: 500;
}
.sec-hiring .btn {
  margin-top: 30px;
}
.sec-hiring a {
  text-decoration: underline;
  text-underline-offset: 0.125em;
}
.sec-hiring a:hover, .sec-hiring a:focus-visible {
  color: #808080;
}
.sec-hiring a.link-mail {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
  text-decoration: none;
  text-underline-offset: 0;
}
.sec-hiring a.link-mail .link-underline {
  padding-bottom: 0;
}

/* -------------RWD--------------- */
@media (min-width: 1681px) {
  .sec-hiring-intro figure {
    width: 460px;
  }
}
@media (max-width: 1440px) {
  .sec-hiring-intro .content {
    max-width: none;
  }
}
@media (max-width: 1280px) {
  .sec-hiring-intro {
    padding-top: 0;
  }
  .sec-hiring-intro .content h2 span.font-en-h4 {
    font-size: 2.625em;
  }
  .sec-hiring-intro .content h2 span.font-zh {
    font-size: 2.3125em;
  }
}
@media (max-width: 1024px) {
  .sec-hiring-intro {
    padding: 17px 0 80px;
  }
  .sec-hiring-intro .container {
    flex-direction: column;
  }
  .sec-hiring-intro figure {
    width: 100%;
    margin-bottom: 40px;
  }
  .sec-hiring-intro .content h2 span.font-en-h4 {
    font-size: 1.875em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.0666;
  }
  .sec-hiring-intro .content h2 span.font-zh {
    font-size: 1.875em;
    line-height: 1.0666;
    letter-spacing: 0;
    margin-top: 12px;
  }
  .sec-hiring-intro .content .description {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.7777;
    letter-spacing: 0.0444em;
  }
  .sec-hiring {
    padding-bottom: 40px;
  }
  .sec-hiring hr.hr-stroke-grey-2 {
    margin-bottom: 80px;
  }
  .sec-hiring h3 {
    font-size: 1.625em;
    font-weight: 600;
    line-height: 1.3077;
    letter-spacing: 0;
    margin-bottom: 15px;
  }
  .sec-hiring .title {
    grid-column: auto/span 12;
  }
  .sec-hiring .title h4 {
    margin-bottom: 30px;
  }
  .sec-hiring .content {
    grid-column: auto/span 12;
  }
}
@media (max-width: 1023px) {
  .sec-hiring a.link-mail {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
  }
}
@media (max-width: 414px) {
  .sec-hiring-intro {
    padding-bottom: 80px;
  }
  .sec-hiring-intro .content .description {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
  }
  .sec-hiring .title h4 {
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 1.6667;
    letter-spacing: 0.0533em;
    font-weight: 500;
  }
  .sec-hiring ul > li,
.sec-hiring ol > li,
.sec-hiring .content.g-col-8 > div,
.sec-hiring p {
    font-size: 0.9375em;
    line-height: 2;
    letter-spacing: 0.0266em;
  }
}
@media (max-width: 360px) {
  .sec-hiring-intro figure {
    margin-bottom: 30px;
  }
}
#page-service {
  position: relative;
  overflow-x: hidden;
}
#page-service #header-position {
  -webkit-backface-visibility: hidden;
}
#page-service #header-position:not(.is-open) {
  mix-blend-mode: exclusion;
}
#page-service #header-position:not(.is-open) #header {
  filter: invert(100%);
}
#page-service #header-position.bg-white {
  mix-blend-mode: unset;
}
#page-service #header-position.bg-white #header {
  filter: none;
}
#page-service #header.is-shrink {
  padding: 11px 0 !important;
}
#page-service #header.is-hidden {
  transform: none !important;
}

.sec-main-title.title-service {
  padding-top: 30px;
}
.sec-main-title.title-service .content {
  max-width: 72.75em;
}
.sec-main-title.title-service h2 {
  margin-bottom: 160px;
  margin-left: -0.05em;
}
.sec-main-title.title-service p.font-en-h4 {
  margin-bottom: 30px;
}

#nav-service {
  width: 100%;
  background: white;
  box-shadow: 0 3px 9px -3px rgba(0, 0, 0, 0.08);
  margin-bottom: 60px;
  position: sticky;
  top: var(--header_height);
  margin-top: -1px;
  z-index: 9;
  transition: top 0.4s;
}
#nav-service a {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  min-width: 7.5rem;
  margin: 0 20px;
  padding: 10px 0;
  color: #D0D0D0;
  text-align: center;
  transition: color 0.25s ease;
}
#nav-service a:hover, #nav-service a:focus-visible, #nav-service a.is-active {
  color: #000000;
}

.sec-process .accordion-item .content, .sec-service .accordion-item .content {
  padding: 24px 60px 24px 24px;
}
.sec-process .accordion-item .content .font-zh-body-3, .sec-service .accordion-item .content .font-zh-body-3 {
  font-size: 1.0625em;
  margin-bottom: 18px;
}
.sec-process .accordion-item .content .font-en-body-3, .sec-service .accordion-item .content .font-en-body-3 {
  font-size: 1.0625em;
}

.sec-service {
  padding-top: 120px;
  margin-top: -120px;
}
.sec-service article {
  margin-bottom: 160px;
}
.sec-service article > .title {
  position: sticky;
  top: 120px;
  align-self: start;
}
.sec-service article > .title h3 {
  font-weight: 500;
}
.sec-service article > .content {
  position: relative;
  overflow: hidden;
}
.sec-service .accordion-item .title {
  padding: 19px 24px;
}
.sec-service .accordion-item .title .font-zh-subtitle-1 {
  display: inline-block;
  margin-right: 20px;
}
.sec-service .swiper-work {
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}
.sec-service .swiper-work a {
  display: block;
  position: relative;
}
.sec-service .swiper-work a:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 2;
}
.sec-service .swiper-work a:hover:before, .sec-service .swiper-work a:focus-visible:before {
  opacity: 1;
}
.sec-service .swiper-work a:hover .title-work, .sec-service .swiper-work a:focus-visible .title-work {
  animation-delay: 0s;
  animation-duration: 0.4s;
  animation-name: fadeInUp;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
.sec-service .swiper-work img,
.sec-service .swiper-work video,
.sec-service .swiper-work iframe {
  display: block;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.sec-service .swiper-work .vid-container {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
.sec-service .swiper-work .vid-container iframe {
  width: 105%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2.5%;
}
.sec-service .swiper-work .title-work {
  line-height: 1;
  letter-spacing: 0.08em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 25px 30px;
  opacity: 0;
  z-index: 3;
}
.sec-service .swiper-work .swiper-controls {
  margin-top: 10px;
}
.sec-service .swiper-work .swiper-pagination {
  top: 0;
  margin-top: 0;
}
.sec-service .swiper-work .swiper-button-prev,
.sec-service .swiper-work .swiper-button-next {
  display: block;
  width: auto;
  height: auto;
  position: relative;
  mix-blend-mode: unset;
  padding-left: 15px;
}
.sec-service .swiper-work .swiper-button-prev img,
.sec-service .swiper-work .swiper-button-next img {
  width: 16px;
}

.sec-partners .grid > .title.grid-s, .sec-process .grid > .title.grid-s {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 24px;
  align-self: start;
}
.sec-partners .grid > .title h3, .sec-process .grid > .title h3 {
  margin-block: 32px 35px;
}

.sec-process {
  margin-top: -80px;
  padding-block: 120px 200px;
}
.sec-process .grid > .title h3 {
  font-weight: 500;
}
.sec-process .accordion-item .title {
  padding: 27px 24px;
}
.sec-process .accordion-item .title small {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  margin-right: 20px;
}
.sec-process .accordion-item .title span {
  display: inline-block;
  font-size: 1.5625em;
  line-height: 1.6;
  letter-spacing: 0;
  text-align: justify;
}
.sec-partners {
  padding-block: 120px 180px;
}
.sec-partners .grid > .title h3 {
  font-weight: 500;
}
.sec-partners .accordion-item .title {
  padding: 24px;
}
.sec-partners .accordion-item .title span {
  display: inline-block;
  font-size: 1.5625em;
  line-height: 1.6;
  letter-spacing: 0;
  text-align: justify;
}
.sec-partners .accordion-item .content .grid-s {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 30px;
  align-self: start;
  margin-block: 24px;
  text-align: left;
}
.sec-partners .accordion-item .content button:not(:disabled):hover figure:after, .sec-partners .accordion-item .content button:not(:disabled):focus-visible figure:after {
  opacity: 1;
}
.sec-partners .accordion-item figure {
  position: relative;
}
.sec-partners .accordion-item figure:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: opacity 0.25s;
}
.sec-partners .accordion-item .txt-container {
  padding-top: 30px;
}
.sec-partners .accordion-item .txt-container h4 {
  margin-bottom: 15px;
  font-weight: 400;
}
.sec-partners .accordion-item .tag-container {
  margin-bottom: 30px;
}
.sec-partners .accordion-item .tag {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 400;
  padding-right: 12px;
  border-right: 1px solid #808080;
  margin-right: 12px;
}
.sec-partners .accordion-item .tag:last-child {
  padding-right: 0;
  border-right: none;
  margin-right: 0;
}

#partner-detail .modal-title {
  padding-bottom: 60px;
}
#partner-detail .modal-title h2 {
  font-weight: 500;
  text-transform: none;
}
#partner-detail .modal-title p {
  font-weight: 400;
  margin-bottom: 12px;
}
#partner-detail .btn-close {
  right: 40px;
}
#partner-detail .bg-white {
  max-width: 584px;
  margin-left: auto;
}
#partner-detail .container {
  padding-inline: 40px;
}
#partner-detail .sec-partner-work {
  padding-bottom: 40px;
}
#partner-detail .sec-partner-work .project-list {
  width: 100%;
}
#partner-detail .sec-partner-work .work-item, #partner-detail .sec-partner-work .news-item {
  margin-bottom: 60px;
}

/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-main-title.title-service .content {
    max-width: 68.3333vw;
  }
  .sec-main-title.title-service p.font-en-h4 {
    font-size: 3.6806vw;
    line-height: 1.1887;
    letter-spacing: -0.0377em;
    margin-bottom: 20px;
  }
  .sec-main-title.title-service p.font-zh-h1 {
    font-size: 2.2917vw;
    line-height: 1.5152;
    letter-spacing: 0.0455em;
  }
  .sec-service article {
    margin-bottom: 140px;
  }
}
@media (max-width: 1280px) {
  .sec-main-title.title-service {
    padding-top: 60px;
  }
  .sec-main-title.title-service h2 {
    margin-bottom: 190px;
  }
  .sec-main-title.title-service p.font-en-h4 {
    margin-bottom: 15px;
  }
  #nav-service {
    margin-bottom: 40px;
  }
  .sec-process .grid > .title h3, .sec-partners .grid > .title h3 {
    margin-block: 22px 25px;
    font-size: 2em;
    line-height: 1.2;
    letter-spacing: -0.0313em;
  }
  .sec-process .grid > .title p, .sec-partners .grid > .title p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.0111em;
  }
  .sec-partners .accordion-item .content .grid-s {
    grid-column-gap: 20px;
  }
  .sec-partners .accordion-item .txt-container {
    padding-top: 12px;
  }
  .sec-partners .accordion-item .txt-container h4 {
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.7777;
    letter-spacing: -0.0111em;
    margin-bottom: 5px;
  }
  .sec-partners .accordion-item .tag-container {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .sec-main-title.title-service h2 {
    margin-bottom: 120px;
  }
  .sec-service .accordion-item .content .font-zh-body-3, .sec-process .accordion-item .content .font-zh-body-3,
.sec-service .accordion-item .content .font-en-body-3,
.sec-process .accordion-item .content .font-en-body-3 {
    font-size: 0.9375em;
  }
  .sec-service article > .title {
    position: relative;
    top: 0;
  }
}
@media (max-width: 1024px) {
  #page-service .container {
    padding-inline: 25px;
  }
  #page-service .grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .sec-main-title.title-service .content {
    max-width: 44.875em;
  }
  .sec-main-title.title-service p.font-en-h4 {
    font-size: 2.625em;
    font-weight: 600;
    letter-spacing: -0.0238em;
    line-height: 1.2;
  }
  .sec-main-title.title-service p.font-zh-h1 {
    font-size: 1.6875em;
    line-height: 1.6296;
    letter-spacing: 0.0556em;
  }
  #nav-service a {
    min-width: 6.25rem;
    font-size: 1.0625em;
    font-weight: 600;
    letter-spacing: 0.02353em;
    line-height: 1;
    padding-block: 16px 12px;
    margin: 0 5px;
  }
  #nav-service a:hover, #nav-service a:focus-visible {
    color: #D0D0D0;
  }
  #nav-service a.is-active {
    color: #000000;
  }
  .sec-service .accordion-item .content, .sec-process .accordion-item .content {
    padding: 20px 15px;
  }
  .sec-service {
    position: relative;
    overflow: hidden;
  }
  .sec-service .title.g-col-5 {
    grid-column: auto/span 6;
  }
  .sec-service .content.g-col-7 {
    grid-column: auto/span 6;
  }
  .sec-service article {
    margin-bottom: 100px;
  }
  .sec-service article > .title h3 {
    font-size: 1.875em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.0666;
    margin-bottom: 40px;
  }
  .sec-service article > .content {
    overflow: visible;
  }
  .sec-service .accordion-item .title {
    padding: 21px 15px 14px;
  }
  .sec-service .accordion-item .title .font-en-btn-3 {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 3px;
  }
  .sec-service .accordion-item .title .font-zh-subtitle-1 {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
  }
  .sec-service .accordion-item p {
    display: flex;
    flex-direction: column-reverse;
  }
  .sec-service .swiper-work {
    overflow: visible;
  }
  .sec-service .swiper-work .swiper-slide {
    opacity: 0.3;
    transition: opacity 0.4s;
  }
  .sec-service .swiper-work .swiper-slide .title-work {
    opacity: 0;
  }
  .sec-service .swiper-work .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
  .sec-service .swiper-work .swiper-slide.swiper-slide-active .title-work {
    opacity: 1;
  }
  .sec-service .swiper-work a:before {
    display: none;
  }
  .sec-service .swiper-work .title-work {
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.4444;
    letter-spacing: 0.0444em;
    position: relative;
    margin: 15px 0;
    opacity: 1;
    animation-name: none !important;
    color: #000000 !important;
  }
  .sec-process .grid, .sec-partners .grid {
    display: block;
  }
  .sec-process .grid > .title, .sec-partners .grid > .title {
    margin-bottom: 60px;
  }
  .sec-process .grid > .title.grid-s, .sec-partners .grid > .title.grid-s {
    display: block;
  }
  .sec-process .grid > .title h3, .sec-partners .grid > .title h3 {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
    margin-block: 0 15px;
  }
  .sec-process .grid > .title p, .sec-partners .grid > .title p {
    font-size: 1em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.025em;
  }
  .sec-process h2, .sec-partners h2 {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .sec-process {
    margin-top: -140px;
    padding: 140px 0;
  }
  .sec-process .accordion-item .title {
    padding: 20px 15px 20px 0;
  }
  .sec-process .accordion-item .title small {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.4286;
    letter-spacing: 0;
    font-weight: bold;
    margin-right: 25px;
  }
  .sec-process .accordion-item .title span {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
  }
  .bg-light {
    padding-top: 1px;
    margin-top: -1px;
  }
  .sec-partners {
    margin-top: -80px;
    padding-block: 140px 100px;
  }
  .sec-partners .accordion-item .title {
    padding: 20px 15px 20px 0;
  }
  .sec-partners .accordion-item .title p {
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6666;
  }
  .sec-partners .accordion-item .content .grid-s {
    display: block;
    margin: 0;
  }
  .sec-partners .accordion-item .txt-container {
    padding-bottom: 40px;
  }
  .sec-partners .accordion-item .txt-container h4 {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.7777;
    letter-spacing: 0.0444em;
    margin-bottom: 5px;
  }
  .sec-partners .accordion-item .tag-container {
    margin-bottom: 15px;
  }
  .sec-partners .accordion-item .tag {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.7143;
    letter-spacing: 0.05714em;
    border-right-color: #D0D0D0;
  }
  .sec-partners .accordion-item figure {
    margin-block: 17px 15px;
  }
  .sec-partners .accordion-item figure img {
    width: 100%;
  }
  #partner-detail .btn-close {
    right: 25px;
  }
  #partner-detail .container {
    padding-inline: 25px;
  }
}
@media (max-width: 768px) {
  #page-service {
    position: relative;
    overflow-x: hidden;
  }
  #page-service #header-position:not(.is-open) {
    mix-blend-mode: unset;
  }
  #page-service #header-position:not(.is-open) #header {
    filter: invert(0);
  }
}
@media (max-width: 620px) {
  #partner-detail .bg-white {
    margin-left: 60px;
  }
}
@media (max-width: 414px) {
  #page-service .container {
    padding-inline: 17px;
  }
  #page-service .grid {
    grid-column-gap: 10px;
  }
  .sec-main-title.title-service h2 {
    margin-bottom: 100px;
  }
  .sec-main-title.title-service p.font-en-h4 {
    font-size: 1.625em;
    font-weight: 600;
    line-height: 1.3077;
    letter-spacing: 0;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .sec-main-title.title-service p.font-zh-h1 {
    font-size: 1.625em;
    font-weight: 500;
    letter-spacing: 0.01538em;
    line-height: 1.6154;
  }
  .sec-partners {
    padding-bottom: 80px;
  }
  #partner-detail .bg-white {
    margin-left: 40px;
  }
  #partner-detail .btn-close {
    right: 17px;
  }
  #partner-detail .container {
    padding-inline: 17px;
  }
  #partner-detail .modal-title p {
    margin-bottom: 10px;
  }
  #partner-detail .modal-title h2 {
    font-size: 2em;
    font-weight: 500;
    line-height: 1.3125;
    letter-spacing: 0;
    font-size: 1.5625em;
    font-weight: 500;
    line-height: 1.28;
  }
}
@media (max-width: 360px) {
  #partner-detail .bg-white {
    margin-left: 20px;
  }
}
.sec-main-title.title-privacy {
  padding: 50px 0 200px;
}
.sec-main-title.title-privacy h2 {
  margin-bottom: 0;
}

.sec-privacy {
  padding-bottom: 100px;
}
.sec-privacy h3 {
  font-weight: 500;
}
.sec-privacy p {
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.0111em;
}
.sec-privacy .flex-nowrap {
  padding: 30px 0 60px;
}

/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-main-title.title-privacy h2 {
    font-size: 7.6389vw;
  }
}
@media (max-width: 1024px) {
  .sec-privacy .flex-nowrap {
    flex-direction: column;
  }
  .sec-privacy .col-30,
.sec-privacy .col-70 {
    width: 100%;
  }
  .sec-privacy h3 {
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .sec-main-title.title-privacy {
    padding: 40px 0 140px;
  }
  .sec-main-title.title-privacy h2 {
    font-size: 16.9082vw;
    text-align: left;
  }
}
@media (max-width: 414px) {
  .sec-main-title.title-privacy {
    padding: 40px 0 140px;
  }
  .sec-privacy {
    padding-bottom: 80px;
  }
  .sec-privacy p {
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.02667em;
    text-align: justify;
  }
}
#page-404 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
@supports (height: 100dvh) {
  #page-404 {
    min-height: 100dvh;
  }
}
#page-404 > .vertical-align-center {
  margin: auto 0;
}
#page-404 main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
}

.sec-404 h2 {
  margin-bottom: 40px;
}

/* -------------RWD--------------- */
@media (max-width: 414px) {
  .sec-404 h2 {
    font-size: 1.875em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.0666;
    margin-bottom: 20px;
  }
}