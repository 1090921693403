@charset 'utf-8';
/* -----RESET---------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, input, textarea, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, main, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article, aside, details, figcaption, figure, footer,
header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  border: none;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
input::-moz-focus-inner,
button::-moz-focus-inner,
select::-moz-focus-inner {
  border: 0;
  outline: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
input[type="search"],
input[type="search"]:hover,
input[type="search"]:focus,
input[type="search"]:active{
  background: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  appearance:none;
  -webkit-appearance:none;
}
input[type="search"]:-internal-autofill-selected,
input[type="search"]:-webkit-autofill,
input[type="search"]:-webkit-autofill:hover,
input[type="search"]:-webkit-autofill:focus,
input[type="search"]:-webkit-autofill:active{
  transition: background-color 5000000s ease-in-out 0s;
}
select::-ms-expand {
  display: none;
}
button,
input[type="submit"],
input[type="reset"] {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  appearance:none;
  -webkit-appearance:none;
}
option {
  background-color: transparent;
}
button{
  padding: 0;
  margin: 0;
  color: inherit;
}
// code{
//  display: block;
//  background: $color-grey;
//  padding:10px;
//  border-left: 3px solid $color-primary;
// }