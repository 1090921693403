@charset 'utf-8';
.category-container{
  padding: 30px 0;
  nav{
    display: flex;
    align-items: flex-start;
    a{
      display: block;
      @include font-en-btn-4();
      color: $color-txt-grey-1;
      padding: 6px 20px;
      @include transition(color .25s);
      &:first-child{
        padding-left: 15px;
      }
      &:hover,
      &:focus-visible,
      &.is-active{
        color: $color-black;
      }
      // &.is-active{
      //   cursor: default;
      //   pointer-events: none;
      // }
    }
    > span{
      display: block;
      @include font-en-btn-4();
      position: relative;
      &:after{
        content:'';
        width: 1px;
        height: 1.5em;
        background: $color-stroke-grey-1;
        position: absolute;
        top: .25em;right: 0;
      }
    }
  }
}
#trigger-filter{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  @include font-en-btn-4();
  padding: 6px 15px;
  color: $color-txt-grey-1;
  box-shadow: 0 0 0 1px $color-stroke-grey-2 inset;
  border-radius: 15px;
  @include transition(color .25s, box-shadow .25s);
  svg{
    display: inline-block;
    width: .7em;
    height: .7em;
    margin-left: 5px;
    background-size: contain;
  }
  path{
    fill: $color-txt-grey-1;
    @include transition(fill .25s);
  }
  &:hover,
  &:focus-visible,
  &.is-active{
    color: $color-black;
    box-shadow: 0 0 0 1px $color-black inset;
    path{
      fill: $color-black;
    }
  }
  &.is-active{
    svg{
      @include scale(1, -1);
    }
  }
}
#filter-container{
  width: calc(100% + 120px);
  display: flex;
  // display: none;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: -6px;
  margin-inline: -60px;
  padding-inline: 60px;
  overflow-x: auto;
  overflow-y: hidden;
  @include transition(margin .3s ease);
  &::-webkit-scrollbar {height: 0; }
  &::-webkit-scrollbar-track {background: #f1f1f1; }
  &::-webkit-scrollbar-thumb {background: #888; }
  &::-webkit-scrollbar-thumb:hover {background: #555; }

  &.is-animated{
    @include animation(showFilter, .4s, 0s, ease, 1, both);
  }
  &[style*="height: 0px"]{
    margin-top: 0;
    margin-bottom: 0;
  }
  li{
    flex-shrink: 0;
    @include font-en-btn-4();
    padding: 6px 15px;
    box-shadow: 0 0 0 1px $color-stroke-grey-2 inset;
    border-radius: 15px;
    margin-right: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    @include transition(box-shadow .25s);
    &:last-child{
      margin-right: 0;
    }
    &:hover,
    &:focus-visible{
      box-shadow: 0 0 0 1px $color-black inset;
    }
    &.is-active{
      cursor: default;
      // pointer-events: none;
      color: $color-txt-grey-1;
      box-shadow: 0 0 0 1px $color-stroke-grey-1 inset;
    }
  }
}

/* -------------RWD--------------- */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  #filter-container{
    width: calc(100% + 80px);
    margin-inline: -40px;
    padding-inline: 40px;
  }
  .category-container{
    padding: 25px 0;
  }
}
@media (max-width: 1024px) {
  .category-container{
    padding: 20px 0;
  }
}
@media (max-width: 1023px) {
  #filter-container{
    width: calc(100% + 50px);
    margin-inline: -25px;
    padding-inline: 25px;
  }
  .category-container{
    padding: 15px 0;
    nav{
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      a{
        padding: 6px 15px;
        &:first-child{
          padding-left: 0;
        }
      }
    }
  }
  #trigger-filter{
    margin-left: auto;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 414px) {
  #filter-container{
    width: calc(100% + 34px);
    margin-inline: -17px;
    padding-inline: 17px;
  }
  #mobile-sticky-filter{
    position: sticky;
    top: -1px;
    z-index: 3;
    // margin-top: -1px;
    background: white;
    @include transition(transform .4s linear);
    .category-container{
      // margin-top: -1px;
      transition-duration: .4s !important;
    }
    &.header-show{
      transform: translateY(var(--header_height));
    }
    &.not-sticky{
      transform: translateY(0);
      // @include transition(none);
    }
  }
}
@media (max-width: 360px) {
  .category-container{
    nav{
      a,
      > span{
        font-size: 0.8125em;
      }
    }
  }
  #trigger-filter{
    span{
      font-size: 0.8125em;
    }
  }
  #filter-container{
    li{
      font-size: 0.8125em;
    }
  }
}