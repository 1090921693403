@charset 'utf-8';
#page-service{
  position: relative;
  overflow-x: hidden;
  #header-position{
    -webkit-backface-visibility: hidden;
    &:not(.is-open){
      mix-blend-mode: exclusion;
      #header{
        filter: invert(100%);
      }
    }
    &.bg-white{
      mix-blend-mode: unset;
      #header{
        filter: none;
      }
    }
  }
  #header{
    &.is-shrink{
      padding: 11px 0 !important;
    }
    &.is-hidden{
      transform: none !important;
    }
  }
}
.sec-main-title{
  &.title-service{
    padding-top: 30px;
    // padding-bottom: 200px;
    .content{
      max-width: 72.75em;
    }
    h2{
      margin-bottom: 160px;
      margin-left: -0.05em;
    }
    p{
      &.font-en-h4{margin-bottom: 30px; }
    }
  }
}
#nav-service{
  width: 100%;
  background: white;
  box-shadow: 0 3px 9px -3px transparentize($color-black, .92);
  margin-bottom: 60px;
  position: sticky;
  top: var(--header_height);
  margin-top: -1px;
  z-index: 9;
  @include transition(top .4s);
  a{
    @include font-en-subtitle-5();
    min-width: 7.5rem;
    margin: 0 20px;
    padding: 10px 0;
    color: $color-stroke-grey-1;
    text-align: center;
    @include transition(color .25s ease);
    &:hover,
    &:focus-visible,
    &.is-active{
      color: $color-black;
    }
  }
}
%accordion-content{
  padding: 24px 60px 24px 24px;
  .font-zh-body-3{
    font-size: 1.0625em;
    margin-bottom: 18px;
  }
  .font-en-body-3{
    font-size: 1.0625em;
  }
}
.sec-service{
  padding-top: 120px;
  margin-top: -120px;
  article{
    margin-bottom: 160px;
    > .title{
      position: sticky;
      top: 120px;
      align-self: start;
      h3{font-weight: 500;}
    }
    > .content{
      position: relative;
      overflow: hidden;
    }
  }
  .accordion-item{
    .title{
      padding: 19px 24px;
      .font-zh-subtitle-1{
        display: inline-block;
        margin-right: 20px;
      }
    }
    .content{
      @extend %accordion-content;
    }
  }
  .swiper-work{
    margin-bottom: 60px;
    position: relative;
    overflow: hidden;
    a{
      display: block;
      position: relative;
      &:before{
        content:'';
        display: block;
        position: absolute;
        inset: 0;
        background: transparentize($color-black, .5);
        opacity: 0;
        @include transition(opacity .4s ease);
        z-index: 2;
      }
      &:hover,
      &:focus-visible{
        &:before{opacity: 1; }
        .title-work{
          @include animation(fadeInUp, .4s, 0s, ease, 1, both);
        }
      }
    }
    img,
    video,
    iframe{
      display: block;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
    .vid-container{
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      overflow: hidden;
      iframe{
        width: 105%;
        height: 100%;
        position: absolute;
        top: 0;left: -2.5%;
      }
    }
    .title-work{
      line-height: 1;
      letter-spacing: .08em;
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;left: 0;
      margin: 25px 30px;
      opacity: 0;
      z-index: 3;
    }
    .swiper-controls{
      margin-top: 10px;
    }
    .swiper-pagination{
      top: 0;
      margin-top: 0;
    }
    .swiper-button-prev,
    .swiper-button-next{
      display: block;
      width: auto;
      height: auto;
      position: relative;
      mix-blend-mode: unset;
      padding-left: 15px;
      img{
        width: 16px;
      }
    }
  }
}
%title{
  .grid{
    > .title{
      &.grid-s{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 0;
        grid-column-gap: 24px;
        align-self: start;
      }
      h3{
        margin-block: 32px 35px;
      }
    }
  }
}
.sec-process{
  margin-top: -80px;
  padding-block: 120px 200px;
  // padding-block: 40px 200px;
  @extend %title;
  .grid{
    > .title{
      h3{font-weight: 500;}
    }
  }
  .accordion-item{
    .title{
      padding: 27px 24px;
      small{
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        margin-right: 20px;
      }
      span{
        display: inline-block;
        font-size: 1.5625em;
        line-height: 1.6;
        letter-spacing: 0;
        text-align: justify;
      }
    }
    .content{
      @extend %accordion-content;
    }
  }
}
.sec-partners{
  padding-block: 120px 180px;
  @extend %title;
  .grid{
    > .title{
      h3{font-weight: 500;}
    }
  }
  .accordion-item{
    .title{
      padding: 24px;
      span{
        display: inline-block;
        font-size: 1.5625em;
        line-height: 1.6;
        letter-spacing: 0;
        text-align: justify;
      }
    }
    .content{
      .grid-s{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 0;
        grid-column-gap: 30px;
        align-self: start;
        margin-block:24px;
        text-align: left;
      }
      button{
        &:not(:disabled):hover,
        &:not(:disabled):focus-visible{
          figure{
            &:after{opacity: 1;}
          }
        }
      }
    }
    figure{
      position: relative;
      &:after{
        content:'';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        background: transparentize(black, .92);
        opacity: 0;
        @include transition(opacity .25s);
      }
    }
    .txt-container{
      padding-top: 30px;
      h4{
        margin-bottom: 15px;
        font-weight: 400;
      }
    }
    .tag-container{
      margin-bottom: 30px;
    }
    .tag{
      @include font-en-btn-4();
      font-weight: 400;
      padding-right: 12px;
      border-right: 1px solid $color-txt-grey-1;
      margin-right: 12px;
      &:last-child{
        padding-right: 0;
        border-right: none;
        margin-right: 0;
      }
    }
  }
}
#partner-detail{
  .modal-title{
    padding-bottom: 60px;
    h2{
      font-weight: 500;
      text-transform: none;
    }
    p{
      font-weight: 400;
      margin-bottom: 12px;
    }
  }
  .btn-close{
    right: 40px;
  }
  .bg-white{
    max-width: 584px;
    margin-left: auto;
  }
  .container{
    padding-inline: 40px;
  }
  .sec-partner-work{
    padding-bottom: 40px;
    .project-list{
      width: 100%;
    }
    .work-item{
      margin-bottom: 60px;
    }
  }
}
/* -------------RWD--------------- */
@media (max-width: 1440px) {
  .sec-main-title{
    &.title-service{
      // padding-bottom: 180px;
      // h2{margin-bottom: 120px;}
      .content{
        max-width: 68.3333vw;
      }
      p{
        &.font-en-h4{
          font-size: 3.6806vw;
          line-height: 1.1887;
          letter-spacing: -0.0377em;
          margin-bottom: 20px;
        }
        &.font-zh-h1{
          font-size: 2.2917vw;
          line-height: 1.5152;
          letter-spacing: .0455em;
        }
      }
    }
  }
  .sec-service{
    article{
      margin-bottom: 140px;
    }
  }
}
@media (max-width: 1280px) {
  .sec-main-title{
    &.title-service{
      padding-top: 60px;
      // padding-bottom: 160px;
      h2{margin-bottom: 190px;}
      p{
        &.font-en-h4{margin-bottom: 15px; }
      }
    }
  }
  #nav-service{
    margin-bottom: 40px;
  }
  .sec-service{
  }
  %title{
    .grid{
      > .title{
        h3{
          margin-block: 22px 25px;
          font-size: 2em;
          line-height: 1.2;
          letter-spacing: -.0313em;
        }
        p{
          @include font-zh-body-2();
        }
      }
    }
  }
  .sec-partners{
    .accordion-item{
      .content{
        .grid-s{
          grid-column-gap: 20px;
        }
      }
      .txt-container{
        padding-top: 12px;
        h4{
          @include font-en-body-1();
          margin-bottom: 5px;
        }
      }
      .tag-container{margin-bottom: 20px;}
    }
  }
}
@media (max-width: 1024px) {
  .sec-main-title{
    &.title-service{
      // padding-bottom: 120px;
      h2{margin-bottom: 120px;}
    }
  }
  %accordion-content{
    .font-zh-body-3,
    .font-en-body-3{
      font-size: .9375em;
    }
  }
  .sec-service{
    article{
      > .title{
        position: relative;
        top: 0;
      }
    }
  }
}
@media (max-width: 1024px) {
  #page-service{
    .container {
      padding-inline: 25px;
    }
    .grid{
      grid-template-columns: repeat(6, 1fr);
    }
  }
  .sec-main-title{
    &.title-service{
      .content{
        max-width: 44.875em;
      }
      p{
        &.font-en-h4{
          @include font-en-h5();
        }
        &.font-zh-h1{
          font-size: 1.6875em;
          line-height: 1.6296;
          letter-spacing: 0.0556em;
        }
      }
    }
  }
  #nav-service{
    a{
      min-width: 6.25rem;
      @include font-en-m-body-2();
      padding-block: 16px 12px;
      margin: 0 5px;
      &:hover,
      &:focus-visible{
        color: $color-stroke-grey-1;
      }
      &.is-active{
        color: $color-black;
      }
    }
  }
  %accordion-content{
    padding: 20px 15px;
  }
  .sec-service{
    position: relative;
    overflow: hidden;
    .title.g-col-5{
      grid-column: auto/span 6;
    }
    .content.g-col-7{
      grid-column: auto/span 6;
    }
    article{
      margin-bottom: 100px;
      > .title{
        h3{
          @include font-en-m-h5();
          margin-bottom: 40px;
        }
      }
      > .content{
        overflow: visible;
      }
    }
    .accordion-item{
      .title{
        padding:21px 15px 14px;
        .font-en-btn-3{
          @include font-en-btn-m-2();
          margin-bottom: 3px;
        }
        .font-zh-subtitle-1{
          @include font-zh-m-subtitle-2();;
        }
      }
      p{
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .swiper-work{
      overflow: visible;
      .swiper-slide{
        opacity: 0.3;
        @include transition(opacity .4s);
        .title-work{opacity: 0;}
        &.swiper-slide-active{
          opacity: 1;
          .title-work{opacity: 1;}
        }
      }
      a{
        &:before{display: none;}
      }
      .title-work{
        font-size: 1.125em;
        font-weight: 600;
        line-height: 1.4444;
        letter-spacing: .0444em;
        position: relative;
        margin: 15px 0;
        opacity: 1;
        animation-name: none !important;
        color: $color-black !important;
      }
    }
  }
  %title{
    .grid{
      display: block;
      > .title{
        margin-bottom: 60px;
        &.grid-s{
          display: block;
        }
        h3{
          @include font-en-m-body-1();
          margin-block: 0 15px;
        }
        p{
          @include font-zh-m-subtitle-2();
        }
      }
    }
    h2{
      @include font-en-m-subtitle-4();
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
  .sec-process{
    margin-top: -140px;
    padding: 140px 0;
    // padding: 0 0 100px;
    .accordion-item{
      .title{
        padding: 20px 15px 20px 0;
        small{
          @include font-en-m-caption-1();
          font-weight: bold;
          margin-right: 25px;
        }
        span{
          @include font-en-m-body-1();
        }
      }
    }
  }
  .bg-light{
    padding-top: 1px;
    margin-top: -1px;
  }
  .sec-partners{
    margin-top: -80px;
    padding-block: 140px 100px;
    // padding-block: 60px 100px;
    .accordion-item{
      .title{
        padding: 20px 15px 20px 0;
        p{
          @include font-en-m-body-1();
        }
      }
      .content{
        .grid-s{
          display: block;
          margin: 0;
        }
      }
      .txt-container{
        padding-bottom: 40px;
        h4{
          @include font-zh-m-subtitle-1();
          margin-bottom: 5px;
        }
      }
      .tag-container{margin-bottom: 15px;}
      .tag{
        @include font-zh-m-caption-1();
        border-right-color: $color-stroke-grey-1;
      }
      figure{
        margin-block: 17px 15px;
        img{width: 100%;}
      }
    }
  }
  #partner-detail{
    .btn-close{
      right: 25px;
    }
    .container{
      padding-inline: 25px;
    }
  }
}
@media (max-width: 768px) {
  #page-service{
    position: relative;
    overflow-x: hidden;
    #header-position{
      &:not(.is-open){
        mix-blend-mode: unset;
        #header{
          filter: invert(0);
        }
      }
    }
  }
}
@media (max-width: 620px) {
  #partner-detail{
    .bg-white{
      margin-left: 60px;
    }
  }
}
@media (max-width: 414px) {
  #page-service{
    .container {
      padding-inline: 17px;
    }
    .grid {
      grid-column-gap: 10px;
    }
  }
  .sec-main-title{
    &.title-service{
      h2{margin-bottom: 100px;}
      p{
        &.font-en-h4{
          @include font-en-m-subtitle-1();
          font-weight: bold;
          margin-bottom: 30px;
        }
        &.font-zh-h1{
          @include font-zh-m-h2();
        }
      }
    }
  }
  .sec-partners{
    padding-bottom: 80px;
  }
  #partner-detail{
    .bg-white{
      margin-left: 40px;
    }
    .btn-close{
      right: 17px;
    }
    .container{
      padding-inline: 17px;
    }
    .modal-title{
      p{
        margin-bottom: 10px;
      }
      h2{
        @include font-en-m-subtitle-2();
      }
    }
  }
}
@media (max-width: 360px) {
  #partner-detail{
    .bg-white{
      margin-left: 20px;
    }
  }
}