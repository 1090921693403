@charset 'utf-8';
.pagination{
	display: flex;
	flex-wrap:wrap;
	align-items:flex-start;
	justify-content:flex-start;
	text-align: center;
	margin-bottom: 100px;
	a,
	p{
		@include font-en-btn-3();
		color:$color-txt-grey-2;
		cursor: pointer;
		@include transition(color .25s);
		&:hover,
		&:focus-visible{
			color: $color-black;
		}
		&.is-active{
			color: $color-black;
		}
		&.prev,
		&.next{
			padding: 10px 0;
			margin: -10px 0;
			color:$color-black;
			&:hover,
			&:focus-visible{
			}
			&.is-disabled{
				color:$color-txt-grey-2;
				pointer-events: none;
			}
		}
		&.prev{
			margin-right: 20px;
		}
		&.next{
			margin-left: 20px;
		}
	}
	ol{
		display: flex;
		flex-wrap:wrap;
		align-items:flex-start;
		justify-content:flex-start;
		a,
		p{
			padding: 10px 7.5px;
			margin: -10px 0;
		}
		li{
			&:first-child{
				a{padding-left: 0;}
			}
			&:last-child{
				a{padding-right: 0;}
			}
		}
	}
}
@media (max-width: 1280px) {
	.pagination{
		margin-bottom: 80px;
	}
}
@media (max-width: 1023px) {
	.pagination{
		justify-content: space-between;
		margin-top: 20px;
		ol{
			a,
			p{
				padding: 10px 5px;
			}
		}
		p{
			color: $color-black;
			&.total{
				&:before{
					content:'/';
					display: inline-block;
					margin-right: 10px;
				}
			}
		}
	}
}
@media (max-width: 414px){
	.pagination{
	}
}