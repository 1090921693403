@charset 'utf-8';
.sec-news{
  padding-bottom: 120px;
  .module-title{
    padding:70px 0 80px;
    .date{
      margin-bottom: 30px;
      b{
        @include font-en-caption-2();
        font-weight: bold;
      }
      time{
        display: inline-block;
        @include font-en-caption-2();
        font-weight: 300;
        margin-left: 10px;
      }
    }
    h2{
      margin-bottom: 30px;
    }
    .description{
      font-weight: 400;
    }
  }
  .module-carousel-news{
    margin-bottom: 100px;
    .swiper-width{
      background: $bg-white;
      padding: 0 24px;
      margin: 0 -24px;
      position: relative;
      // overflow: hidden;
    }
    .swiper-container{
      position: relative;
      overflow: hidden;
    }
    .swiper-slide{
      img{
        aspect-ratio: 1.5;
        object-fit: cover;
      }
    }
    .swiper-button-next,
    .swiper-button-prev{
      &:after{
        margin: 0 -20px;
      }
    }
    .swiper-pagination{
      top: 0;
    }
  }
  .module-article{
    position: relative;
  }
  .module-content{
    margin-bottom: 60px;
    h3{
      @include font-zh-body-2();
      font-weight: 500;
      letter-spacing: .05em;
      margin-bottom: 1.125em;
    }
    > div,
    p{
      @include font-zh-body-2();
      letter-spacing: .05em;
    }
    a{
      &.link-arrow{
        margin-top: 40px;
      }
      &:not(.link-arrow){
        text-decoration: underline;
        text-underline-offset: 0.3em;
      }
    }
  }
  .module-figure{
    margin-bottom: 60px;
    figcaption{
      font-weight: 400;
      margin-top: 8px;
      line-height: 1.7333;
    }
  }
  .share-pos{
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    margin-left: 60px;
    pointer-events: none;
  }
  .share-container{
    position: sticky;
    top: 140px;
    a{
      display: block;
      width: 50px;
      height: 50px;
      margin-bottom: 12px;
      border-radius: 25px;
      @include transition(background .25s ease);
      pointer-events: all;
      position: relative;
      &:hover,
      &:focus-visible{
        background: $color-stroke-grey-2;
        .tooltip{
          opacity: 1;
          @include translate(0, 0);
          pointer-events: all;
        }
      }
    }
    .tooltip{
      font-size: .75em;
      line-height: 1;
      letter-spacing: .0666em;
      color: $color-txt-grey-2;
      display: flex;
      align-items: center;
      position: absolute;
      left: 100%;
      top: 0;bottom: 0;
      margin: auto 0 auto 12px;
      opacity: 0;
      pointer-events: none;
      @include translate(-6px, 0);
      @include transition(transform .25s, opacity .4s);
    }
  }
  .module-author{
    padding-top: 80px;
    .bg-light{
      padding: 40px;
      color: $color-dark;
    }
    h3{
      margin-bottom: 25px;
      b{font-weight: 500;}
      span{
        display: inline-block;
        font-weight: 400;
        margin-left: 0.8em;
      }
    }
    p{
      letter-spacing: .05em;
    }
  }
  .scroll-top-container{
    height: 0;
  }
  #scroll-top{
    width: 60px;
    height: 60px;
    @include transition(transform .25s);
    &:hover,
    &:focus-visible{
      @include translate(0, -4px);
    }
    &:active{
      @include translate(0, -2px);
    }
  }
}
/* -------------RWD--------------- */
@media (min-width: 2042px) {
  .sec-news{
  }
}
@media (max-width: 1440px) {
  .sec-news{
  }
}
@media (max-width: 1280px) {
  .sec-news{
    .module-title{
      .title-container{
        grid-column: auto/span 9;
        grid-column-start: 2;
      }
      .date{
        margin-bottom: 15px;
      }
    }
    .module-carousel-news{
      margin-bottom: 100px;
    }
    .module-author{
      padding-top: 60px;
    }
    .share-pos{
      margin-left: 40px;
    }
  }
}
@media (max-width: 1024px) {
  .sec-news{
    .module-author{
      .bg-light{
        padding: 25px;
      }
    }
    #scroll-top{
      width: 40px;
      height: 40px;
    }
  }
}
@media (max-width: 1023px) {
  .sec-news{
    .module-title{
      .title-container{
        grid-column: auto/span 12;
        grid-column-start: 1;
      }
      h2{
        @include font-zh-m-h2();
        margin-bottom: 25px;
      }
      p{
        @include font-zh-m-subtitle-2();
        font-weight: 400;
      }
    }
    .module-carousel-news{
      .swiper-width{
        grid-column: auto/span 12;
        grid-column-start: 1;
      }
    }
    .module-article{
      .g-col-8{
        grid-column: auto/span 12;
        grid-column-start: 1;
      }
    }
    .scroll-top-container{
      margin-top: 70px;
      margin-bottom: -70px;
    }
    .share-pos{
      height: auto;
      position: relative;
      margin-left: 25px;
      padding-top: 60px;
    }
    .share-container{
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      top: 0;
      a{
        margin-right: 12px;
        margin-bottom: 0;
      }
      .tooltip{display: none;}
    }
  }
}
@media (max-width: 768px) {
  .sec-news{
    padding-bottom: 60px;
    .module-content{
      p{
        @include font-zh-m-subtitle-2();
        // font-weight: 300;
        letter-spacing: 0.0125em;
      }
    }
    .module-carousel-news{
      position: relative;
      overflow: hidden;
      .swiper-button-prev,
      .swiper-button-next{
        width: 16px;
        height: 16px;
        position: absolute;
        margin: 0;
        top: auto;
        mix-blend-mode: unset;
        &:after{
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          top: 0;right: 0;bottom: 0;left: 0;
          opacity: 1;
          @include transform(none);
          background-size: contain;
        }
      }
      .swiper-button-prev{
        bottom: 0;
        left: auto;
        right: 55px;
        &:after{
          background-image: url(/assets/img/icon/arrow-left-black.svg);
        }
      }
      .swiper-button-next{
        bottom: 0;
        right: 0;
        margin-right: 24px;
        &:after{
          background-image: url(/assets/img/icon/arrow-right-black.svg);
        }
      }
    }
  }
}
@media (max-width: 414px) {
  .sec-news{
    .module-title{
      padding:40px 0;
    }
    .share-pos{
      margin-left: 17px
    }
  }
}
@media (max-width: 360px) {
  .sec-news{
  }
}