@charset 'utf-8';
/* -------------COOKIE------------ */
#cookie-banner {
  height: 37px;
  padding: 12px 0;
  position: relative;
  z-index: 2;
  transition: margin .4s;
  p{
    @include font-en-overline-1();
    margin-right: 15px;
  }
  button{
    display: block;
    @include font-en-overline-1();
    color: $color-white;
    transition: opacity .2s;
  }
  &.is-hidden{
    margin-top: -37px;
  }
}
#trigger-cookie-accept{
  text-decoration: underline;
  // margin-right: 12px;
  &:hover,
  &:focus-visible{
    opacity: 0.8;
  }
}
#trigger-cookie-decline{
  opacity: 0.4;
  &:hover,
  &:focus-visible{
    opacity: 0.8;
  }
}
@media (max-width: 1280px) {
  #cookie-banner {
  }
}
@media (max-width: 1023px) {
  #cookie-banner {
  }
}
@media (max-width: 414px) {
  #cookie-banner {
  }
}