@charset 'utf-8';
#page-contact{
  main{
    clip-path: unset;
  }
}
.sec-main-title{
  &.title-contact{
    h2{
      text-transform: none;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}
%contact{
  padding: 40px 0 160px;
  .grid-s{
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    text-align: left;
  }
  h2.font-en-h3{margin-bottom: 80px;}
  p{
    margin-bottom: 22px;
    &:last-child{margin-bottom: 0;}
  }
  .link-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    a{
      margin-bottom: 10px;
      &:last-child{
        box-sizing: border-box;
        margin-bottom: 0;
      }
      .link-underline{
        padding-bottom: 0;
      }
    }
    + p{
      margin-top: 40px;
    }
  }
}
.sec-intro-contact{
  @extend %contact;
}
.sec-share-contact{
  @extend %contact;
}
.sec-visit-contact{
  @extend %contact;
}
.sec-careers-contact{
  @extend %contact;
  .font-en > div,
  .font-en > p,
  p:not(.font-zh-subtitle-1){
    @include font-en-m-subtitle-3();
    margin-bottom: 22px;
  }
}
#list-career{
  margin-top: 80px;
  .list-title{
    border-bottom: 1px solid $color-black;
    padding: 20px 24px;
  }
  .list-content{
    button{
      font: inherit;
      text-align: left;
      padding: 32px 24px;
      &:after{
        content:'';
        display: block;
        width: 1rem;
        height: 1rem;
        background: url(/assets/img/icon/arrow-right-black.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;bottom: 0;
        right: 24px;
        margin: auto 0;
        opacity: 0.3;
      }
      &:hover,
      &:focus-visible{
        &:after{
          opacity: 1;
          @include translate(0, 0);
        }
        p{
          color: $color-black;
        }
      }
    }
    p{
      @include font-zh-subtitle-2();
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
/* -------------RWD--------------- */
@media (min-width: 1681px) {
// @media (min-width: 2042px) {
  %contact{
    .grid-s{
      width: 1296px;
      margin: 0 auto;
    }
  }
  #hiring-detail{
    .bg-white{
      margin-left: 140px;
    }
  }
}
@media (min-width: 2560px) {
  %contact{
    .grid-s{
      width: 80em;
      margin: 0 auto;
    }
  }
}
@media (min-width: 3000px) {
}
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  %contact{
    padding-bottom: 120px;
  }
  .sec-contact{
  }
}
@media (max-width: 1024px) {
  %contact{
    padding-bottom: 80px;
    .grid-s{
      .title{
        grid-column: auto/span 3;
      }
      .g-col-6{
        grid-column: auto/span 7;
      }
    }
  }
  .sec-main-title{
    &.title-contact{
      padding-top: 20px;
    }
  }
  #list-career{
    .list-content{
      button{
        display: block;
        padding: 20px 15px;
        &:after{
          top: auto;
          bottom: 20px;
          right: 15px;
        }
      }
      p{
        @include font-zh-body-2();
        margin-bottom: 1px;
      }
      .role{
        font-weight: 500;
      }
      .type{

      }
      .period{
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 1023px) {
  %contact{
    .grid-s{
      grid-column: auto/span 6;
      display: block;
      width: 100%;
      p{
        margin-bottom: 32px;
      }
      .font-en-m-subtitle-3{
        @include font-en-m-body-1();
      }
      .font-zh-subtitle-1{
        @include font-zh-m-subtitle-1();
      }
      .title{
        h3{
          @include font-en-m-h5();
        }
        margin-bottom: 30px;
      }
    }
    .link-group{
      margin-top: 32px;
      a{margin-bottom: 0;}
      +p{
        margin-top: 32px;
        margin-bottom: 22px;
      }
    }
  }
  .sec-main-title{
    &.title-contact{
      padding-top: 60px;
    }
  }
  .sec-share-contact{
    p{
      br{display: none;}
    }
  }
  .sec-careers-contact{
    padding-bottom: 100px;
    .font-en > div,
    .font-en > p,
    p:not(.font-zh-subtitle-1){
      @include font-en-m-body-1();
      margin-bottom: 32px;
    }
  }
  #list-career{
    margin-top: 60px;
  }
}
@media (max-width: 768px) {
  .sec-main-title{
    &.title-contact{
      padding-top: 80px;
    }
  }

}
@media (max-width: 414px) {
  .sec-contact{
  }
}
@media (max-width: 360px) {
  .sec-contact{
  }
}